import React, {useState} from 'react';
import { useHistory } from 'react-router-dom'
//import AuthService from "../Services/AuthService";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {css} from "glamor";
import axios from 'axios'
import Collections from "../Utils/collections";
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Lottie from 'react-lottie-player'
import loading from "../Lottie/33637-ondas-sonoras.json"


const ResetPass = (props) => {

    let history = useHistory();

    const [email, setEmail] = useState("");
    const [completed, setCompleted] = useState(true);

    const alerts = (msg, error) => {
        toast(msg, {
            className: css({
                background: error?"#E7332A": "#00AC46",
                color: "white"
            }),
            progressClassName: css({
                background: error? "#00AC46": "white"
            })
        })
    };

    const handleChange = (e) =>
    {
        setEmail(e.target.value);
    };

    const handleFormSubmit = (e) =>
    {
        e.preventDefault();
        setCompleted(false);
        axios
            .post(Collections.ipServer + "/mails/reset", {
                email: email
            })
            .then(function (response) {
                alerts("Se ha enviado un correo de verificación", false);
                setTimeout(()=>{
                    //AuthService.logout();
                    window.localStorage.setItem('titleToolbar', "");
                    history.push("/");
                    window.location.reload();
                    setCompleted(true);
                }, 2000)
            })
            .catch(function (error) {
                console.log(error);
                alerts("Usuario no existente", true);
                setCompleted(true);
            });
    };

    const handleFormCancel = () => {
        window.localStorage.setItem('titleToolbar', "");
        history.push("/");
        window.location.reload();
    };

    return (
        <Container component="main" className="loginMain" maxWidth="xs">
            <CssBaseline/>
            <div className="loginPaper">

                <img src="/assets/img/thermomix_header.png" alt=""/>
                <br/>
                <br/>
                <Typography component="h1" variant="h5">
                    Restablecer contraseña
                </Typography>
                {
                    !completed ?
                        <Lottie
                            loop
                            animationData={loading}
                            play
                            style={{width: 450, height: 450, display: "block", margin: "auto"}}
                        />
                        :
                        <form className="col s12 inputs-travel">
                            <div className="row">
                                <div className="input-field col s12">
                                    <input id="email" type="email" name="email" className="validate"
                                           onChange={handleChange}/>
                                    <label htmlFor="email">Correo electrónico</label>
                                </div>
                            </div>
                            <div className="row note-login">
                                <p>
                                    * Escribe tu correo para restablecer tu contraseña
                                </p>
                            </div>
                            <div className="row content-btn-login">
                                <button onClick={handleFormCancel} style={{float: "left"}}
                                        className="btn-large btn-travel">
                                    Cancelar
                                </button>
                                <button onClick={handleFormSubmit} style={{float: "right"}}
                                        className="MuiBadge-colorSecondary btn-large btn-travel">
                                    Enviar
                                </button>
                            </div>
                        </form>
                }
            </div>
        </Container>
    );

};
export default ResetPass;