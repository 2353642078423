import React, {useState, useEffect} from 'react';
import AuthService from "../Services/AuthService";
import { useHistory } from 'react-router-dom'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Lottie from 'react-lottie-player'
import loading from "../Lottie/33637-ondas-sonoras.json"
import Button from '@material-ui/core/Button';
//import { Link } from 'react-router-dom';
//import utils from "../Utils/collections";
/**
 * Componente para pintar dashboard de cada usuario
 * @param {*} props Datos de usuario logeado
 * @returns La vista de los dashboard
 */
export default function Estadisticas(props) {

    let history = useHistory();

    const [currentUser] = useState(props.user);
    const [usersIntrns] = useState(props.userIntr);
    const [calendarioVorwerk] = useState(props.calendarioVorwerk);
    const [myGoals, setMyGoals] = useState([]);
    const [sumaGoals, setSumaGoals] = useState();
    const [prospectos, setProspectos] = useState([]);
    const [sumProspectos, setSumProspectos] = useState();
    const [experiencias, setExperiencias] = useState([]);
    const [sumExperiencias, setSumExperiencias] = useState();
    const [ventas, setVentas] = useState([]);
    const [sumVentas, setSumVentas] = useState();
    //const [ventasNewApi, setVentasNewApi] = useState([]);
    //const [cancelacionesNewApi, setCancelacionesNewApi] = useState();
    //To Do Ver2: Global Date for Estadisticas....
    // eslint-disable-next-line
    const [CurrentFecha, setCurrentFecha] = useState(props.CurrentFecha);
    // eslint-disable-next-line
    const [CurrentMesNum, setCurrentMesNum] = useState(props.CurrentMesNum);
    // eslint-disable-next-line
    const [CurrentAnioNum, setCurrentAnioNum] = useState(props.CurrentAnioNum);

    
    const [ventasGrafica, setVentasGrafica] = useState([]);
    const [load, setLoad] = useState(false);
    const arrVentas = [];
    const arrCancelaciones = [];
    // eslint-disable-next-line
    const [month, setMonth] = useState(0);
    // eslint-disable-next-line
    const [year, setYear] = useState(0);
    let smtrGoal = {
        sumProspectos: 0,
        sumExperiencias: 0,
        sumVentas: 0
    };
    let smtrExperiencias = {
        experienciasProgramadas: 0,
        experienciasRealizadas: 0
    };
    let smtrPrsp = 0;
    let meses = [1,2,3,4,5,6,7,8,9,10,11,12];
    useEffect(()=>{
        //To Do Ver2: Disable this console logs.
        //console.log("Vamos a trabajar en la estadistica con este calendario vorwerk ", calendarioVorwerk)
        //console.log("Mes actual recibido para Estadistica es: " + CurrentMesNum)
        //console.log("Año actual recibido para Estadistica es: " + CurrentAnioNum)
        AuthService.getIdVentas().then(json=> {
            if(json){
                let datosVentas = json.data.results;
                //To Do Ver6: IMPORTANT! remove this console log from Lambda response:
                //console.log('Lambda Response:');
                //console.log(datosVentas);
                if(datosVentas){
                    datosVentas.forEach((dato, index)=>{
                        //console.log(dato.basic.customFieldList.customField);
                        
                        //Validar que solo sea de la fecha iicio de mes vorwerk en adelante
                        // Esta consulta debe ser en base al mes Vorwerk
                        //To Do Ver2: Fecha Changed for useState value.
                        //let fecha = new Date()
                        //let fechaVorwerkFin = utils.calendarioVorwerk[CurrentFecha.getMonth()][CurrentFecha.getMonth()][1].fin;
                        //To Do Ver6: Check if vorwerk month end date should be validated in the sales.
                        //let fechaVorwerkFin = calendarioVorwerk[CurrentFecha.getMonth()].fechaFin;
                        //console.log('Ver6 fechaVorwerkFin: '+fechaVorwerkFin);
                        //let fechaVorwerkInicio = utils.calendarioVorwerk[CurrentFecha.getMonth()][CurrentFecha.getMonth()][0].inicio;
                        let fechaVorwerkInicio = new Date(calendarioVorwerk[CurrentFecha.getMonth()].fechaInicio);
                        console.log('Ver6 preIF fechaVorwerkInicio: '+fechaVorwerkInicio);

                        //To Do Ver6: This adjustems modifies time of the date. check if sales are compared correctly on the exact month start date.
                        /*
                        if(calendarioVorwerk && calendarioVorwerk.length>0){
                            //console.log("Hay calendario vorwerk")
                            calendarioVorwerk.forEach(mes=>{
                                //console.log("mes", mes)
                                if( CurrentFecha.getTime() > new Date(mes.fechaInicio).getTime() && CurrentFecha.getTime() < new Date(mes.fechaFin).getTime() ) {
                                    fechaVorwerkInicio = new Date(mes.fechaInicio)
                                    console.log('Ver6 postIF fechaVorwerkInicio: '+fechaVorwerkInicio);
                                }
                            })
                        } else {
                            console.log("ERROR: Sin calendario vorwerk")
                        }
                        */
                        
                        
                        //console.log("fecha vorwerk para estadistica: ", fechaVorwerkInicio)
                        // Fecha de transaccion de venta en el servicio
                        let fechaVenta = new Date(dato.basic.tranDate[0].searchValue)
                        //To Do Ver6: IMPORTANT Disable this console log.
                        //console.log('Fecha de venta para verificar: '+fechaVenta);
                        if(fechaVenta.getTime()>= fechaVorwerkInicio.getTime() ){
                            console.log("Tenemos ventas en este mes...")
                            if(dato.basic.customFieldList.customField.length>0){
                                dato.basic.customFieldList.customField.forEach((ventas, j)=>{
                                    if(ventas.$attributes.internalId==="883"){
                                        //console.log(dato.salesRepJoin.entityId[0].searchValue + " - venta");
                                        arrVentas.push({"idu": dato.salesRepJoin.entityId[0].searchValue, "venta": 1});
                                    }
                                    if(ventas.$attributes.internalId==="3137"){
                                        //console.log(dato.salesRepJoin.entityId[0].searchValue + " - cancelación")
                                        arrCancelaciones.push({"idu": dato.salesRepJoin.entityId[0].searchValue, "cancelacion": 1});
                                    }
                                    //console.log(ventas.$attributes.internalId)
                                });
                            }
                        } else {
                            console.log('NO HAY VENTAS REGISTRADAS PARA ESTE USUARIO.');
                        }
                        
                    });
                }
                /*
                console.log("VENTAS:");
                console.log(arrVentas.sort((a,b)=>a.idu-b.idu));

                console.log("CANCELACIONES:");
                console.log(arrCancelaciones);
                */
                //setVentasNewApi(arrVentas.sort((a,b)=>a.idu-b.idu));
                //setCancelacionesNewApi(arrCancelaciones);

                consultaGoals(currentUser, true);
                consultaEstadisticaPresentador();
                if(currentUser.rol[0].value===3 || currentUser.rol[0].value===5){
                    consultaEstadisticaLyG();
                }
                if(currentUser.rol[0].value===3 || currentUser.rol[0].value===1){  // Solo lideres y presentadores para gráfica de ventas
                    
                    //To Do Ver4: This secction is the old Ventas Querry and must be deleted.
                    /*
                    let gql = `{salesIdu(iduEmpleado: "${currentUser.idu}" year: ${CurrentFecha.getFullYear()+1}){
                        id
                        iduEmpleado
                        email
                        mes
                        year
                        mesAnio
                        ventas_sin_tm_ganada
                        cancelaciones
                        created_date
                      }}`;
                    //console.log(gql);
                    AuthService.consult(gql)
                        .then(resp=>{
                            //console.log(resp.data.data.salesIdu)
                            setVentasGrafica(resp.data.data.salesIdu)
                        })
                */

                //console.log(arrVentas.filter(a=>a.idu==="9453"));

                    // FixToDo DevVer:1 Eliminar variables y lineas comentadas de esta sección.
                    //To Do Ver2: End implementation of new Sales control.........

                    let VParam = currentUser.idu;

                    console.log('IDU Presentador Actual:' + VParam);

                    AuthService.getventasrel(VParam)
                        .then(resp=>{
                            //console.log('INFORMACIÓN RECIBIDA PARA VENTAS REL:---- ' + resp.data)
                            console.log('DISABLED')
                            setVentasGrafica(resp.data)
                        }
                        )
                    // ${currentUser.idu}   
                }
                //console.log(arrVentas.filter(a=>a.idu==="9453"));
            }
        });
    }, []);

    useEffect(() => {
        //console.log("entroReagendar: "+entroReagendar)
    },[sumProspectos]);

    useEffect(() => {
        //console.log("entroReagendar: "+entroReagendar)
    },[sumaGoals]);

    useEffect(() => {
        //console.log("entroReagendar: "+entroReagendar)
    },[sumExperiencias]);

    const consultaGoals = (user, presentador) => {
        //console.log(user)
        setLoad(false);
        //To Do Ver2: Dates defined by new functions for Goals Unify function in a Export Mode....
        
        //var CurrentFecha = GetFechaGlobal(6);
        console.log('FECHA PARA GOALS-------------------------------------------------------------------------: ' + CurrentFecha)
        //let fecha = new Date();
        //Si es lider
        setMonth(CurrentFecha.getMonth()+1);
        setYear(CurrentFecha.getFullYear());


        //To Do Ver2: Using calendar passed by useSate for Estadistica----------------------

        //To Do Ver6: Check why this mutators are no longer used:
        /*
        function TimeZoneMutator(numOfHours, date) {
            date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
        
            return date;
          }
        
        var ToMutateFin2022 = new Date (calendarioVorwerk[CurrentMesNum-1].fechaInicio)
        var ToMutateFin2023 = new Date (calendarioVorwerk[CurrentMesNum-1].fechaFin)
        */

        //To Do Ver6: unused vars commented. Check why they are no longer used.

        /*
        const fechaInicioStat = TimeZoneMutator(6,ToMutateFin2022);
        const fechaFinStat = TimeZoneMutator(6,ToMutateFin2023);
        */

        //To Do Ver2: Delete this console logs....
        //console.log('La Fecha Inicio para Estadisticas es: ' + fechaInicioStat);
        //console.log('La Fecha Fin para Estadisticas es: ' + fechaFinStat);



        //console.log(mesConsultar)
        console.log('VERIFICANDO SI SE CORRE ESTA FUNCION------------------------------------------------');
        
        let gqlConsulta = `{
            goalsIduMonthYear(iduUser:"${user.idu}" month: ${CurrentMesNum}
              year: ${CurrentAnioNum} ){ id iduUser prospectos experiencias ventas budgets month year }
          }`
        console.log(gqlConsulta)
        AuthService.consult(gqlConsulta)
            .then(result=>{
                let goals = result.data.data.goalsIduMonthYear;
                setLoad(true);
                console.log('Resultado de las Metas consultadas: ' + goals[0].prospectos)
                if( goals !== null && goals.length > 0 ){
                    //console.log(goals);
                    if(currentUser.idu === user.idu && presentador) {
                        //console.log(goals);
                        console.log('Definiendo metas desde esta condicional.......')
                        setMyGoals(goals);
                    } else {
                        smtrGoal.sumProspectos = smtrGoal.sumProspectos + goals[0].prospectos;
                        smtrGoal.sumExperiencias = smtrGoal.sumExperiencias + goals[0].experiencias;
                        smtrGoal.sumVentas = smtrGoal.sumVentas + goals[0].ventas;
                        let objSumGoals = {
                            sumProspectos: smtrGoal.sumProspectos,
                            sumExperiencias: smtrGoal.sumExperiencias,
                            sumVentas: smtrGoal.sumVentas
                        };
                        //console.log(user.idu)
                        //console.log(objSumGoals)
                        setSumaGoals(objSumGoals);
                    }
                }
            })
            .catch(error=>{console.log(error);});
    };

    const consultaEstadisticaPresentador = () => {
        //Consultar  getProspectos
        AuthService.getProspectos(currentUser.idu)
            .then(resp => {
                setProspectos(resp.data)
            })
            .catch(error=>{console.log(error)});

        //Consultar  getExperiencias
        AuthService.getExperiencias(currentUser.idu)
            .then(resp => {
                let exp = [];
                exp.push(resp.data);
                console.log('Consulta de Experiencias (getExperiencias) Ejecutada para el usuario: '+currentUser.idu);
                console.log(resp.data);
                setExperiencias(exp)
            })
            .catch(error=>{console.log(error)});

        //Consultar ventas del nuevo array de ventas de nueva API
        /*
        AuthService.getVentas(currentUser.idu)
            .then(resp => {
                setVentas(resp.data);
            })
            .catch(error=>{console.log(error)});
        */
        let noVentas = arrVentas.filter(v => v.idu === currentUser.idu)
        let noCncl = arrCancelaciones.filter(c => c.idu === currentUser.idu)
        let arrVentas2 = [{Ventas_sin_tm_ganada: noVentas.length, Cancelaciones: noCncl.length, idu: currentUser.idu}]
        //To Do Ver3: Check from API Data returned for ventas semaforo.
        setVentas(arrVentas2);

        console.log('Ventas recibidas para estadisticas del Usuario:');
        console.log(arrVentas2);
        // FixToDo DevVer:1 Eliminar lineas superiores para monitoreo de las variables.
    };

    const consultaEstadisticaLyG = () => {
        let chavos = usersIntrns.filter(usr => (usr.estructura && usr.estructura.length>0 && usr.estructura.filter(usrE => (usrE && usrE.idu && usrE.idu===currentUser.idu) ).length>0) )
        //Vamos a verificar si el mismo usuario no esta en los chavos lo agregamos
        let inChavosCurrent = chavos.filter(u=>u.idu===currentUser.idu)
        if(inChavosCurrent.length===0){
            chavos.push(currentUser);
        }

        /*
        console.log("VENTAS:");
        console.log(arrVentas.sort((a,b)=>a.idu-b.idu));
        console.log("CANCELACIONES:");
        console.log(arrCancelaciones);
        */
        let arrVentas2 = []
        chavos.forEach( (u, i)=>{
            consultaGoals(u, false);
            consultaSumExperiencias(u, i===chavos.length-1);
            consultaSumProspectos(u, i===chavos.length-1);

            let noVentas = arrVentas.filter(v => v.idu === u.idu)
            let noCncl = arrCancelaciones.filter(c => c.idu === u.idu)

            let objVenta = {Ventas_sin_tm_ganada: noVentas.length, Cancelaciones: noCncl.length, idu: u.idu}
            arrVentas2.push(objVenta)
        });
        //console.log(arrVentas2)
        let sumVentas = 0;
        let sumCancelaciones = 0;
        arrVentas2.forEach(v=>{
            sumVentas += parseInt(v.Ventas_sin_tm_ganada);
            sumCancelaciones += parseInt(v.Cancelaciones);
        });
        //console.log({sumVentas: sumVentas, sumCancelaciones: + sumCancelaciones})
        setSumVentas({sumVentas: sumVentas, sumCancelaciones: + sumCancelaciones});

        //Consultar  getVentas
        /*
        let arrIdu = '';
        chavos.forEach((u, i)=>{
            arrIdu += i===0 ? u.idu: ', ' + u.idu
        });
        //console.log(arrIdu);
        AuthService.getVentas(arrIdu)
            .then(resp => {
                //console.log(resp.data);
                // Vamos a acumular las ventas de estos chavos
                if(resp.data.length>0){
                    let sumVentas = 0;
                    let sumCancelaciones = 0;
                    resp.data.forEach(v=>{
                        sumVentas += parseInt(v.Ventas_sin_tm_ganada);
                        sumCancelaciones += parseInt(v.Cancelaciones);
                    });
                    setSumVentas({sumVentas: sumVentas, sumCancelaciones: + sumCancelaciones});
                }
            })
            .catch(error=>{console.log(error)});
        */
        //console.log(chavos);
    };

    const consultaSumExperiencias = (user, ultimo) => {
        //console.log("--> " + user.idu)
        //Consultar  getExperiencias
        AuthService.getExperiencias(user.idu)
            .then(resp => {
                console.log(resp.data);
                smtrExperiencias.experienciasProgramadas += resp.data.experienciasProgramadas.length;
                smtrExperiencias.experienciasRealizadas += resp.data.experienciasRealizadas.length;

                setSumExperiencias({
                    experienciasProgramadas: smtrExperiencias.experienciasProgramadas,
                    experienciasRealizadas: smtrExperiencias.experienciasRealizadas
                })

            })
            .catch(error=>{console.log(error)});
    };

    const consultaSumProspectos = (user, ultimo) => {
        AuthService.getProspectos(user.idu)
            .then(resp => {
                //console.log(resp.data);
                smtrPrsp += resp.data.length;

                //if (ultimo){
                    //console.log(user.idu + ' prospecto: ' + smtrPrsp + ' ultimo: ' + ultimo)
                    setSumProspectos(smtrPrsp);
                //}
            })
            .catch(error=>{console.log(error)});
    };

    const handleClick= (path, titleToolbar) => {
        window.localStorage.setItem('titleToolbar', titleToolbar)
        history.push(path);
        window.location.reload();
    }

    return (
        <div>
        {
            !load ?
                <Lottie
                    loop
                    animationData={loading}
                    play
                    style={{width: 450, height: 450, display: "block", margin: "auto"}}
                />
            :
            <div>
            {
                (myGoals.length>0) ?
                    <div className="row">
                        {/* <h3 className="staTile RosaTitle">Estadística ROSA</h3> */}
                        {
                            //PRESENTADORES
                        }
                        {
                            (currentUser.rol[0].value === 1 || (currentUser.rol[0].value === 3)) &&
                            <div className="col l12 panelRosa">
                                <div className="ProspectoCont">
                                    <h4 className="staTile StatTitle_Prospecto">
                                      Prospectos: 
                                      <Tooltip arrow disableFocusListener title="Total de contactos ingresados con eventos agendados este mes.">
                                        <InfoOutlinedIcon fontSize="large"/> 
                                      </Tooltip>
                                    </h4>
                                    <div className="barSalesCont">
                                        <div className="barProspectFill" style={{width: 'calc(0% + '+(
                                            myGoals && myGoals.length>0 ?
                                                prospectos.length>myGoals[0].prospectos?100:
                                                (prospectos.length*100/myGoals[0].prospectos):0
                                        )+'%)'}}>
                                            {prospectos.length}/{myGoals && myGoals.length>0 && myGoals[0].prospectos}
                                        </div>
                                    </div>
                                    <div className="subTitle_Prospecto">
                                        <span>Lo que quiero lograr este mes: {myGoals && myGoals.length>0 && myGoals[0].prospectos}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col l12 s12 rosaSection row">
                                        <div className="col l6 m6 s12">
                                            
                                            <h4 className="staTile StatTitle_ExpProg">
                                                Demos Programadas:
                                                <Tooltip arrow disableFocusListener title="Total de Experiencias (demos) agendadas respecto al objetivo declarado.">
                                                    <InfoOutlinedIcon fontSize="large"/> 
                                                </Tooltip>
                                            </h4>
                                            <div className="GraphCont">
                                                <span className="infoGraph infoExpProg">
                                                    {experiencias && experiencias.length>0 && experiencias[0].experienciasProgramadas.length} / {myGoals && myGoals.length>0 && myGoals[0].experiencias}
                                                </span>

                                                <div className="graphCircle">
                                                    <div className="graphRosa">
                                                        <CircularProgress
                                                        className= 'graphExpProg'
                                                        size= '16rem'
                                                        thickness= {6}
                                                        style={{color:'#019846'}}
                                                        variant="determinate"
                                                        value={experiencias && experiencias.length>0 && myGoals && myGoals.length>0 ?
                                                            experiencias[0].experienciasProgramadas.length>myGoals[0].experiencias?100:
                                                            (experiencias[0].experienciasProgramadas.length*100/myGoals[0].experiencias):0
                                                        } />
                                                    </div>
                                                </div>
                                                <div className="spinnerTM"></div>
                                            </div>
                                        </div>
                                        <div className="col l6 m6 s12">
                                            <h4 className="staTile StatTitle_ExpDone">
                                                Demos Realizadas:
                                                <Tooltip arrow disableFocusListener title="Total de Experiencias (demos) con pase de lista realizado, respecto al objetivo ">
                                                    <InfoOutlinedIcon fontSize="large"/> 
                                                </Tooltip> 
                                            </h4>
                                            <div className="GraphCont">
                                                <span className="infoGraph infoStatTitle_ExpDone">
                                                {experiencias && experiencias.length>0 && experiencias[0].experienciasRealizadas.length} / {myGoals && myGoals.length>0 && myGoals[0].experiencias}
                                                </span>
                                                <div className="graphCircle ">
                                                    <div className="graphRosa">
                                                        <CircularProgress
                                                        className= 'graphStatTitle_ExpDone'
                                                        size= '16rem'
                                                        thickness= {6}
                                                        style={{color:'#019846'}}
                                                        variant="determinate"
                                                        value={experiencias && experiencias.length>0 && myGoals && myGoals.length>0 ?
                                                            experiencias[0].experienciasRealizadas.length>myGoals[0].experiencias?100:
                                                            (experiencias[0].experienciasRealizadas.length*100/myGoals[0].experiencias):0
                                                        } />
                                                    </div>
                                                </div>
                                                <div className="spinnerTM"></div>
                                            </div>
                                        </div>
                                        <div className="subTitle_Prospecto">
                                                    <span>Lo que quiero lograr este mes: {myGoals && myGoals.length>0 && myGoals[0].experiencias}</span>
                                                </div>
                                    </div>

                                    <div className="col l12 s12 rosaSection">
                                        <div className="EfectCont">
                                            <div className="col l8 s12 StatTitle_EfectCont">
                                                <h4 className="staTile StatTitle_Efect">Efectividad</h4>
                                                <p className="question_Efect">¿Cuántas demos necesito agendar por cada 1 que logro realizar?: </p>
                                            </div>
                                            <div className="col l4 s12 blade_dataCont">
                                                <div className="blade_effect">
                                                {
                                                    experiencias && experiencias.length>0 ?
                                                        <span className="effectData">
                                                            {experiencias[0].experienciasProgramadas.length > 0 &&
                                                                experiencias[0].experienciasRealizadas.length>0?
                                                                <>
                                                                {(experiencias[0].experienciasProgramadas.length
                                                                /
                                                                experiencias[0].experienciasRealizadas.length).toFixed(2)}
                                                                </>
                                                                :
                                                                <>
                                                                -
                                                                </>
                                                            }
                                                        </span>
                                                        :
                                                        <div>-</div>
                                                }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        //2===2 &&
                                        <>
                                            <div className="col l12 s12 rosaSection">
                                                <div className="ProspectoCont">
                                                    <h4 className="staTile StatTitle_Sales">
                                                      Ventas: 
                                                      <Tooltip arrow disableFocusListener title="Total de pedidos efectivos realizados en el mes.">
                                                        <InfoOutlinedIcon fontSize="large"/> 
                                                      </Tooltip>
                                                    </h4>
                                                    <div className="barSalesCont">
                                                        {ventas && ventas.length>0 && ventas[0].Ventas_sin_tm_ganada>0 ?
                                                        <div className="barSalesFill" style={{width: 'calc(0% + '+ (
                                                            myGoals && myGoals.length>0 && ventas && ventas.length>0 ?
                                                                ventas[0].Ventas_sin_tm_ganada>myGoals[0].ventas?100:
                                                                (ventas[0].Ventas_sin_tm_ganada*100/myGoals[0].ventas):0
                                                            )+'%)'}}>
                                                            {ventas && ventas.length>0 && ventas[0].Ventas_sin_tm_ganada}/{myGoals && myGoals.length>0 && myGoals[0].ventas}
                                                        </div>
                                                        :
                                                        <div className="barSalesEmpty" style={{width: 'calc(100% + '+ (
                                                            myGoals && myGoals.length>0 && ventas && ventas.length>0 ?
                                                                ventas[0].Ventas_sin_tm_ganada>myGoals[0].ventas?100:
                                                                (ventas[0].Ventas_sin_tm_ganada*100/myGoals[0].ventas):0
                                                            )+'%)'}}>
                                                            {ventas && ventas.length>0 && ventas[0].Ventas_sin_tm_ganada}/{myGoals && myGoals.length>0 && myGoals[0].ventas}
                                                        </div>
                                                        }
                                                        
                                                    </div>
                                                    <div className="subTitle_Prospecto">
                                                        <span>Lo que quiero lograr este mes: {myGoals && myGoals.length>0 && myGoals[0].ventas}</span>
                                                    </div>
                                                </div>

                                              </div>
                                            <div className="col l12 s12 rosaSection">
                                                <div className="canceledCont">
                                                    <h4 className=" StatTitle_Canceled">
                                                        <Tooltip arrow disableFocusListener title="Total de pedidos cancelados cada mes.">
                                                        <InfoOutlinedIcon fontSize="small"/> 
                                                      </Tooltip>
                                                      Ventas canceladas: 
                                                      {ventas && ventas.length>0 && ventas[0].Cancelaciones}
                                                    </h4>
                                                </div>

                                            </div>
                                        </>
                                    }
                                    <div className="col l12 s12 rosaSection">

                                        <div className="EfectCont">
                                            {
                                                //2===2 &&
                                                <>
                                                <div className="col l8 s12 StatTitle_RatioDemoVentaCont">
                                                    <h4 className="staTile StatTitle_RatioDemoVenta">Ratio
                                                        demo-venta</h4>
                                                    <p className="question_RatioDemoVenta">¿Cuántas demos
                                                        necesito realizar para lograr 1 venta?</p>
                                                </div>
                                                <div className="col l4 s12 blade_dataCont">
                                                    <div className="blade_effect_alt">
                                                        {
                                                            experiencias && experiencias.length>0 && experiencias[0].experienciasRealizadas.length>0 ?
                                                                <span className="effectData">
                                                            {ventas && ventas.length>0 && parseInt(ventas[0].Ventas_sin_tm_ganada)>0?
                                                                <>
                                                                {
                                                                    Math.round(experiencias[0].experienciasRealizadas.length / parseInt(ventas[0].Ventas_sin_tm_ganada)) ?
                                                                        (experiencias[0].experienciasRealizadas.length / parseInt(ventas[0].Ventas_sin_tm_ganada)).toFixed(2):
                                                                        '-'
                                                                }
                                                                </>
                                                                :
                                                                <>
                                                                -
                                                                </>
                                                            }
                                                        </span>
                                                                :
                                                                <span className="effectData">
                                                            {
                                                                //myGoals && myGoals.length>0 && myGoals[0].ventas
                                                            }
                                                                    0
                                                        </span>
                                                        }
                                                    </div>
                                                </div>
                                                </>
                                            }

                                        </div>
                                    </div>
                                    {
                                        //2===2 &&
                                        <>
                                        <div className="col l12 s12 rosaSection row">
                                            <div className="col l2 s2">
                                                <img alt="blade_rellow" className="blade_yellow" src="/assets/img/blade_yellow_1.svg" />
                                            </div>

                                            <div className="col l10 s10">
                                                    <h4 className="staTile StatTitle_Activity">Actividad {CurrentFecha.getFullYear()}
                                                      <Tooltip arrow disableFocusListener title="Registro de los meses que he realizado al menos una venta durante el año.">
                                                        <InfoOutlinedIcon fontSize="large"/> 
                                                      </Tooltip>
                                                    </h4>
                                                    {
                                                        <div className="row">
                                                            {
                                                                meses.map((mes,i)=>
                                                                    ((mes===(CurrentFecha.getMonth()+1) && parseInt(ventas && ventas.length>0 && [0].Ventas_sin_tm_ganada)>0)) ||
                                                                    (ventasGrafica.length>0 && ventasGrafica.filter(v=>v.mes===mes).length>0 && ventasGrafica.filter(v=>v.mes===mes)[0].ventas_sin_tm_ganada>0)
                                                                        ?
                                                                        <div className="col l1 monthAct" key={i}>
                                                                            <span className="monthNumber_activity">{mes}</span>
                                                                            <FiberManualRecordIcon className={"rosa_activity_month sales_act_active"} fontSize="large" />
                                                                        </div>
                                                                        :
                                                                        <div className="col l1 monthAct" key={i}>
                                                                            <span className="monthNumber_activity">{mes}</span>
                                                                            <FiberManualRecordIcon className={"rosa_activity_month sales_act_inactive"} fontSize="large" />
                                                                        </div>
                                                                )
                                                            }
                                                        </div>
                                                    }
                                                    {

                                                    }
                                                </div>

                                        </div>
                                        </>
                                    }
                                </div>
                            </div>
                        }

                        {
                            //LIDERES
                        }
                        {
                            (currentUser.rol[0].value === 3) &&
                            <div className="col l12 s12 panelRosa">
                                <div>
                                    <h4 className="StatTitle_MyTeam">Mi Equipo</h4>

                                    <div className="row">
                                        <div className="col l12 s12 ProspectoCont">
                                            <h4 className="staTile StatTitle_Prospecto">
                                              Prospectos: 
                                              <Tooltip arrow disableFocusListener title="Total de contactos ingresados con eventos agendados este mes.">
                                                  <InfoOutlinedIcon fontSize="large"/> 
                                              </Tooltip>
                                            </h4>
                                            <div className="barSalesCont">
                                                <div className="barProspectFill" style={{width: 'calc(0% + '+(
                                                    sumaGoals ?
                                                        sumProspectos>sumaGoals.sumProspectos?100:
                                                        (sumProspectos*100/sumaGoals.sumProspectos ): 0
                                                )+'%)'}}>
                                                    {sumProspectos}/
                                                    {
                                                        (parseInt(sumaGoals &&  sumaGoals.sumProspectos)?
                                                            parseInt(sumaGoals && sumaGoals.sumProspectos):0)
                                                    }
                                                </div>
                                            </div>
                                            <div className="subTitle_Prospecto">
                                                <span>Acumulado de mi equipo: 
                                                    {
                                                        (parseInt(sumaGoals  && sumaGoals.sumProspectos)?
                                                            parseInt(sumaGoals  && sumaGoals.sumProspectos):0)
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col l12 s12 rosaSection row">
                                            <div className="col l6 s12">
                                                <h4 className="staTile StatTitle_ExpProg">
                                                    Demos Programadas:
                                                    <Tooltip arrow disableFocusListener title="Total de Experiencias (demos) agendadas respecto al objetivo declarado por mi equipo.">
                                                        <InfoOutlinedIcon fontSize="large"/> 
                                                    </Tooltip>
                                                    </h4>
                                                <div className="GraphCont">
                                                    <span className="infoGraph infoExpProg">
                                                    {sumExperiencias && sumExperiencias.experienciasProgramadas} /
                                                        {
                                                            (parseInt(sumaGoals && sumaGoals.sumExperiencias)?
                                                                parseInt(sumaGoals && sumaGoals.sumExperiencias):0)
                                                        }
                                                    </span>
                                                    
                                                    <div className="graphCircle">
                                                        <div className="graphRosa">
                                                            <CircularProgress 
                                                            className= 'graphExpProg'
                                                            size= '16rem'
                                                            thickness= {6}
                                                            style={{color:'#019846'}}
                                                            variant="determinate"
                                                            value={sumExperiencias && sumaGoals ?
                                                                sumExperiencias.experienciasProgramadas>sumaGoals.sumExperiencias?100:
                                                                (sumExperiencias.experienciasProgramadas*100/sumaGoals.sumExperiencias):0} />
                                                        </div>
                                                    </div>
                                                    <div className="spinnerTM"></div>
                                                </div>
                                            </div>
                                            <div className="col l6 s12">
                                                <h4 className="staTile StatTitle_ExpDone">
                                                    Demos Realizadas:
                                                    <Tooltip arrow disableFocusListener title="Total de Experiencias (demos) con pase de lista realizado, respecto al objetivo declarado por mi equipo.">
                                                        <InfoOutlinedIcon fontSize="large"/> 
                                                    </Tooltip> 
                                                </h4>
                                                <div className="GraphCont">
                                                    <span className="infoGraph infoStatTitle_ExpDone">
                                                    {sumExperiencias && sumExperiencias.experienciasRealizadas} /
                                                        {
                                                            (parseInt(sumaGoals && sumaGoals.sumExperiencias)?
                                                                parseInt(sumaGoals && sumaGoals.sumExperiencias):0)
                                                        }
                                                    </span>
                                                    <div className="graphCircle ">
                                                        <div className="graphRosa">
                                                            <CircularProgress
                                                            className= 'graphStatTitle_ExpDone'
                                                            size= '16rem'
                                                            thickness= {6}
                                                            style={{color:'#019846'}}
                                                            variant="determinate"
                                                            value={sumExperiencias && sumaGoals ?
                                                                sumExperiencias.experienciasRealizadas>sumaGoals.sumExperiencias?100:
                                                                (sumExperiencias.experienciasRealizadas*100/sumaGoals.sumExperiencias):0} />
                                                        </div>
                                                    </div>
                                                    <div className="spinnerTM"></div>
                                                </div>
                                            </div>
                                            <div className="subTitle_Prospecto">
                                                <span>Acumulado de mi equipo:
                                                    {
                                                        (parseInt(sumaGoals && sumaGoals.sumExperiencias)?
                                                            parseInt(sumaGoals && sumaGoals.sumExperiencias):0)
                                                    }
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col l12 s12 rosaSection">
                                            <div className="EfectCont">
                                                <div className="col l8 s12 StatTitle_EfectCont">
                                                    <h4 className="staTile StatTitle_Efect">
                                                        Efectividad
                                                        <Tooltip arrow disableFocusListener title="Número total de Ventas realizadas registradas en Netsuite">
                                                            <InfoOutlinedIcon fontSize="large"/> 
                                                        </Tooltip>
                                                    </h4>
                                                    <p className="question_Efect">¿Cuántas demos necesito agendar por cada 1 que logro realizar?: </p>
                                                </div>
                                                <div className="col l4 s12 blade_dataCont">
                                                    <div className="blade_effect">
                                                            <span className="effectData">
                                                                {
                                                                    (parseInt(sumExperiencias && sumExperiencias.experienciasRealizadas)?
                                                                        parseInt(sumExperiencias && sumExperiencias.experienciasRealizadas):0) === 0
                                                                    ||
                                                                    (parseInt(sumExperiencias && sumExperiencias.experienciasProgramadas)?
                                                                        parseInt(sumExperiencias && sumExperiencias.experienciasProgramadas):0) === 0
                                                                        ?
                                                                        '-':
                                                                        (
                                                                            parseInt(sumExperiencias.experienciasProgramadas)
                                                                            /
                                                                            parseInt(sumExperiencias.experienciasRealizadas)
                                                                        ).toFixed(2)
                                                                }
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            //2===2 &&
                                            <>
                                            <div className="col l12 s12 rosaSection">
                                                <div className="ProspectoCont">
                                                    <h4 className="staTile StatTitle_Sales">
                                                      Ventas: 
                                                      <Tooltip arrow disableFocusListener title="Total de pedidos efectivos realizados en el mes respecto al objetivo declarado por mi equipo.">
                                                          <InfoOutlinedIcon fontSize="large"/> 
                                                      </Tooltip> 
                                                    </h4>
                                                    <div className="barSalesCont">
                                                        {
                                                        parseInt(sumVentas && sumVentas.sumVentas)>0 ? 
                                                        <div className="barSalesFill" style={{width: 'calc(0% + '+
                                                            (sumVentas && sumaGoals ?
                                                                    sumVentas.sumVentas>sumaGoals.sumVentas?100:
                                                                    (sumVentas.sumVentas*100/sumaGoals.sumVentas):0
                                                            )+'%)'}}>
                                                            {parseInt(sumVentas && sumVentas.sumVentas)}/
                                                            {
                                                                (parseInt(sumaGoals && sumaGoals.sumVentas)?
                                                                    parseInt(sumaGoals && sumaGoals.sumVentas):0)
                                                            }
                                                        </div> :
                                                        <div className="barSalesEmpty" style={{width: 'calc(100% + '+
                                                        (sumVentas && sumaGoals ?
                                                                sumVentas.sumVentas>sumaGoals.sumVentas?100:
                                                                (sumVentas.sumVentas*100/sumaGoals.sumVentas):0
                                                        )+'%)'}}>
                                                        {parseInt(sumVentas && sumVentas.sumVentas)}/
                                                        {
                                                            (parseInt(sumaGoals && sumaGoals.sumVentas)?
                                                                parseInt(sumaGoals && sumaGoals.sumVentas):0)
                                                        }
                                                    </div>
                                                        }
                                                    </div>
                                                    <div className="subTitle_Prospecto">
                                                        <span>Acumulado de mi equipo:
                                                            {
                                                                (parseInt(sumaGoals && sumaGoals.sumVentas)?
                                                                    parseInt(sumaGoals && sumaGoals.sumVentas):0)
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col l12 s12 rosaSection">
                                                <div className="canceledCont">
                                                    <h4 className=" StatTitle_Canceled">
                                                        <Tooltip arrow disableFocusListener title="Total de pedidos cancelados cada mes en mi equipo.">
                                                            <InfoOutlinedIcon fontSize="small"/> 
                                                        </Tooltip>
                                                        Ventas canceladas:
                                                        {parseInt(sumVentas && sumVentas.sumCancelaciones)}
                                                    </h4>
                                                </div>
                                            </div>

                                            <div className="col l12 s12 rosaSection">

                                                <div className="EfectCont">
                                                    <div className="col l8 s12 StatTitle_RatioDemoVentaCont">
                                                        <h4 className="staTile StatTitle_RatioDemoVenta">Ratio demo-venta</h4>
                                                        <p className="question_RatioDemoVenta">¿Cuántas demos necesito realizar para lograr 1 venta?:</p>
                                                    </div>
                                                    <div className="col l4 s12 blade_dataCont">
                                                        <div className="blade_effect_alt">
                                                            <span className="effectData">
                                                                {
                                                                    sumVentas && ventas && parseInt(sumVentas.sumVentas>0?sumVentas.sumVentas:0)>0 &&
                                                                    sumExperiencias && sumExperiencias.experienciasRealizadas>0 ?
                                                                    (
                                                                        parseInt(sumExperiencias.experienciasRealizadas)/
                                                                        parseInt(sumVentas.sumVentas)
                                                                    ).toFixed(2): '-'
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            //GERENTES
                        }
                        {
                            (currentUser.rol[0].value === 5) &&
                            <div className="col l12 s12 panelRosa">
                                <div>
                                    <h4 className="StatTitle_MyTeam">Mi Gerencia</h4>
                                    <div className="row">
                                        <div className="col l12 s12 rosaSection row">
                                            <div className="col l6 s12">
                                                <h4 className="staTile StatTitle_ExpProg">
                                                  Demos Programadas: 
                                                  <Tooltip arrow disableFocusListener title="Total de Experiencias (demos) agendadas respecto al objetivo declarado por mi fuerza de ventas.">
                                                      <InfoOutlinedIcon fontSize="large"/> 
                                                  </Tooltip>
                                                </h4>
                                                <div className="GraphCont">
                                                    <span className="infoGraph infoExpProg">
                                                        {sumExperiencias && sumExperiencias.experienciasProgramadas} / {sumaGoals && sumaGoals.sumExperiencias}
                                                    </span>

                                                    <div className="graphCircle">
                                                        <div className="graphRosa">
                                                            <CircularProgress
                                                            className= 'graphExpProg'
                                                            size= '16rem'
                                                            thickness= {6}
                                                            style={{color:'#019846'}}
                                                            variant="determinate"
                                                            value={(sumExperiencias && sumaGoals) ?
                                                                sumExperiencias.experienciasProgramadas>sumaGoals.sumExperiencias? 100:
                                                                (sumExperiencias.experienciasProgramadas*100/sumaGoals.sumExperiencias):
                                                                0} />
                                                        </div>
                                                    </div>
                                                    <div className="spinnerTM"></div>
                                                </div>
                                            </div>
                                            <div className="col l6 s12">
                                                <h4 className="staTile StatTitle_ExpDone">
                                                  Demos Realizadas: 
                                                  <Tooltip arrow disableFocusListener title="Total de Experiencias (demos) con pase de lista realizado, respecto al objetivo declarado por mi fuerza de ventas.">
                                                      <InfoOutlinedIcon fontSize="large"/> 
                                                  </Tooltip>
                                                </h4>
                                                <div className="GraphCont">
                                                    <span className="infoGraph infoStatTitle_ExpDone">
                                                    {sumExperiencias && sumExperiencias.experienciasRealizadas}/{sumaGoals && sumaGoals.sumExperiencias}
                                                    </span>
                                                    <div className="graphCircle ">
                                                        <div className="graphRosa">
                                                            <CircularProgress
                                                            className= 'graphStatTitle_ExpDone'
                                                            size= '16rem'
                                                            thickness= {6}
                                                            style={{color:'#019846'}}
                                                            variant="determinate"
                                                            value={sumExperiencias && sumaGoals ?
                                                                sumExperiencias.experienciasRealizadas>sumaGoals.sumExperiencias?100:
                                                                (sumExperiencias.experienciasRealizadas*100/sumaGoals.sumExperiencias):0} />
                                                        </div>
                                                    </div>
                                                    <div className="spinnerTM"></div>
                                                </div>
                                            </div>
                                            <div className="subTitle_Prospecto">
                                                <span>Acumulado de mi equipo: {sumaGoals && sumaGoals.sumExperiencias}</span>
                                            </div>
                                        </div>
                                        {
                                            //2===2 &&
                                            <>
                                            <div className="col l12 s12 rosaSection">
                                                <div className="ProspectoCont">
                                                    <h4 className="staTile StatTitle_Sales">
                                                      Ventas: 
                                                      <Tooltip arrow disableFocusListener title="Total de pedidos efectivos realizados en el mes, respecto al budget mensual.">
                                                          <InfoOutlinedIcon fontSize="large"/> 
                                                      </Tooltip>
                                                    </h4>
                                                    <div className="barSalesCont">
                                                        {
                                                        parseInt(sumVentas && sumVentas.sumVentas)>0 ? 
                                                            <div className="barSalesFill" style={{width: 'calc(0% + '+(
                                                                sumVentas && sumaGoals ?
                                                                    sumVentas.sumVentas>sumaGoals.sumProspectos?100:
                                                                    ((sumVentas.sumVentas*100)/sumaGoals.sumProspectos) : 0
                                                            )+'%)'}}>
                                                                {parseInt(sumVentas && sumVentas.sumVentas)} / {parseInt(myGoals && myGoals[0].budgets)}
                                                            </div>:
                                                            <div className="barSalesEmpty" style={{width: 'calc(100% + '+(
                                                                sumVentas && sumaGoals ?
                                                                    sumVentas.sumVentas>sumaGoals.sumProspectos?100:
                                                                    ((sumVentas.sumVentas*100)/sumaGoals.sumProspectos) : 0
                                                            )+'%)'}}>
                                                                {parseInt(sumVentas && sumVentas.sumVentas)} / {parseInt(myGoals && myGoals[0].budgets)}
                                                            </div>
                                                        }
                                                        
                                                    </div>
                                                    <div className="subTitle_Prospecto">
                                                        <span>Budgets: {parseInt(myGoals && myGoals[0].budgets)}</span>
                                                    </div>
                                                </div>
                                                </div>

                                            <div className="col l12 s12 rosaSection">
                                                <div className="ProspectoCont">
                                                    {/* <h4 className="staTile StatTitle_Sales">Ventas: </h4> */}
                                                    <div className="barSalesCont">
                                                        {
                                                        parseInt(sumVentas && sumVentas.sumVentas)>0 ?
                                                        <div className="barSalesFill" style={{width: 'calc(0% + '+(
                                                            sumVentas && sumaGoals ?
                                                                sumVentas.sumVentas>sumaGoals.sumVentas?100:
                                                                (sumVentas.sumVentas*100/sumaGoals.sumVentas):0
                                                        )+'%)'}}>
                                                            {parseInt(sumVentas && sumVentas.sumVentas)} / {sumaGoals && sumaGoals.sumVentas}
                                                        </div>:
                                                        <div className="barSalesEmpty" style={{width: 'calc(100% + '+(
                                                            sumVentas && sumaGoals ?
                                                                sumVentas.sumVentas>sumaGoals.sumVentas?100:
                                                                (sumVentas.sumVentas*100/sumaGoals.sumVentas):0
                                                        )+'%)'}}>
                                                            {parseInt(sumVentas && sumVentas.sumVentas)} / {sumaGoals && sumaGoals.sumVentas}
                                                        </div>
                                                        }
                                                    </div>
                                                    <div className="subTitle_Prospecto">
                                                        <span>
                                                          Acumulado de la gerencia: 
                                                          <Tooltip arrow disableFocusListener title="Total de ventas acumuladas respecto al objetivo declarado por mi fuerza de ventas.">
                                                              <InfoOutlinedIcon fontSize="large"/> 
                                                          </Tooltip>
                                                          {parseInt(sumaGoals && sumaGoals.sumVentas)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col l12 s12 rosaSection">
                                                <div className="canceledCont">
                                                    <h4 className=" StatTitle_Canceled">
                                                        <Tooltip arrow disableFocusListener title="Total de pedidos cancelados cada mes en mi gerencia.">
                                                          <InfoOutlinedIcon fontSize="small"/> 
                                                      </Tooltip>
                                                      Ventas canceladas: 
                                                      {parseInt(sumVentas && sumVentas.sumCancelaciones) + parseInt(ventas && ventas.length>0 && ventas[0].Cancelaciones)}
                                                    </h4>
                                                </div>
                                            </div>
                                            </>
                                        }
                                        <div className="col l6 s12 rosaSection">
                                            <div className="EfectCont">
                                                <div className="col l12 s12 blade_dataCont">
                                                    <h4 className="staTile StatTitle_Efect">Efectividad</h4>
                                                    <div className="blade_effect">
                                                            <span className="effectData">
                                                            {
                                                                (parseInt(sumExperiencias && sumExperiencias.experienciasProgramadas)/
                                                                parseInt(sumExperiencias && sumExperiencias.experienciasRealizadas)).toFixed(2)
                                                            }
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            //2===2 &&
                                            <>
                                        <div className="col l6 s12 rosaSection">

                                            <div className="EfectCont">
                                                <div className="col l12 s12 blade_dataCont">
                                                    <div className="col l8 s12 StatTitle_RatioDemoVentaCont">
                                                        <h4 className="staTile StatTitle_RatioDemoVenta">Ratio demo-venta</h4>
                                                    </div>
                                                    <div className="col l4 s12 blade_dataCont">
                                                        <div className="blade_effect_alt">
                                                            <span className="effectData">
                                                            {(
                                                                parseInt(sumExperiencias && sumExperiencias.experienciasRealizadas)/
                                                                parseInt(parseInt(sumVentas && sumVentas.sumVentas) +
                                                                    parseInt(parseInt(ventas && ventas.length>0 && ventas[0].Ventas_sin_tm_ganada)))
                                                            ).toFixed(2)}
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                            </>
                                        }



                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                    :
                    <div className="ObjetivosContainer">
                        <div className='ObjetivosButtonContainer'>
                            <Button className="ObjetivosButton" onClick={()=>handleClick('/mismetas', 'Mis Metas') }
                            >
                            <img src="/assets/img/icons/objetivos_white_23.png" className="iconObjetivos" alt="Mis objetivos"/>
                            <span className="ObjetivosButtonTxt">¿Qué quiero lograr este mes?</span>
                            </Button>
                        </div>
                    </div>
            }
            </div>
        }
        </div>
    );
}

