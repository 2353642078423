import AuthService from "../Services/AuthService";
import React, {useState, useEffect, useCallback} from 'react';
import axios from 'axios'
import Collections from "../Utils/collections";
import Button from '@material-ui/core/Button';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DoneIcon from '@material-ui/icons/Done';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {toast} from "react-toastify";
import Divider from '@material-ui/core/Divider';
import "react-toastify/dist/ReactToastify.css";
import {css} from "glamor";
import Lottie from 'react-lottie-player'
import loading from "../Lottie/33637-ondas-sonoras.json"
import Cropper from 'react-easy-crop'
import { makeStyles } from '@material-ui/core/styles'
import getCroppedImg from '../Utils/cropImage'
import Avatar from '@material-ui/core/Avatar';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        '& > *': {
            margin: '0px',
            width: '148px',
            height: '148px',
        },
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));
const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                Este campo es requerido!
            </div>
        );
    }
};

const Perfil = (props) => {
    const classes = useStyles();
    //let history = useHistory();
    const [editar] = useState(props.editar);
    const [userConectado] = useState(AuthService.getCurrentUser());
    const [currentUser, setCurrentUser] = useState(props.user);
    const [mostrador, setMostrador] = useState("");
    const [correoMstr, setCorreoMstr] = useState("");
    //const [telefonoMstr,setTelefonoMstr] = useState("");
    const [tieneAcceso, setTieneAcceso] = useState(userConectado.idu === currentUser.idu);
    const [estructura, setEstructura] = useState([]);
    const [completed, setCompleted] = useState(false);
    //const [files, setFiles] = useState(undefined);
    const [openModalFoto, setOpenModalFoto] = useState(false);
    const [foto, setFoto] = React.useState(typeof(props.user.profile_route)!=='undefined' ?
        (props.user.profile_route !== null ? props.user.profile_route: "") : "");
    const [editaPerfil, setEditaPerfil] = useState(currentUser.updatePassword?true:false);
    const [seePassword, setSeePassword] = useState(false);
    const [seePassword2, setSeePassword2] = useState(false);
    const [email, setEmail] = useState("");
    const [updatePassword] = useState(false);
    const [openModal, setOpenModal] = useState(currentUser.updatePassword);
    /* * * * I N I C I A  C R O P  I M A G E * * * */
    const [img, setImg] = useState("");
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)

    const onChangeUser = (event) => {
        setCurrentUser( {...currentUser,  [event.target.name]: event.target.value});
    };

    const onChangePass = (event) => {
        setCurrentUser( {...currentUser,  [event.target.name]: event.target.value});
    };

    const guardarUpdatePass = () => {
        //console.log(currentUser._id + " vamos a guardar estatus: " + updatePassword)
        if( updatePassword ){ //updatePassword){
            guardarUpdPass(currentUser._id, updatePassword, false)
        } else {
            setOpenModal(false);
        }
    };

    const guardarUpdPass=(id, updPass, salir)=>{
        AuthService.consult(`mutation{
                  updatePasswordUser(
                        id:"${id}"
                        updatePassword: ${!updPass}
                  ){
                        id
                        updatePassword
                  }
            }`)
            .then(result=>{
                //console.log(result);
                //console.log(currentUser.updatePassword)
                currentUser.updatePassword = !updPass
                //console.log(currentUser.updatePassword)
                localStorage.setItem("user", JSON.stringify(currentUser));
                setOpenModal(false);
                if(salir){
                    AuthService.logout();
                    localStorage.setItem("titleToolbar", "");
                    window.location.replace('/');
                }
            })
            .catch(error=>{
                console.log(error);
                setOpenModal(false);
            })
    };

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleFormSubmit(e);
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        //console.log("Cambiar datos: ");
        let continua = true;
        let mensaje= "";
        /*
        if(!currentUser.nombre || currentUser.nombre.length===0){
            continua = false;
            mensaje="Debes ingresar tu nombre.";
        }
        if(!currentUser.apellido || currentUser.apellido.length===0){
            continua = false;
            mensaje="Debes ingresar tus apellidos.";
        }
        if(!currentUser.email || currentUser.email===0){
            continua = false;
            mensaje="Debes ingresar tu correo.";
        }
        */

        if( /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*([^a-zA-Z\d\s]))(?=.*?[^\w\s]).{8,}$/.test(currentUser.password) ){
            if(currentUser.password !== currentUser.confPassword ){
                continua = false;
                mensaje="Tu contraseña no fue confirmada correctamente.";
            }
        } else {
            continua = false;
            mensaje = "Debes ingresar una contraseña con las condiciones mencionadas.";
        }

        if(!currentUser.password || currentUser.password===0){
            continua = false;
            mensaje="Debes ingresar una contraseña.";
        }

        //console.log(currentUser)
        //console.log(mensaje)
        if(continua){
            axios
                .post(Collections.ipServer + "/mails/changePassword", {
                    nombre: currentUser.nombre,
                    apellido: currentUser.apellido,
                    email: currentUser.email,
                    password: currentUser.password,
                    confPassword: currentUser.confPassword
                })
                .then(function (response) {
                    alerts("Se han modificado tus datos correctamente.", false);
                    guardarUpdPass(currentUser._id, true, true);
                })
                .catch(function (error) {
                    console.log(error);
                    alerts("Usuario no existente", true);
                });
        } else {
            alerts(mensaje, false);
        }
    };

    const handleCloseModal = (ev) =>{
        setOpenModal(false);
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    useEffect(() => {
        //const user = AuthService.getCurrentUser();
        setEmail(currentUser.email)
        //console.log(user)
    }, []);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                img,
                croppedAreaPixels,
                rotation
            )
            console.log('donee', { croppedImage })
            setCroppedImage(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])

    const onClose = useCallback(() => {
        setImg("")
        setCroppedImage(null)
    }, [])
    /* * * * F I N  C R O P  I M A G E * * * */

    useEffect(() => {
        console.log(currentUser)
        if(currentUser.rol[0].value===0){  // ES ADMIN
            AuthService.consult(`{users{
                    idu
                    nombre
                    apellido
                    correo
                    rol {
                      value
                      text
                    }
                    telefono
                    oficina: sucursal {
                      value
                      name
                    }
                    estructura {
                      idu
                    }
                }
            }`)
                .then(result=>{
                    //console.log(result.data.data.users);
                    let estrAll = result.data.data.users;
                    if (estrAll.length>0){
                        //console.log(estrAll);
                        setTieneAcceso(true)
                        let estrS = [].concat(
                            estrAll.filter((el,i)=>el.rol[0].value===0),
                            estrAll.filter((el,i)=>el.rol[0].value===9),
                            sortEstructura(estrAll.filter((el,i)=>el.rol[0].value===9)[0],
                                estrAll.filter((el,i)=>(el.idu!==null && el.rol[0].value!==0))
                                    .sort((a,b)=>b.rol[0].value - a.rol[0].value),
                                false));

                        setEstructura( estrS );
                        setCompleted(true);
                    }
                })
                .catch(error=>{
                    console.log(error);
                });
        } else {
            //Sacar datos del mostrador si es que vienen
            let datosMstr = currentUser.estructura.filter(dto=>dto.rol[0].value===55);
            if(datosMstr.length>0){
                setMostrador(datosMstr[0].nombre +' ' + datosMstr[0].apellido );
                //setTelefonoMstr(datosMstr[0].telefono);
                setCorreoMstr(datosMstr[0].correo);
            }
            // Ir por la estructura, pero 1ro ir por el rol arriba del rol logeado
            let iduEstructura=[currentUser];
            let verifyAccess = false;
            switch (userConectado.rol[0].value){
                case 1: // presentadora
                    verifyAccess = ( currentUser.idu === userConectado.idu )
                    break;
                case 3: // lider
                    verifyAccess = (currentUser.idu === userConectado.idu || currentUser.rol[0].value === 1)
                    break;
                case 5: // gerente - delegada
                    verifyAccess = (
                        currentUser.idu === userConectado.idu ||
                        currentUser.rol[0].value === 3 ||
                        currentUser.rol[0].value === 1
                    )
                    break;
                case 8: // area manager
                    verifyAccess = (
                        currentUser.idu === userConectado.idu ||
                        currentUser.rol[0].value === 5 ||
                        currentUser.rol[0].value === 3 ||
                        currentUser.rol[0].value === 1
                    )
                    break;
                case 9: // regional manager
                    //iduEstructura=[currentUser];
                    verifyAccess = (true);
                    break;
                case 0: // admin
                    verifyAccess = (true);
                    break;
                default:
                    //iduEstructura=[currentUser];
                    break;
            }
            //console.log(verifyAccess);
            setTieneAcceso(verifyAccess);
            if(verifyAccess && iduEstructura.length>0) {
                // Vamos a mandar el idu del que esta arriba de este usuario
                AuthService.consult(`{estructura(iduSuperior:"${iduEstructura[0].idu}" idu: "${currentUser.idu}" 
                            rol: ${currentUser.rol[0].value}){
                        idu
                        nombre
                        apellido
                        correo
                        rol {
                          value
                          text
                        }
                        telefono
                        oficina: sucursal {
                          value
                          name
                        }
                        estructura {
                          idu
                        }
                      }
                    }`)
                    .then((users) => {
                        // toda la estructura, incluyendo mismo nivel y un rol arriba
                        //let estrAll = iduEstructura.concat(users.data.data.estructura)
                        //console.log(users.data.data.estructura)
                        // estructura del rol de currentUser hacia abajo
                        //console.log(users.data.data.estructura)
                        let estrDown = users.data.data.estructura.filter((usr,i)=>usr.rol[0].value !== currentUser.rol[0].value);
                        // Acomodar la estructura de acuerdo a su rol y las que hay debajo de esta
                        if(currentUser.rol[0].value===3 || currentUser.rol[0].value===1){
                            setEstructura(
                                estrDown
                                .sort((a,b) => (a.rol[0].value < b.rol[0].value) ? 1 : ((b.rol[0].value > a.rol[0].value) ? -1 : 0))
                                .sort((a,b) => (a.oficina.name < b.oficina.name) ? 1 : ((b.oficina.name > a.oficina.name) ? -1 : 0))
                            );
                            setCompleted(true);
                        } else {
                            //setEstructura(estrDown);
                            let estrS = [].concat(estrDown[0], sortEstructura(currentUser, estrDown, false))
                            //Vamos a ordenar por oficina
                            //estrS.sort((a,b) => (a.oficina.name > b.oficina.name) ? 1 : ((b.oficina.name > a.oficina.name) ? -1 : 0))
                            setEstructura(estrS
                                .sort((a,b) => (a.rol[0].value < b.rol[0].value) ? 1 : ((b.rol[0].value > a.rol[0].value) ? -1 : 0))
                                .sort((a,b) => (a.oficina.name < b.oficina.name) ? 1 : ((b.oficina.name > a.oficina.name) ? -1 : 0))
                            );
                            setCompleted(true);
                        }

                    })
                    .catch(error => {
                        setCompleted(true);
                        console.log(error)
                    })
            } else {
                setCompleted(true);
            }
        }
    }, []);

    const sortEstructura = (user, estrDown, esLacayo) => {
        //console.log(user);
        //console.log(estrDown)
        // A este user meterle los que estan debajo de el
        let arrUserRolInfr = []; //Vamos a meter aqui los que estan debajo del rol de currentuser
        let newEstrDown = JSON.parse(JSON.stringify(estrDown));
        switch (user.rol[0].value){
            //case 1: // presentadora
            //arrUserRolInfr=estrDown.filter((el,i)=>el.rol[0].value===3);
            //break;

            case 3: // lider
                if(esLacayo){
                    // filtrar dentro de estrDown todos los usuarios donde aparezca el idu de user dentro de la estructura
                    arrUserRolInfr = estrDown.filter((el)=>
                        (el.estructura.filter((estr,j)=> (estr && estr.idu===user.idu) ).length>0) && el.rol[0].value===1
                    ).sort((a,b) => (a.oficina.name > b.oficina.name) ? 1 : ((b.oficina.name > a.oficina.name) ? -1 : 0))
                }else{
                    arrUserRolInfr=estrDown.filter((el,i)=>el.rol[0].value===1)
                        .sort((a,b) => (a.oficina.name > b.oficina.name) ? 1 : ((b.oficina.name > a.oficina.name) ? -1 : 0));
                }
                break;

            case 5: // gerente - delegada
                if(esLacayo){
                    //filtrar dentro de estrDown todos los usuarios donde aparezca el idu de user dentro de la estructura
                    arrUserRolInfr=estrDown.filter((el)=>
                        (el.estructura.filter((estr,j)=>(estr && estr.idu===user.idu)).length>0) && el.rol[0].value===3
                    ).sort((a,b) => (a.oficina.name > b.oficina.name) ? 1 : ((b.oficina.name > a.oficina.name) ? -1 : 0))
                }else {
                    arrUserRolInfr = estrDown.filter((el, i) => el.rol[0].value === 3)
                        .sort((a,b) => (a.oficina.name > b.oficina.name) ? 1 : ((b.oficina.name > a.oficina.name) ? -1 : 0));
                }
                break;
            case 8: // area manager
                if(esLacayo){
                    //filtrar dentro de estrDown todos los usuarios donde aparezca el idu de user dentro de la estructura
                    arrUserRolInfr=estrDown.filter((el)=>
                        (el.estructura.filter((estr,j)=>(estr && estr.idu===user.idu)).length>0) && el.rol[0].value===5
                    ).sort((a,b) => (a.oficina.name > b.oficina.name) ? 1 : ((b.oficina.name > a.oficina.name) ? -1 : 0))
                }else {
                    arrUserRolInfr = estrDown.filter((el, i) => el.rol[0].value === 5)
                        .sort((a,b) => (a.oficina.name > b.oficina.name) ? 1 : ((b.oficina.name > a.oficina.name) ? -1 : 0));
                }
                break;
            case 9: // regional manager
                if(esLacayo){
                    //filtrar dentro de estrDown todos los usuarios donde aparezca el idu de user dentro de la estructura
                    arrUserRolInfr=estrDown.filter((el)=>
                        (el.estructura.filter((estr,j)=>(estr && estr.idu===user.idu)).length>0) && el.rol[0].value===8
                    ).sort((a,b) => (a.oficina.name > b.oficina.name) ? 1 : ((b.oficina.name > a.oficina.name) ? -1 : 0))
                }else {
                    arrUserRolInfr = estrDown.filter((el, i) => el.rol[0].value === 8)
                        .sort((a,b) => (a.oficina.name > b.oficina.name) ? 1 : ((b.oficina.name > a.oficina.name) ? -1 : 0));
                }
                break;
            default: // Caso de un admin que tendría a todos en su poder
                //if(user.rol[0].value!==3){ // Como tenemos comentado arriba el rol de lider aqui lo valido
                    if(esLacayo){
                        //filtrar dentro de estrDown todos los usuarios donde aparezca el idu de user dentro de la estructura
                        arrUserRolInfr=estrDown.filter((el)=>
                            (el.estructura.filter((estr,j)=> (estr && estr.idu===user.idu) ).length>0) && el.rol[0].value===9
                        ).sort((a,b) => (a.oficina.name > b.oficina.name) ? 1 : ((b.oficina.name > a.oficina.name) ? -1 : 0))
                    }else {
                        arrUserRolInfr = estrDown.filter((el, i) => el.rol[0].value === 9)
                            .sort((a,b) => (a.oficina.name > b.oficina.name) ? 1 : ((b.oficina.name > a.oficina.name) ? -1 : 0));
                    }
                //}
                break;
        }
        // Aqui como mandamos a currentuser por props siempre entrara en la condición
        // Debemos sacar el user loegado y comparar o si el suer loegado es admin tambien entra
        //console.log(currentUser.idu + ' vs ' + user.idu)
        if(currentUser.idu === user.idu || user.rol[0].value===9){
            arrUserRolInfr.forEach((userInfr,i)=>{
                let arrLacayos = sortEstructura(userInfr, newEstrDown, true)
                Object.assign(userInfr, {"lacayos": arrLacayos })
            });
        }
        return arrUserRolInfr
    }

    const alerts = (msg,error) =>{
        error ?
            toast(msg, {
                className: css({
                    background: "red",
                    color: "white"
                }),
                progressClassName: css({
                    background: "#00AC46"
                })
            })
            :
            toast(msg, {
                className: css({
                    background: "black"
                }),
                progressClassName: css({
                    background: "purple"
                })
            })
    };

    const handleAddPhoto = () => {
        onClose()
        setOpenModalFoto(true);
    }
    const uploadCropFoto = () => {
        showCroppedImage();
    }
    const guardarFoto=()=>{
        if(croppedImage!==null){

            //console.log(croppedImage);

            AuthService.uploadImageProfile(croppedImage)
                .then(function(response) {
                    // Colocar la imagen
                    localStorage.setItem("user", JSON.stringify({...currentUser,...{"profile_route": currentUser.correo + "_profile.jpg"} }) );
                    setOpenModalFoto(false);
                    setFoto(currentUser.correo + "_profile.jpg")
                    window.location.reload()
                })
                .catch(function(error) {
                    console.log(error);
                });


        } else {
            setOpenModalFoto(false);
            alerts("No se pudo cargar la imagen.", true)
        }
    }

    // called every time a file's `status` changes
    const handleChangeStatusFoto = ({ meta, file }, status) => {
        if(status==="done"){
            /*
            if(meta.height===500){
                if(meta.width===500){
                */
                    //setFiles(file)
            const reader = new FileReader();
            reader.onload = e => {
                setImg(e.target.result);
                //console.log(e.target.result);
            };
            reader.readAsDataURL(file);
            /*
                } else{
                    alerts("El ancho de la imagen debe ser de 500px.", true)
                }
            } else {
                alerts("La altura de la imagen debe ser de 500px.", true)
            }
            */
        }
    }
    const handleCloseModalFoto = (ev) =>{
        setOpenModalFoto(false);
    };
    const editarPerfil = () => {
        console.log("Editar perfil")
        let showEdit = !editaPerfil;
        setEditaPerfil(showEdit);
    }

    return (
        <div>
            {
            !completed ?
            <Lottie
                loop
                animationData={loading}
                play
                style={{ width: 450, height: 450, display: "block", margin: "auto" }}
            />
            :
            <>{
                editaPerfil ?
                <Container component="main" className="loginMain" maxWidth="xs">
                    <CssBaseline/>
                    <div className="loginPaper">
                        <Typography component="h1" variant="h5">
                            Mi Perfil
                        </Typography>
                        <form className="loginForm">
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Correo electrónico"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                disabled
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="nombre"
                                label="Nombre Completo"
                                name="nombre"
                                autoComplete="nombre"
                                autoFocus
                                // value={(currentUser.nombre + ' ' + currentUser.apellido) || ''}
                                value={(currentUser.nombre) || ''}
                                onChange={onChangeUser}
                                validations={[required]}
                                disabled
                            />

                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="apellido"
                                label="Apellidos: "
                                id="apellido"
                                defaultValue={currentUser.apellido}
                                onChange={onChangeUser}
                                validations={[required]}
                                type="text"
                                style={{display: "none"}}
                            />
                            <br/>
                            <br/>
                            <div className="row">
                                <div className="col l11">
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Contraseña: "
                                        type={seePassword?"text":"password"}
                                        id="password"
                                        autoComplete="current-password"
                                        defaultValue={currentUser.password}
                                        onChange={onChangePass}
                                        validations={[required]}
                                    />
                                </div>
                                <div className="col l1" onClick={()=>setSeePassword(!seePassword)}>
                                    <IconButton style={{marginTop: '15px', marginLeft: '-65px'}}>
                                        <VisibilityIcon/>
                                    </IconButton>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col l11">
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="confPassword"
                                        label="Confirmar contraseña: "
                                        type={seePassword2?"text":"password"}
                                        id="confPassword"
                                        autoComplete="current-password"
                                        defaultValue={currentUser.confPassword}
                                        onChange={onChangePass}
                                        onKeyDown={_handleKeyDown}
                                        validations={[required]}
                                    />
                                </div>
                                <div className="col l1" onClick={()=>setSeePassword2(!seePassword2)}>
                                    <IconButton style={{marginTop: '15px', marginLeft: '-65px'}}>
                                        <VisibilityIcon/>
                                    </IconButton>
                                </div>
                            </div>
                            <br/>
                            <br/>
                            <div
                                style={{
                                    background: "lightgrey",
                                    padding: "20px",
                                    borderRadius: "15px"
                                }}
                            >
                                {
                                    // eslint-disable-next-line
                                    <Typography >
                                        💡 Tips para una contraseña segura:
                                    </Typography>
                                }
                                <ul>
                                    <li>- La contraseña debe tener al menos 8 caracteres y hasta 15 caracteres.</li>
                                    <li>- La contraseña debe contener al menos una letra mayúscula</li>
                                    <li>- Debe contener 2 números</li>
                                    <li>- Debe contener alguno de los siguientes caracteres especiales: @ (arroba) .
                                        (punto) - (guión) * (asterisco)
                                    </li>
                                </ul>
                            </div>

                            <br/>
                            <br/>
                            <div className="row">
                                <div className="col l6">
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        className="cancelEditPerfil"
                                        onClick={()=>setEditaPerfil(false)}
                                    >
                                        Cancelar
                                    </Button>
                                </div>
                                <div className="col l6">
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className="loginSubmit"
                                        onClick={handleFormSubmit}
                                    >
                                        Actualizar
                                    </Button>
                                </div>
                            </div>
                        </form>

                        <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
                            <DialogTitle className="titleNtfcAddEvent" id="form-dialog-title"
                                         onClose={handleCloseModal}>
                                <MuiDialogTitle disableTypography>
                                        <span
                                            className="TitleModalContact">¡Gracias por utilizar tu Agenda Digital!</span>
                                    {/* <IconButton className="closeButtonX" aria-label="close" onClick={handleCloseModal}>
                                <CloseIcon fontSize="large" />
                            </IconButton> */}
                                </MuiDialogTitle>
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Es necesario que actualices tu contraseña, te recomendamos que sea única y fácil
                                    de recordar. Por seguridad no la compartas con nadie.
                                </DialogContentText>
                                {/* <div className="row">
                            <div className="col l12 s12 m12 s12 fieldModal">
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<GreenCheckbox checked={updatePassword} onChange={handleChangeUpdtPass}
                                                                name="updatePassword" />}
                                        label="No volver a mostrar este mensaje." style={{color: "#00AC46"}}
                                    />
                                </FormGroup>
                            </div>
                        </div> */}
                            </DialogContent>
                            <DialogActions>
                                <Button className="MuiBadge-colorSecondary saveContactBtn"
                                        onClick={guardarUpdatePass} color="inherit">
                                    <DoneIcon className="iconBtnG" fontSize="small"/>
                                    Aceptar
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </Container>
                :
                <div>
                <div className="row">
                    <div className="col l3 s12">
                        <div className="profilePhotoCont" style={{
                            width: "150px", height: "150px", borderRadius: "100px",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderColor: "#00AC46"
                        }}>
                            {
                                typeof(foto) !== 'undefined' && foto.length>0 ?
                                    <div className={classes.root}>
                                        <Avatar alt="fotoperfil" src={Collections.imageProfilePath + foto} />
                                    </div>
                                    :
                                    <img src="/assets/img/icons/profile_23.png" alt="Perfil"
                                                             style={{width: "150px"}}/>
                            }
                            {
                                editar &&
                                <div>
                                    <Button className="greenThermomix" onClick={handleAddPhoto}
                                        style={{
                                            width: "150px", padding: "10px", color: "white", borderRadius: "10px", fontWeight: "600"
                                        }}>
                                        Agregar foto
                                    </Button>
                                    <br/>
                                    <br/>
                                </div>
                                
                            }
                        </div>
                    </div>
                    <div className="col l9 s12 ">
                        <div >
                            {
                                editar ?
                                    <h3 className="profileName" style={{color: "#00AC46"}}>
                                        {currentUser.nombre + ' ' + (currentUser.apellido!==null?currentUser.apellido: '')}</h3>
                                    :
                                    <h4 className="profileName" style={{color: "#00AC46"}}>
                                        {currentUser.nombre + ' ' + (currentUser.apellido!==null?currentUser.apellido: '')}</h4>
                            }
                        </div>
                        <div><strong classname="profileInfoLabel" >Rol:</strong> {currentUser.rol[0].text}</div>
                        <div><strong classname="profileInfoLabel" >Correo:</strong> {currentUser.correo}</div>

                        <div ><strong classname="profileInfoLabel" >IDU:</strong> {currentUser.idu}</div>
                        <div><strong classname="profileInfoLabel" >Tel:</strong> {currentUser.telefono}</div>
                        
                        {
                            mostrador.length>0 &&
                            <>
                                <Divider style={{margin: "10px 0"}}  variant="middle" />

                                <div className="profileInfoEO">
                                    <div><strong classname="profileInfoLabel" >Ejecutivo de Operación:</strong> {mostrador}</div>
                                    <div><strong classname="profileInfoLabel" >Correo:</strong> {correoMstr}</div>
                                    <div><strong classname="profileInfoLabel" >Teléfono:</strong> {
                                        //telefonoMstr
                                        '55 5350 9637'
                                    }</div>
                                </div>
                                
                            </>
                        }
                        { 
                            // <div className="col l6">Sucursal: {currentUser.oficina.name}</div>
                        }
                    </div>
                    <Dialog open={openModalFoto} onClose={handleCloseModalFoto} aria-labelledby="form-dialog-title">
                        <DialogTitle className="titleNtfcAddEvent" id="form-dialog-title"
                                     onClose={handleCloseModalFoto}>
                            <MuiDialogTitle disableTypography style={img.length!==0 && croppedImage===null ? {color: "white"} : {}}>
                                <span
                                    className="TitleModalContact">Agregar foto</span>
                                <IconButton className="closeButtonX" aria-label="close"
                                            onClick={handleCloseModalFoto}>
                                    <CloseIcon fontSize="large" style={img.length!==0 && croppedImage===null ? {color: "white"} : {}}/>
                                </IconButton>
                            </MuiDialogTitle>
                        </DialogTitle>
                        <DialogContent>
                            {
                                img.length===0 ?
                                    <Dropzone
                                        onChangeStatus={handleChangeStatusFoto}
                                        accept="image/*"
                                        maxFiles={1}
                                        inputContent="Arrastra una imagen o haz click aquí para cargarla"
                                        inputWithFilesContent={files => `${1 - files.length} more`}
                                        submitButtonDisabled={files => files.length < 1}
                                    />
                                    :
                                <div>
                                    {
                                        croppedImage!==null ?
                                            <div>
                                                <div className={classes.root}>
                                                    <Avatar alt="fotoperfil" src={croppedImage} />
                                                </div>
                                            </div>
                                            :
                                            <Cropper
                                                image={img}
                                                crop={crop}
                                                cropShape={'round'}
                                                rotation={rotation}
                                                zoom={zoom}
                                                aspect={2 / 2}
                                                onCropChange={setCrop}
                                                onRotationChange={setRotation}
                                                onCropComplete={onCropComplete}
                                                onZoomChange={setZoom}
                                            />
                                    }
                                </div>
                            }

                            <p style={img.length!==0 && croppedImage===null ? {color: "white"} : {}}>
                                Esta imagen es para uso único de la agenda.
                                Actualizar esta imagen NO cambia la fotografía en NetSuite ni en tu registro como Presentador(a).
                            </p>

                            {/* <div className="row">
                            <div className="col l12 s12 m12 s12 fieldModal">
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<GreenCheckbox checked={updatePassword} onChange={handleChangeUpdtPass}
                                                                name="updatePassword" />}
                                        label="No volver a mostrar este mensaje." style={{color: "#00AC46"}}
                                    />
                                </FormGroup>
                            </div>
                        </div> */}
                        </DialogContent>
                        <DialogActions>
                            {
                                croppedImage===null ?
                                    <Button className="MuiBadge-colorSecondary saveContactBtn"
                                            onClick={uploadCropFoto} color="inherit">
                                        <DoneIcon className="iconBtnG" fontSize="small"/>
                                        Aceptar
                                    </Button>
                                    :
                                    <Button className="MuiBadge-colorSecondary saveContactBtn"
                                            onClick={guardarFoto} color="inherit">
                                        <DoneIcon className="iconBtnG" fontSize="small"/>
                                        Guardar
                                    </Button>
                            }
                        </DialogActions>
                    </Dialog>
                </div>
                <br/>
                    { editar &&
                        <div className="row">
                            <div className="col l6 s12" style={{marginTop: "5px"}}>
                                <Button className="col l6" onClick={editarPerfil} 
                                    style={{ width: "175px", padding: "10px", color: "white", borderRadius: "10px", backgroundColor: "#00AC46"}}>
                                    Editar Perfil
                                </Button>
                            </div>
                        </div>
                    }
                {
                    tieneAcceso &&
                    <div>
                        <div className="row" style={{marginTop: "20px"}}>
                            <div className="col l4 s12">
                                <h5 style={{color: "#00AC46"}}>Mi estructura</h5>
                            </div>
                            <div className="col l4 s6 desktopshow">
                                <h5 style={{color: "#00AC46"}}>Información</h5>
                            </div>
                            </div>
                        {
                            estructura.map((user, i)=>
                                <div className="row" key={i}>
                                    {
                                        user.lacayos && user.lacayos.length>0 ?
                                        <Accordion className="viewSchema_accordion">
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon/>}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <div className={"col l12 s12 nopadding labelCategariaInterno" + user.rol[0].value}
                                                     style={{fontWeight: "900"}}>
                                                    <div className="col l4 s6 viewSchema_office">
                                                        {user.rol[0].text} <br/> {user.oficina.name}
                                                    </div>
                                                    <div className="col l4 s6 viewSchema_name">
                                                        {user.nombre + ' ' + (user.apellido && user.apellido.length > 0 ? user.apellido : '')}
                                                    </div>
                                                    <div className="col l4 s6 viewSchema_mail">{user.correo}</div>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails className="col l12">
                                                <div>
                                                    {lacayosLines(user.lacayos)}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                            :
                                        <div className={"col l12 s12 divAccordion labelCategariaInterno" + user.rol[0].value}
                                             style={{fontWeight: "900"}}>
                                            <div className="col l4 s6 viewSchema_office">
                                                {user.rol[0].text} <br/> {user.oficina.name}
                                            </div>
                                            <div className="col l4 s6 viewSchema_name">
                                                {user.nombre + ' ' + (user.apellido && user.apellido.length > 0 ? user.apellido : '')}
                                            </div>
                                            <div className="col l4 s12 viewSchema_mail">{user.correo}</div>
                                        </div>
                                    }
                                </div>
                            )
                        }
                    </div>
                }
                </div>
              }
            </>
            }
        </div>
    );
};
const lacayosLines = (lacayos) => {
    return lacayos.map((user, i) => {
        return (
            <div className="row viewSchema_element" key={i}>
                <div className={"col l12 s12 labelCategariaInterno"+user.rol[0].value} style={{fontWeight: "900"}}>
                    <div className="col l4 s6">
                        {user.rol[0].text} <br/> {user.oficina.name}
                    </div>
                    <div className="col l4 s6">
                        {user.nombre} {user.apellido}
                    </div>
                    <div className="col l4 s6">
                        {user.correo}
                    </div>
                </div>
                {
                    user.lacayos &&
                    <div className="col l12">
                        {lacayosLines(user.lacayos)}
                    </div>
                }
            </div>
        )
    })
};

export default Perfil;