import AuthService from "../Services/AuthService";
//import { useHistory } from 'react-router-dom'
import React, {useState, useEffect} from 'react';
import "react-toastify/dist/ReactToastify.css";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Lottie from 'react-lottie-player'
import loading from "../Lottie/33637-ondas-sonoras.json"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import {toast} from "react-toastify";
import {css} from "glamor";

/**
 * Componente para saber si el invitado asistira a un evento o no
 * @param {*} props datos del invitado
 * @returns Contenedor con la información de si el invitado asistira
 */
const GestionAdmins = (props) => {

    //let history = useHistory();

    const [usuarios, setUsuarios] = useState([]);
    const usuarioDefault = {
        nombre: "",
        apellido: "", 
        apellidoMaterno: "",
        correo: "", 
        telefono: ""
    }
    const [usuarioNew, setUsuarioNew] = useState(JSON.parse(JSON.stringify(usuarioDefault)))
    const [completed, setCompleted] = useState(true);
    const [openQuestion, setOpenQuestion] = useState(false);
    const [openFormUser, setOpenFormUser] = useState(false);
    const [idEliminar, setIdEliminar] = useState("")
    const [usuarioEditar, setUsuarioEditar] = useState(null)

    useEffect(() => {
        //console.log(props);
        getData()
    },[]);
    const getData = () => {
        setCompleted(false)
        let gql = `{ usersAdmin(rol:0){id idu nombre apellido correo profile_route rol { value text } telefono }}`
        AuthService.consult(gql)
            .then(resp=>{
                //console.log(resp);
                setCompleted(true)
                setUsuarios(resp.data.data.usersAdmin)
            })
            .catch(err=>console.log(err));
    }

    /**
     * Función para mostrar notificación al usuario
     * @param {*} msg Mensaje que se le mostrara al usuario
     * @param {*} error Para saber si corresponde a un error
     */
     const alerts = (msg, error) =>{
        toast(msg, {
            className: css({
                background: error ? "#E6332A":"#00AC46",
                color: "white"
            }),
            progressClassName: css({
                background: error ? "#00AC46":"#FFF"
            })
        });
    };

    const removeAdmin = (u) => {
        console.log("Preguntar si desea eliminar.. ", u.id);
        setIdEliminar(u.id);
        setOpenQuestion(true);
    }
    /**
     * Función para ejecutar el gql para eliminar el usuario
     */
     const aceptoEliminarUser = () => {
        AuthService.consult(`mutation{
              removeUser(id: "${idEliminar}"){ id }
            }`)
            .then(result => {
                setOpenQuestion(false)
                alerts("Se ha eliminado el usuario correctamente", false)
                getData()
            })
            .catch(error => {
                console.log("error removeUserExterno info: " + error)
            });
    }

    const addAdmin = () => {
        setUsuarioEditar(null)
        setUsuarioNew(JSON.parse(JSON.stringify(usuarioDefault)))
        //console.log("Add admin ")
        setOpenFormUser(true)
    }
    const guardarAdmin = () => {
        //console.log("Validar formulario y despues guardar ")
        //console.log(usuarioNew)
        let validar = validForm()
        if(validar.continuar){
            //console.log("Vamos a guardar...")
            let gqlAdd = `mutation{
                addAdmin(
                  nombre: "${usuarioNew.nombre}"
                  apellido: "${usuarioNew.apellido + (usuarioNew.apellidoMaterno ? ' ' + usuarioNew.apellidoMaterno: '')}"
                  correo: "${usuarioNew.correo}"
                  telefono: "${usuarioNew.telefono}"
                ){
                  id
                }
            }`
            AuthService.consult(gqlAdd)
            .then(resp=>{
                //console.log(resp)
                if(resp.data.errors){
                    //console.log(resp.data.errors[0].message)
                    alerts("Error al crear usuario, el correo de este usuario ya existe", true)
                } else {
                    setOpenFormUser(false)
                    alerts("Se ha creado el usuario correctamente", false)
                    getData()
                }
            }).catch(err=>{
                console.log(err)
                setOpenFormUser(false)
            })

        } else {
            //console.log(validar.msg)
            alerts(validar.msg, true)
        }
    }

    const validForm = () => {
        let continuar = true
        let msg = ''
        if(usuarioNew.nombre.length === 0){
            continuar = false
            msg = 'Agrega el nombre del usuario'
        } else
        if(usuarioNew.apellido.length === 0){
            continuar = false
            msg = 'Agrega el apellido paterno del usuario'
        } else
        if(usuarioNew.correo.length === 0){
            continuar = false
            msg = 'Agrega el correo del usuario'
        }
        return { "continuar": continuar, "msg": msg }
    }

    const editAdmin = (u) => {
        console.log("Editar admin ", u)
        setUsuarioEditar(u)
        setUsuarioNew(u)
        setOpenFormUser(true)
    }

    const actualizarAdmin = () => {
        console.log("Validar formulario y despues actualizar")
        let validar = validForm()
        if(validar.continuar){
            let gql = `mutation{
                updateAdmin(
                    id: "${usuarioNew.id}"
                    nombre: "${usuarioNew.nombre}"
                    apellido: "${usuarioNew.apellido + (usuarioNew.apellidoMaterno ? ' ' + usuarioNew.apellidoMaterno: '')}"
                    correo: "${usuarioNew.correo}"
                    telefono: "${usuarioNew.telefono}"
                ){
                    id
                }
            }`
            //console.log(gql)
            AuthService.consult(gql)
            .then(resp=>{
                //console.log(resp)
                setOpenFormUser(false)
                if(resp.data.errors){
                    alerts("Ocurrió un problema al actualziar la información", false)
                } else {
                    alerts("Se ha actualizado la información correctamente", false)
                    getData()
                }
            }).catch(err=>{
                console.log(err)
                setOpenFormUser(false)
            })
        } else {
            //console.log(validar.msg)
            alerts(validar.msg, true)
        }
    }

    const changeInput = (e) => {
        console.log(e.target.name + ': ' + e.target.value)
        let userNew = JSON.parse(JSON.stringify(usuarioNew))
        userNew[e.target.name] = (e.target.name==='correo' ? e.target.value: e.target.value.toString().toUpperCase())
        setUsuarioNew(userNew)
    }

    return (
        <Container>
            <CssBaseline/>
            {
            !completed ?
                <Lottie
                    loop
                    animationData={loading}
                    play
                    style={{ width: 450, height: 450, display: "block", margin: "auto" }}
                />
                :
            <>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} style={{height:"125px"}}/>
                <div className="row">
                    <h3>Gestión de Administradores</h3>  
                    <Button className="MuiBadge-colorSecondary" variant="contained" color="primary"
                        startIcon={<img src="/assets/img/icons/newcontact_white_23.png" alt="Nuevo Contacto" style={{mixBlendMode: "screen", width: "20px"}}/>} onClick={()=>addAdmin()}>Agregar Administrador</Button>
                    <table>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Correo</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                usuarios && usuarios.length>0 ? 
                                usuarios.map(usr=>
                                    <tr key={usr.id}>
                                        <td>
                                            {usr.nombre + (usr.apellido.length>0 ? ' ' + usr.apellido: '')}
                                        </td>
                                        <td>
                                            {usr.correo}
                                        </td>
                                        <td>
                                        <Button variant="contained" className="MuiBadge-colorSecondary" style={{backgroundColor: "#00AC46"}}
                                            startIcon={<img src="/assets/img/icons/edit_white_23.png" alt="Editar"
                                            style={{width: "20px"}}/>} onClick={()=>editAdmin(usr)}>Editar</Button>
                                        <br />
                                        <br />
                                        <Button variant="contained" className="MuiBadge-colorSecondary" style={{backgroundColor: "#00AC46"}}
                                            startIcon={<img src="/assets/img/icons/trash_white_23.png" alt="Editar"
                                            style={{width: "20px"}}/>} onClick={()=>removeAdmin(usr)}>Eliminar</Button>
                                        </td>
                                    </tr>
                                )
                                :
                                <tr>
                                    <td>
                                        Sin información
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>  

                    <Dialog
                        open={openFormUser}
                        onClose={()=>setOpenFormUser(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                        {usuarioEditar ? 'Actualizar datos': 'Agregar administrador'}
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                Nombre: <br />
                                <input type="text" name="nombre" onChange={changeInput} value={usuarioNew.nombre}/>
                                <br />
                                Apellido Paterno:
                                <input type="text" name="apellido" onChange={changeInput} value={usuarioNew.apellido}/>
                                <br />
                                Apellido Materno:
                                <input type="text" name="apellidoMaterno" onChange={changeInput} value={usuarioNew.apellidoMaterno}/>
                                <br />
                                Correo:
                                {
                                usuarioEditar ? 
                                    <input type="text" name="correo" value={usuarioNew.correo} disabled/>:
                                    <input type="text" name="correo" onChange={changeInput} value={usuarioNew.correo}/>
                                }
                                
                                <br />
                                Celular:
                                <input type="text" name="telefono" onChange={changeInput} value={usuarioNew.telefono}/>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button  variant="contained" className="MuiBadge-colorSecondary" style={{backgroundColor: "#00AC46"}} onClick={()=>setOpenFormUser(false)} color="primary">
                                Cancelar
                            </Button>
                            {
                                usuarioEditar ?
                                <Button  variant="contained" className="MuiBadge-colorSecondary" style={{backgroundColor: "#00AC46"}} onClick={actualizarAdmin} color="primary" autoFocus>
                                    Actualizar
                                </Button>:
                                <Button  variant="contained" className="MuiBadge-colorSecondary" style={{backgroundColor: "#00AC46"}} onClick={guardarAdmin} color="primary" autoFocus>
                                    Guardar
                                </Button>
                            }
                        </DialogActions>
                    </Dialog>  

                    <Dialog
                        open={openQuestion}
                        onClose={()=>setOpenQuestion(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Eliminar administrador</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                ¿Estás seguro de eliminar al usuario?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>setOpenQuestion(false)} color="primary">
                                Cancelar
                            </Button>
                            <Button onClick={aceptoEliminarUser} color="primary" autoFocus>
                                Aceptar
                            </Button>
                        </DialogActions>
                    </Dialog>  
                </div>
            </>
            }
        </Container>
    );
};

export default GestionAdmins;