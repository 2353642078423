/* eslint-disable default-case */
/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
import AuthService from "../Services/AuthService";
import React, {useState, useEffect, useCallback} from 'react';
import {useHistory, Link} from "react-router-dom"
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import axios from 'axios'
import Collections from "../Utils/collections";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {css} from "glamor";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DoneIcon from '@material-ui/icons/Done';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Cropper from 'react-easy-crop'
import getCroppedImg from '../Utils/cropImage'
import Lottie from 'react-lottie-player'
import loading from "../Lottie/33637-ondas-sonoras.json"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: '0px',
            width: '123px',
            height: '123px',
        },
    },
}));
const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                Este campo es requerido!
            </div>
        );
    }
};

/*
const BlueCheckbox = withStyles({
    root: {
        color: "#999999",
        '&$checked': {
            color: "#00AC46",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);
*/

export default function Ajustes(props) {
    let history = useHistory();
    const classes = useStyles();

    const [completed, setCompleted] = useState(true);
    const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
    const [email, setEmail] = useState("");
    const [openModal, setOpenModal] = useState(currentUser.updatePassword);
    const [openModalFoto, setOpenModalFoto] = useState(false);
    const [updatePassword] = useState(false);
    const [seePassword, setSeePassword] = useState(false);
    const [seePassword2, setSeePassword2] = useState(false);
    const [checkModoNctr, setCheckModoNctr] = useState(false);
    const [editaPerfil, setEditaPerfil] = useState(currentUser.updatePassword?true:false);
    //const [files, setFiles] = useState(undefined);
    const [foto, setFoto] = useState(typeof(AuthService.getCurrentUser().profile_route)!=='undefined'?
        AuthService.getCurrentUser().profile_route: "");

    /* * * * I N I C I A  C R O P  I M A G E * * * */
    const [img, setImg] = useState("");
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    const [openQuestion, setOpenQuestion] = useState(false);
    
    //Para pasar lista a evento
    const [idEventFind, setIdEventFind] = useState("");
    const [loadingDataEvent, setLoadingDataEvent] = useState(false)
    const [datosEvento, setDatosEvento] = useState(null)
    const [keyFind, setKeyFind] = useState(0)
    const [loadingBuscaEvent, setLoadingBuscaEvent] = useState(false)

    //Para calendario vorwerk
    const [idCalendarioVorwerk, setIdCalendarioVorwerk] = useState("")
    const [calendarioVorwerk, setCalendarioVorwerk] = useState([
        {
            nombreMes: "Enero",
            fechaInicio: new Date().getFullYear()-1+"-12-28",
            fechaFin: new Date().getFullYear()+"-01-31"
        },
        {
            nombreMes: "Febrero",
            fechaInicio: new Date().getFullYear()+"-02-01",
            fechaFin: new Date().getFullYear()+"-02-28"
        },
        {
            nombreMes: "Marzo",
            fechaInicio: new Date().getFullYear()+"-03-01",
            fechaFin: new Date().getFullYear()+"-03-28"
        },
        {
            nombreMes: "Abril",
            fechaInicio: new Date().getFullYear()+"-03-29",
            fechaFin: new Date().getFullYear()+"-05-02"
        },
        {
            nombreMes: "Mayo",
            fechaInicio: new Date().getFullYear()+"-05-03",
            fechaFin: new Date().getFullYear()+"-05-30"
        },
        {
            nombreMes: "Junio",
            fechaInicio: new Date().getFullYear()+"-05-31",
            fechaFin: new Date().getFullYear()+"-06-27"
        },
        {
            nombreMes: "Julio",
            fechaInicio: new Date().getFullYear()+"-06-28",
            fechaFin: new Date().getFullYear()+"-07-25"
        },
        {
            nombreMes: "Agosto",
            fechaInicio: new Date().getFullYear()+"-07-26",
            fechaFin: new Date().getFullYear()+"-08-29"
        },
        {
            nombreMes: "Septiembre",
            fechaInicio: new Date().getFullYear()+"-08-30",
            fechaFin: new Date().getFullYear()+"-09-26"
        },
        {
            nombreMes: "Octubre",
            fechaInicio: new Date().getFullYear()+"-09-27",
            fechaFin: new Date().getFullYear()+"-10-31"
        },
        {
            nombreMes: "Noviembre",
            fechaInicio: new Date().getFullYear()+"-11-01",
            fechaFin: new Date().getFullYear()+"-11-28"
        },
        {
            nombreMes: "Diciembre",
            fechaInicio: new Date().getFullYear()+"-11-29",
            fechaFin: new Date().getFullYear()+"-12-26"
        },

    ])
    const [contClnd, setContClnd] = useState(0)
    const [existeCalendario, setExisteCalendario] = useState(false)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                img,
                croppedAreaPixels,
                rotation
            )
            console.log('donee', { croppedImage })
            setCroppedImage(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])

    const onClose = useCallback(() => {
        setImg("")
        setCroppedImage(null)
    }, [])
    /* * * * F I N  C R O P  I M A G E * * * */

    useEffect(() => {
        //const user = AuthService.getCurrentUser();
        setEmail(currentUser.email)
        consultarCalendarioVorwerk()
        //console.log(user)
    }, []);
    const handleCloseModal = (ev) =>{
        setOpenModal(false);
    };
    const handleCloseModalFoto = (ev) =>{
        setOpenModalFoto(false);
    };
    // eslint-disable-next-line
    const activaModoNocturno = () => {
        let verModoNctr = !checkModoNctr;
        console.log("Activar modo nocturno: " + verModoNctr);
        setCheckModoNctr(verModoNctr)
    }
    const handleAddPhoto = () => {
        onClose()
        setOpenModalFoto(true);
    }
    const uploadCropFoto = () => {
        showCroppedImage();
    }
    const editarPerfil = () => {
        console.log("Editar perfil")
        let showEdit = !editaPerfil;
        setEditaPerfil(showEdit);
    }
    // eslint-disable-next-line
    const descargarDirectorio = () => {
        console.log("Consultar servicio para traer usuarios de netsuite")
        //AuthService.getAllUsersNetsuite();
    }
    const alerts = (msg,error) =>{
        error ?
            toast(msg, {
                className: css({
                    background: "red",
                    color: "white"
                }),
                progressClassName: css({
                    background: "#00AC46"
                })
            })
        :
        toast(msg, {
            className: css({
                background: "#00AC46",
                color: "white"
            }),
            progressClassName: css({
                background: "white"
            })
        })
    };

    const onChangeUser = (event) => {
        setCurrentUser( {...currentUser,  [event.target.name]: event.target.value});
    };

    const onChangePass = (event) => {
        setCurrentUser( {...currentUser,  [event.target.name]: event.target.value});
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        //console.log("Cambiar datos: ");
        let continua = true;
        let mensaje= "";
        /*
        if(!currentUser.nombre || currentUser.nombre.length===0){
            continua = false;
            mensaje="Debes ingresar tu nombre.";
        }
        if(!currentUser.apellido || currentUser.apellido.length===0){
            continua = false;
            mensaje="Debes ingresar tus apellidos.";
        }
        if(!currentUser.email || currentUser.email===0){
            continua = false;
            mensaje="Debes ingresar tu correo.";
        }
        */

        if( /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*([^a-zA-Z\d\s]))(?=.*?[^\w\s]).{8,}$/.test(currentUser.password) ){
            if(currentUser.password !== currentUser.confPassword ){
                continua = false;
                mensaje="Tu contraseña no fue confirmada correctamente.";
            }
        } else {
            continua = false;
            mensaje = "Debes ingresar una contraseña con las condiciones mencionadas.";
        }

        if(!currentUser.password || currentUser.password===0){
            continua = false;
            mensaje="Debes ingresar una contraseña.";
        }

        //console.log(currentUser)
        //console.log(mensaje)
        if(continua){
            axios
                .post(Collections.ipServer + "/mails/changePassword", {
                    nombre: currentUser.nombre,
                    apellido: currentUser.apellido,
                    email: currentUser.email,
                    password: currentUser.password,
                    confPassword: currentUser.confPassword
                })
                .then(function (response) {
                    alerts("Se han modificado tus datos correctamente.", false);
                    guardarUpdPass(currentUser._id, true, true);
                })
                .catch(function (error) {
                    console.log(error);
                    alerts("Usuario no existente", true);
                });
        } else {
            alerts(mensaje, false);
        }
    };

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleFormSubmit(e);
        }
    };
    /*
    const handleChangeUpdtPass = (e) => {
        //console.log(e.target.name + " - " + e.target.checked)
        setUpdatePassword(e.target.checked)
    };
    */

    const guardarUpdatePass = () => {
        //console.log(currentUser._id + " vamos a guardar estatus: " + updatePassword)
        if( updatePassword ){ //updatePassword){
            guardarUpdPass(currentUser._id, updatePassword, false)
        } else {
            setOpenModal(false);
        }
    };

    const guardarUpdPass=(id, updPass, salir)=>{
        AuthService.consult(`mutation{
                  updatePasswordUser(
                        id:"${id}"
                        updatePassword: ${!updPass}
                  ){
                        id
                        updatePassword
                  }
            }`)
            .then(result=>{
                //console.log(result);
                //console.log(currentUser.updatePassword)
                currentUser.updatePassword = !updPass
                //console.log(currentUser.updatePassword)
                localStorage.setItem("user", JSON.stringify(currentUser));
                setOpenModal(false);
                if(salir){
                    AuthService.logout();
                    localStorage.setItem("titleToolbar", "");
                    window.location.replace('/');
                }
            })
            .catch(error=>{
                console.log(error);
                setOpenModal(false);
            })
    };
    // eslint-disable-next-line

    const guardarFoto=()=>{
        if(croppedImage!==null){

            //console.log(croppedImage);

            AuthService.uploadImageProfile(croppedImage)
                .then(function(response) {
                    // Colocar la imagen
                    localStorage.setItem("user", JSON.stringify({...currentUser,...{"profile_route": currentUser.correo + "_profile.jpg"} }) );
                    setOpenModalFoto(false);
                    setFoto(currentUser.correo + "_profile.jpg")
                    window.location.reload()
                })
                .catch(function(error) {
                    console.log(error);
                });


        } else {
            setOpenModalFoto(false);
            alerts("No se pudo cargar la imagen.", true)
        }
    }

    // called every time a file's `status` changes
    const handleChangeStatusFoto = ({ meta, file }, status) => {
        if(status==="done"){
            /*
            if(meta.height===500){
                if(meta.width===500){
                */
            //setFiles(file)
            const reader = new FileReader();
            reader.onload = e => {
                setImg(e.target.result);
                //console.log(e.target.result);
            };
            reader.readAsDataURL(file);
            /*
                } else{
                    alerts("El ancho de la imagen debe ser de 500px.", true)
                }
            } else {
                alerts("La altura de la imagen debe ser de 500px.", true)
            }
            */
        }
    }
    const handleSincronizaVentas = () => {
        //console.log("Sincronizando ventas...")
        setOpenQuestion(true)
    }

    const aceptoSincronizar = () => {
        //console.log("sincronizar")
        setCompleted(false)
        AuthService.saveVentas().then(resp=>{
            //console.log("Datos guardados")
            setCompleted(true)
            setOpenQuestion(false)
            alerts("Se han sincronizado las ventas correctamente.", false);
        }).catch(err => {
            //console.log(err)
            setCompleted(true)
            setOpenQuestion(false)
            alerts("Ocurrió un error al sincronizar las ventas, intentalo nuevamente.", true);
        })
    }

    const buscarEventoPorID = (paseLista) =>{
        console.log("Buscar el evento del id: ", idEventFind)
        let gql = `{
            event(id:"${idEventFind}"){
              id titulo fecha start end color idTipo tipo modalidad1 modalidad2 notas direccion idAppointment 
              listaInvitados { idu nombre correo segmento esExtr sendMail asistire} status created_event iduPresentador 
              correoPresentador nombrePresentador seRepite frecuencia seRepiteHasta idEventoLigado fechaEvento horaInicioEvento 
              fechaReagenda fechaReagendaFormato fechaCreacion
            }
        }`
        AuthService.consult(gql).then(resp=>{
            //console.log(resp.data.data.event)
            setDatosEvento(resp.data.data.event)
            if(paseLista){
                setLoadingDataEvent(true)
                setLoadingBuscaEvent(false)
            } else {
                setLoadingDataEvent(false)
                setLoadingBuscaEvent(true)
            }
        }).catch(err=>{
            console.log(err)
            setLoadingDataEvent(false)
            setLoadingBuscaEvent(false)
        })
    }
    const pasarLista = () => {
        //console.log("pasar lista al evento: ", datosEvento.id)
        //console.log("lista de invitados: ", datosEvento.listaInvitados)
        let prepareListInvit = "["
        datosEvento.listaInvitados.forEach((invt, i)=>{
            prepareListInvit += '{idu: "'+ invt.idu + '", nombre: "'+ invt.nombre + '", correo: "' +
                invt.correo+'", segmento: "'+invt.segmento+'", esExtr: '+invt.esExtr+', sendMail: '+
                (invt.esExtr ? true: invt.sendMail)+', asistire: '+invt.asistire + '}'
        });
        prepareListInvit += "]"
        let gqlUpd = `mutation{
            updateInvitadosEventMail(
              id: "${datosEvento.id}"
              listaInvitados: ${prepareListInvit.replace(/}{/g, "},{")},
            ){
              id
              listaInvitados {
                idu
                nombre
                correo
                segmento
                esExtr
                sendMail
                asistire
              }
            }
          }`
        //console.log(gqlUpd)
        AuthService.consult(gqlUpd).then(resp=>{
            console.log(resp.data.data.updateInvitadosEventMail)
            alerts("Se ha pasado lista al evento", false)
            setIdEventFind("")
            setLoadingDataEvent(false)
            setDatosEvento(null)
            let key = keyFind + 1 // 623a7b8c79cac0169bb5d0c2
            setKeyFind(key)
        }).catch(err=>{console.log(err)})
    }

    const handleClick= (path, titleToolbar) => {
        console.log("gestion ...")
        window.localStorage.setItem('titleToolbar', titleToolbar)
        history.push(path);
        window.location.reload()
    }

    const consultarCalendarioVorwerk = () => {
        console.log("Consulta de calendario vorwerk Desde Ajustes")
        let gqlClndVrwr = `{
            calendarioVorwerk{
                id
                meses {
                    nombreMes
                    fechaInicio
                    fechaFin
                }
            }
        }`
        AuthService.consult(gqlClndVrwr)
        .then(result=>{
            let calendario = result.data.data.calendarioVorwerk
            if(calendario && calendario.length>0){
                setIdCalendarioVorwerk(calendario[0].id)
                let rsplCalendario = JSON.parse(JSON.stringify(calendarioVorwerk))
                //console.log("calendario.id -> ", calendario)
                calendario[0].meses.forEach(mes=>{
                    

                    let fechaInicioDate = new Date(mes.fechaInicio)
                    let fechaFinDate = new Date(mes.fechaFin)
                    let fechaInicio = (fechaInicioDate.getFullYear() + '-' +
                        ((fechaInicioDate.getMonth()+1).toString().length==1?'0'+(fechaInicioDate.getMonth()+1):(fechaInicioDate.getMonth()+1)) + '-' +
                        (fechaInicioDate.getDate().toString().length==1?'0'+fechaInicioDate.getDate():fechaInicioDate.getDate()))
                    let fechaFin = (fechaFinDate.getFullYear() + '-' +
                        ((fechaFinDate.getMonth()+1).toString().length==1?'0'+(fechaFinDate.getMonth()+1):(fechaFinDate.getMonth()+1)) + '-' +
                        (fechaFinDate.getDate().toString().length==1?'0'+fechaFinDate.getDate():fechaFinDate.getDate()))

                    
                    console.log(mes.nombreMes)
                    console.log(fechaInicio+' - '+fechaFin)
                    switch(mes.nombreMes){
                        case "Enero": 
                            rsplCalendario[0].fechaInicio = fechaInicio
                            rsplCalendario[0].fechaFin = fechaFin
                        break
                        case "Febrero": 
                            rsplCalendario[1].fechaInicio = fechaInicio
                            rsplCalendario[1].fechaFin = fechaFin
                        break
                        case "Marzo": 
                            rsplCalendario[2].fechaInicio = fechaInicio
                            rsplCalendario[2].fechaFin = fechaFin
                        break
                        case "Abril": 
                            rsplCalendario[3].fechaInicio = fechaInicio
                            rsplCalendario[3].fechaFin = fechaFin
                        break
                        case "Mayo": 
                            rsplCalendario[4].fechaInicio = fechaInicio
                            rsplCalendario[4].fechaFin = fechaFin
                        break
                        case "Junio": 
                            rsplCalendario[5].fechaInicio = fechaInicio
                            rsplCalendario[5].fechaFin = fechaFin
                        break
                        case "Julio": 
                            rsplCalendario[6].fechaInicio = fechaInicio
                            rsplCalendario[6].fechaFin = fechaFin
                        break
                        case "Agosto": 
                            rsplCalendario[7].fechaInicio = fechaInicio
                            rsplCalendario[7].fechaFin = fechaFin
                        break
                        case "Septiembre": 
                            rsplCalendario[8].fechaInicio = fechaInicio
                            rsplCalendario[8].fechaFin = fechaFin
                        break
                        case "Octubre": 
                            rsplCalendario[9].fechaInicio = fechaInicio
                            rsplCalendario[9].fechaFin = fechaFin
                        break
                        case "Noviembre": 
                            rsplCalendario[10].fechaInicio = fechaInicio
                            rsplCalendario[10].fechaFin = fechaFin
                        break
                        case "Diciembre": 
                            rsplCalendario[11].fechaInicio = fechaInicio
                            rsplCalendario[11].fechaFin = fechaFin
                        break
                    }
                })
                console.log("cambio rsplCalendario -> ", rsplCalendario)
                setCalendarioVorwerk(rsplCalendario)
                setContClnd(contClnd+1)
                setExisteCalendario(true)
            }
        })
        .catch(error=>{
            console.log("error consultarCalendarioVorwerk", error);
        })
    }

    const guardarCalendarioVorwerk = () => {
        console.log("Guardar calendario vorwerk ", calendarioVorwerk)
        calendarioVorwerk.forEach(mes=>{
            let dateFechaInicio = new Date(mes.fechaInicio +' 00:00:00')
            mes.fechaInicio = dateFechaInicio+""
            let dateFechaFin = new Date(mes.fechaFin +' 23:59:00')
            mes.fechaFin = dateFechaFin+""
        })
        console.log("formato calendario vorwerk ", calendarioVorwerk)
        
        let gqlAddClnd = `mutation {
            addCalendarioVorwerk(
              meses: ${JSON.stringify(calendarioVorwerk)}
              lastIdUser: "${currentUser.idu}"
            ){
              id
            }
        }`
        gqlAddClnd = (gqlAddClnd.replace(/\"nombreMes\"/g,"nombreMes").replace(/\"fechaInicio\"/g,"fechaInicio").replace(/\"fechaFin\"/g, "fechaFin"))
        AuthService.consult(gqlAddClnd)
        .then(result=>{
            console.log(result.data.data.addCalendarioVorwerk)
            window.location.reload()
        })
        .catch(error=>{
            console.log("error guardarCalendarioVorwerk", error);
        })
    }
    const actualizaCalendarioVorwerk = () => {
        console.log("Actualiza  calendario vorwerk ", idCalendarioVorwerk)
        
        calendarioVorwerk.forEach(mes=>{
            let dateFechaInicio = new Date(mes.fechaInicio +' 00:00:00')
            mes.fechaInicio = dateFechaInicio+""
            let dateFechaFin = new Date(mes.fechaFin +' 23:59:00')
            mes.fechaFin = dateFechaFin+""
        })
        console.log("formato calendario vorwerk ", calendarioVorwerk)
        
        let gqlUpdClnd = `mutation {
            updateCalendarioVorwerk(
                id: "${idCalendarioVorwerk}"
                meses: ${JSON.stringify(calendarioVorwerk)}
                lastIdUser: "${currentUser.idu}"
              ){
                id
              }
        }`
        gqlUpdClnd = (gqlUpdClnd.replace(/\"nombreMes\"/g,"nombreMes").replace(/\"fechaInicio\"/g,"fechaInicio").replace(/\"fechaFin\"/g, "fechaFin"))
        AuthService.consult(gqlUpdClnd)
        .then(result=>{
            console.log(result.data.data.updateCalendarioVorwerk)
            window.location.reload()
        })
        .catch(error=>{
            console.log("error actualizaCalendarioVorwerk ", error);
        })
        
    }
    const eliminaCalendarioVorwerk = () => {
        console.log("Elimina  calendario vorwerk ", idCalendarioVorwerk)
        let gqlDeleteCalendario = `mutation{
            removeCalendarioVorwerk(id:"${idCalendarioVorwerk}"){
              id
            }
        }`
        AuthService.consult(gqlDeleteCalendario)
        .then(result=>{
            console.log(result.data.data.removeCalendarioVorwerk)
            window.location.reload()
        })
        .catch(error=>{
            console.log("error guardarCalendarioVorwerk", error);
        })
    }

    const handleChangeCalendario = (ev) => {
        // eslint-disable-next-line no-unused-vars
        let target = ev.target 
        console.log("calendarioVorwerk -> ", calendarioVorwerk)
        console.log(ev.target.name +' = ' + ev.target.value)
        let rsplCalendario = JSON.parse(JSON.stringify(calendarioVorwerk))
        switch(ev.target.name){
            case "eneroInicio": 
                rsplCalendario[0].fechaInicio = ev.target.value
            break
            case "eneroFin": 
                rsplCalendario[0].fechaFin = ev.target.value
            break
            case "febreroInicio": 
                rsplCalendario[1].fechaInicio = ev.target.value
            break
            case "febreroFin": 
                rsplCalendario[1].fechaFin = ev.target.value
            break
            case "marzoInicio": 
                rsplCalendario[2].fechaInicio = ev.target.value
            break
            case "marzoFin": 
                rsplCalendario[2].fechaFin = ev.target.value
            break
            case "abrilInicio": 
                rsplCalendario[3].fechaInicio = ev.target.value
            break
            case "abrilFin": 
                rsplCalendario[3].fechaFin = ev.target.value
            break
            case "mayoInicio": 
                rsplCalendario[4].fechaInicio = ev.target.value
            break
            case "mayoFin": 
                rsplCalendario[4].fechaFin = ev.target.value
            break
            case "junioInicio": 
                rsplCalendario[5].fechaInicio = ev.target.value
            break
            case "junioFin": 
                rsplCalendario[5].fechaFin = ev.target.value
            break
            case "julioInicio": 
                rsplCalendario[6].fechaInicio = ev.target.value
            break
            case "julioFin": 
                rsplCalendario[6].fechaFin = ev.target.value
            break
            case "agostoInicio": 
                rsplCalendario[7].fechaInicio = ev.target.value
            break
            case "agostoFin": 
                rsplCalendario[7].fechaFin = ev.target.value
            break
            case "septiembreInicio": 
                rsplCalendario[8].fechaInicio = ev.target.value
            break
            case "septiembreFin": 
                rsplCalendario[8].fechaFin = ev.target.value
            break
            case "octubreInicio": 
                rsplCalendario[9].fechaInicio = ev.target.value
            break
            case "octubreFin": 
                rsplCalendario[9].fechaFin = ev.target.value
            break
            case "noviembreInicio": 
                rsplCalendario[10].fechaInicio = ev.target.value
            break
            case "noviembreFin": 
                rsplCalendario[10].fechaFin = ev.target.value
            break
            case "diciembreInicio": 
                rsplCalendario[11].fechaInicio = ev.target.value
            break
            case "diciembreFin": 
                rsplCalendario[11].fechaFin = ev.target.value
            break
        }
        console.log("cambio rsplCalendario -> ", rsplCalendario)
        setCalendarioVorwerk(rsplCalendario)
    };

    return (
        <div>
            {
            !completed ?
                <Lottie
                    loop
                    animationData={loading}
                    play
                    style={{ width: 450, height: 450, display: "block", margin: "auto" }}
                />
                :
            <>
            <div className="row" style={{textAlign: "center"}}>
                <h3>Gestionar administradores</h3>
                <div className="col-lg-12">
                    <br/>
                    <Link to="/manageAdmins">
                        <Button
                            style={{
                                width: "250px", padding: "10px", color: "white", borderRadius: "10px", 
                                fontWeight: "600",backgroundColor: "#00AC46"
                            }} onClick={()=>handleClick('/manageAdmins', 'Gestionar Administradores') }>
                            Gestionar
                        </Button>
                    </Link>
                </div>
            </div>
            <br />
            <div className="row" style={{textAlign: "center"}}>
                <h3>Ventas</h3>
                <div className="col-lg-12">
                    <span><b>Instrucciones: </b>Da click en el botón para sincronizar las ventas solo en caso de que sea necesario.</span>
                </div>
                <div className="col-lg-12">
                    <br/>
                    <Button onClick={handleSincronizaVentas}
                        style={{
                            width: "250px", padding: "10px", color: "white", borderRadius: "10px", 
                            fontWeight: "600",backgroundColor: "#00AC46"
                        }}>
                        Sincronizar Ventas
                    </Button>
                </div>
            </div>
            <br/>
            <div className="row" style={{textAlign: "center"}}>
                <h3>Pasar Lista a un evento</h3>
                <div className="col l12" style={{marginBottom: "20px", textAlign: "left"}}>
                    <span><b>Instrucciones: </b>Coloca el ID del evento y da click en el botón "Buscar".</span>
                </div>
                
                <div className="col l8">
                    <TextField id="outlined-basic" label="ID evento" name="idEventFind" variant="outlined" style={{width:"100%"}}
                        onChange={(event) =>{
                            console.log(event.target.value)
                            setIdEventFind(event.target.value)
                            }} key={keyFind}/>
                </div>
                <div className="col l4">
                    <Button onClick={()=>buscarEventoPorID(true)}
                        style={{
                            width: "150px", color: "white", borderRadius: "10px", 
                            fontWeight: "600",backgroundColor: "#00AC46"
                        }}>
                        Buscar
                    </Button>
                </div>
                <div className="col l12">
                    {
                        loadingDataEvent && 
                        <div style={{margin: "25px", border: `1px solid #00AC46`, borderRadius: "5px"}}>
                            {
                                datosEvento!==null ?
                                <div className="row">
                                    <div className="col l8">
                                        <br />
                                        <span><b>Evento: </b> {datosEvento.tipo}</span> <br />
                                        <span><b>Fecha del evento: </b> {datosEvento.fechaEvento + ' ' + datosEvento.horaInicioEvento}</span><br />
                                        <span><b>Nombre del presentador: </b> {datosEvento.nombrePresentador}</span> 
                                        {
                                            // JSON.stringify(datosEvento)
                                        }
                                    </div>
                                    <div className="col l4">
                                    <Button onClick={pasarLista}
                                        style={{width: "150px", color: "white", borderRadius: "10px", 
                                            fontWeight: "600",backgroundColor: "#00AC46", marginTop: "28px"}}>
                                        Pasar Lista
                                    </Button>
                                    </div>
                                </div>
                                :
                                <div>
                                    <p>No se encontraron datos con este Id</p>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>



            <br/>
            <div className="row" style={{textAlign: "center"}}>
                <h3>Buscador de evento</h3>
                <div className="col l12" style={{marginBottom: "20px", textAlign: "left"}}>
                    <span><b>Instrucciones: </b>Coloca el ID del evento y da click en el botón "Buscar".</span>
                </div>
                
                <div className="col l8">
                    <TextField id="outlined-basic" label="ID evento" name="idEventFind" variant="outlined" style={{width:"100%"}}
                        onChange={(event) =>{
                            console.log(event.target.value)
                            setIdEventFind(event.target.value)
                            }} key={keyFind}/>
                </div>
                <div className="col l4">
                    <Button onClick={()=>buscarEventoPorID(false)}
                        style={{
                            width: "150px", color: "white", borderRadius: "10px", 
                            fontWeight: "600",backgroundColor: "#00AC46"
                        }}>
                        Buscar
                    </Button>
                </div>
                <div className="col l12">
                    {
                        loadingBuscaEvent && 
                        <div style={{margin: "25px", border: `1px solid #00AC46`, borderRadius: "5px"}}>
                            {
                                datosEvento!==null ?
                                <div className="row">
                                    <div className="col l12">
                                        <h5>Datos del evento {/* 6283ce11a2d0c03c49c508a6 */}</h5>
                                    </div>
                                    <div className="col l4">
                                        <p><b>Datos</b></p>
                                        <span><b>Fecha de creación del evento: </b> {datosEvento.fechaCreacion}</span><br />
                                        <span><b>Evento: </b> {
                                            datosEvento.titulo.length>0 ? datosEvento.titulo:datosEvento.tipo
                                        }</span> <br />
                                        <span><b>Fecha y hora del evento: </b> {datosEvento.fechaEvento + ' ' + datosEvento.horaInicioEvento}</span><br />
                                        <span><b>Nombre del presentador: </b> {datosEvento.nombrePresentador}</span> <br />
                                        <span><b>Modalidad 1: </b> {datosEvento.modalidad1}</span> <br />
                                        <span><b>Modalidad 2: </b> {datosEvento.modalidad2}</span> <br />
                                        <span><b>Notas: </b> {datosEvento.notas}</span> <br />
                                        <span><b>Dirección: </b> {datosEvento.direccion}</span><br /> 
                                        <span><b>Evento frecuente: </b> {datosEvento.seRepite?'Si':'No'}</span> <br />
                                        {
                                            datosEvento.seRepite && 
                                            new Date(datosEvento.start).getTime() < new Date(datosEvento.seRepiteHasta).getTime() &&
                                            <div><span><b>Se repite hasta: </b> {
                                                new Date(datosEvento.seRepiteHasta).getDate() + '/' + 
                                                (new Date(datosEvento.seRepiteHasta).getMonth()+1) + '/' +
                                                new Date(datosEvento.seRepiteHasta).getFullYear()
                                            }</span> <br /></div>
                                        }
                                    </div>
                                    <div className="col l4">
                                        <p><b>Lista de Invitados</b></p>
                                        <div className="col l12 s12 fieldPeopleInvite">
                                            {
                                                datosEvento.listaInvitados.map((invt, index) =>
                                                <div className="row" style={{textAlign: 'left'}}>
                                                    <div className="col l1">
                                                        {index+1}
                                                    </div>
                                                    <div className="col l11">
                                                        {invt.nombre}
                                                        <br/>
                                                        <b>Correo: </b> {invt.correo}
                                                        <br/>
                                                        <b>Pase de Lista: </b> {invt.sendMail ? 'Si': 'No'}
                                                    </div>
                                                </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="col l4">
                                        <p><b>Estatus</b></p>
                                        {
                                            datosEvento.status == 1 &&
                                            <div><span><b>Agendado</b></span> <br /></div>
                                        }
                                        {
                                            <div>
                                                {
                                                datosEvento.status == 2 && datosEvento.fechaReagendaFormato && datosEvento.fechaReagendaFormato.length>0 ?
                                                <div>
                                                    <span><b>Reagendado para : </b> {datosEvento.fechaReagendaFormato}</span> <br />
                                                </div> : 
                                                datosEvento.status == 2 &&
                                                <div>
                                                    <span><b>Reagendado</b></span> <br />
                                                </div>
                                                }
                                            </div>
                                        }
                                        {
                                            datosEvento.status == 0 &&
                                            <div><span><b>Cancelado</b></span><br /></div>
                                        }
                                    </div>
                                </div>
                                :
                                <div>
                                    <p>No se encontraron datos con este Id</p>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>

            <br/>
            <div className="row" style={{textAlign: "center", borderStyle: "solid", borderRadius: "15px"}}>
                <h3>Calendario Vorwerk {existeCalendario ? '': ' Default'}</h3>
                
                <div className="col l12" style={{marginBottom: "20px", textAlign: "left"}}>
                    <span><b>Instrucciones: </b>Guarda la fecha inicio y fecha fin para cada mes vorwerk, al finalizar da click en el botón "Guardar".</span>
                </div>
                <div className="col l12" style={{marginBottom: "20px", textAlign: "left"}} key={contClnd}>
                    <div className="col l6" style={{textAlign: "center"}}>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <b>Mes</b>
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <b>Fecha Inicio</b>
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <b>Fecha Fin</b>
                        </div>
                        {/*ENERO*/}
                        <div className="col l4" style={{textAlign: "center", marginTop: "30px"}}>
                            Enero
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[0].fechaInicio}
                                id="eneroInicio" name="eneroInicio" label="Enero" 
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true, }}
                            />
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[0].fechaFin}
                                id="eneroFin" name="eneroFin" label="Enero"
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true,}}
                            />
                        </div>
                        {/*FEBRERO*/}
                        <div className="col l4" style={{textAlign: "center", marginTop: "30px"}}>
                            Febrero
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[1].fechaInicio}
                                id="febreroInicio" name="febreroInicio" label="Febrero" 
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true, }}
                            />
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[1].fechaFin}
                                id="febreroFin" name="febreroFin" label="Febrero"
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true,}}
                            />
                        </div>
                        {/*Marzo*/}
                        <div className="col l4" style={{textAlign: "center", marginTop: "30px"}}>
                            Marzo
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[2].fechaInicio}
                                id="marzoInicio" name="marzoInicio" label="Marzo" 
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true, }}
                            />
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[2].fechaFin}
                                id="marzoFin" name="marzoFin" label="Marzo"
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true,}}
                            />
                        </div>
                        {/*ABRIL*/}
                        <div className="col l4" style={{textAlign: "center", marginTop: "30px"}}>
                            Abril
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[3].fechaInicio}
                                id="abrilInicio" name="abrilInicio" label="Abril" 
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true, }}
                            />
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[3].fechaFin}
                                id="abrilFin" name="abrilFin" label="Abril"
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true,}}
                            />
                        </div>
                        {/*MAYO*/}
                        <div className="col l4" style={{textAlign: "center", marginTop: "30px"}}>
                            Mayo
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[4].fechaInicio}
                                id="mayoInicio" name="mayoInicio" label="Mayo" 
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true, }}
                            />
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[4].fechaFin}
                                id="mayoFin" name="mayoFin" label="Mayo"
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true,}}
                            />
                        </div>
                        {/*JUNIO*/}
                        <div className="col l4" style={{textAlign: "center", marginTop: "30px"}}>
                            Junio
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[5].fechaInicio}
                                id="JunioInicio" name="JunioInicio" label="Junio" 
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true, }}
                            />
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[5].fechaFin}
                                id="JunioFin" name="JunioFin" label="Junio"
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true,}}
                            />
                        </div>

                    </div>
                    <div className="col l6" style={{textAlign: "center"}}>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <b>Mes</b>
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <b>Fecha Inicio</b>
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <b>Fecha Fin</b>
                        </div>
                        {/*JULIO*/}
                        <div className="col l4" style={{textAlign: "center", marginTop: "30px"}}>
                            Julio
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[6].fechaInicio}
                                id="julioInicio" name="julioInicio" label="Julio" 
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true, }}
                            />
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[6].fechaFin}
                                id="julioFin" name="julioFin" label="Julio"
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true,}}
                            />
                        </div>
                        {/*AGOSTO*/}
                        <div className="col l4" style={{textAlign: "center", marginTop: "30px"}}>
                            Agosto
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[7].fechaInicio}
                                id="agostoInicio" name="agostoInicio" label="Agosto" 
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true, }}
                            />
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[7].fechaFin}
                                id="agostoFin" name="agostoFin" label="Agosto"
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true,}}
                            />
                        </div>
                        {/*SEPTIEMBRE*/}
                        <div className="col l4" style={{textAlign: "center", marginTop: "30px"}}>
                            Septiembre
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[8].fechaInicio}
                                id="septiembreInicio" name="septiembreInicio" label="Septiembre" 
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true, }}
                            />
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[8].fechaFin}
                                id="septiembreFin" name="septiembreFin" label="Septiembre"
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true,}}
                            />
                        </div>
                        {/*OCTUBRE*/}
                        <div className="col l4" style={{textAlign: "center", marginTop: "30px"}}>
                            Octubre
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[9].fechaInicio}
                                id="octubreInicio" name="octubreInicio" label="Octubre" 
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true, }}
                            />
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[9].fechaFin}
                                id="octubreFin" name="octubreFin" label="Octubre"
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true,}}
                            />
                        </div>
                        {/*NOVIEMBRE*/}
                        <div className="col l4" style={{textAlign: "center", marginTop: "30px"}}>
                            Noviembre
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[10].fechaInicio}
                                id="noviembreInicio" name="noviembreInicio" label="Noviembre" 
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true, }}
                            />
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[10].fechaFin}
                                id="noviembreFin" name="noviembreFin" label="Noviembre"
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true,}}
                            />
                        </div>
                        {/*DICIEMBRE*/}
                        <div className="col l4" style={{textAlign: "center", marginTop: "30px"}}>
                            Diciembre
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[11].fechaInicio}
                                id="diciembreInicio" name="diciembreInicio" label="Diciembre" 
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true, }}
                            />
                        </div>
                        <div className="col l4" style={{textAlign: "center"}}>
                            <TextField className={classes.textField} type="date" defaultValue={calendarioVorwerk[11].fechaFin}
                                id="diciembreFin" name="diciembreFin" label="Diciembre"
                                onChange={handleChangeCalendario} InputLabelProps={{ shrink: true,}}
                            />
                        </div>
                    </div>    
                </div>
                <div className="col l6">
                </div>
                <div className="col l6" style={{textAlign: "right", marginBottom: "30px", marginTop: "30px"}}>
                    { existeCalendario ? 
                        <>
                            <Button onClick={eliminaCalendarioVorwerk}
                                style={{
                                    width: "150px", color: "white", borderRadius: "10px", 
                                    fontWeight: "600",backgroundColor: "#E6332A", marginRight: "15px"
                                }}>
                                Eliminar
                            </Button>
                            <Button onClick={actualizaCalendarioVorwerk}
                                style={{
                                    width: "150px", color: "white", borderRadius: "10px", 
                                    fontWeight: "600",backgroundColor: "#00AC46"
                                }}>
                                Actualizar
                            </Button>
                        </>: 
                        <>
                            <Button onClick={guardarCalendarioVorwerk}
                                style={{
                                    width: "150px", color: "white", borderRadius: "10px", 
                                    fontWeight: "600",backgroundColor: "#00AC46"
                                }}>
                                Guardar
                            </Button>
                        </>
                    }
                </div>
            </div>

            <Dialog
                open={openQuestion}
                onClose={()=>setOpenQuestion(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Sincronizar ventas</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Estás seguro de sincronizar las ventas?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setOpenQuestion(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={aceptoSincronizar} color="primary" autoFocus>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
            {
                1==2 &&
            <>
            {
                editaPerfil ?
                    <Container component="main" className="loginMain" maxWidth="xs">
                        <CssBaseline/>
                        <div className="loginPaper">
                            <Typography component="h1" variant="h5">
                                Mi Perfil
                            </Typography>
                            <form className="loginForm">
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Correo electrónico"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    value={email}
                                    disabled
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="nombre"
                                    label="Nombre Completo"
                                    name="nombre"
                                    autoComplete="nombre"
                                    autoFocus
                                    // value={(currentUser.nombre + ' ' + currentUser.apellido) || ''}
                                    value={(currentUser.nombre) || ''}
                                    onChange={onChangeUser}
                                    validations={[required]}
                                    disabled
                                />

                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="apellido"
                                    label="Apellidos: "
                                    id="apellido"
                                    defaultValue={currentUser.apellido}
                                    onChange={onChangeUser}
                                    validations={[required]}
                                    type="text"
                                    style={{display: "none"}}
                                />
                                <br/>
                                <br/>
                                <div className="row">
                                    <div className="col l11">
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="password"
                                            label="Contraseña: "
                                            type={seePassword?"text":"password"}
                                            id="password"
                                            autoComplete="current-password"
                                            defaultValue={currentUser.password}
                                            onChange={onChangePass}
                                            validations={[required]}
                                        />
                                    </div>
                                    <div className="col l1" onClick={()=>setSeePassword(!seePassword)}>
                                        <IconButton style={{marginTop: '15px', marginLeft: '-65px'}}>
                                            <VisibilityIcon/>
                                        </IconButton>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col l11">
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="confPassword"
                                            label="Confirmar contraseña: "
                                            type={seePassword2?"text":"password"}
                                            id="confPassword"
                                            autoComplete="current-password"
                                            defaultValue={currentUser.confPassword}
                                            onChange={onChangePass}
                                            onKeyDown={_handleKeyDown}
                                            validations={[required]}
                                        />
                                    </div>
                                    <div className="col l1" onClick={()=>setSeePassword2(!seePassword2)}>
                                        <IconButton style={{marginTop: '15px', marginLeft: '-65px'}}>
                                            <VisibilityIcon/>
                                        </IconButton>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                                <div
                                    style={{
                                        background: "lightgrey",
                                        padding: "20px",
                                        borderRadius: "15px"
                                    }}
                                >
                                    {
                                        // eslint-disable-next-line
                                        <Typography >
                                            💡 Tips para una contraseña segura:
                                        </Typography>
                                    }
                                    <ul>
                                        <li>- La contraseña debe tener al menos 8 caracteres y hasta 15 caracteres.</li>
                                        <li>- La contraseña debe contener al menos una letra mayúscula</li>
                                        <li>- Debe contener 2 números</li>
                                        <li>- Debe contener alguno de los siguientes caracteres especiales: @ (arroba) .
                                            (punto) - (guión) * (asterisco)
                                        </li>
                                    </ul>
                                </div>

                                <br/>
                                <br/>
                                <div className="row">
                                    <div className="col l6">
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            className="cancelEditPerfil"
                                            onClick={()=>setEditaPerfil(false)}
                                        >
                                            Cancelar
                                        </Button>
                                    </div>
                                    <div className="col l6">
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            className="loginSubmit"
                                            onClick={handleFormSubmit}
                                        >
                                            Actualizar
                                        </Button>
                                    </div>
                                </div>
                            </form>

                            <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
                                <DialogTitle className="titleNtfcAddEvent" id="form-dialog-title"
                                             onClose={handleCloseModal}>
                                    <MuiDialogTitle disableTypography>
                                        <span
                                            className="TitleModalContact">¡Gracias por utilizar tu Agenda Digital!</span>
                                        {/* <IconButton className="closeButtonX" aria-label="close" onClick={handleCloseModal}>
                                <CloseIcon fontSize="large" />
                            </IconButton> */}
                                    </MuiDialogTitle>
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        Es necesario que actualices tu contraseña, te recomendamos que sea única y fácil
                                        de recordar. Por seguridad no la compartas con nadie.
                                    </DialogContentText>
                                    {/* <div className="row">
                            <div className="col l12 s12 m12 s12 fieldModal">
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<GreenCheckbox checked={updatePassword} onChange={handleChangeUpdtPass}
                                                                name="updatePassword" />}
                                        label="No volver a mostrar este mensaje." style={{color: "#00AC46"}}
                                    />
                                </FormGroup>
                            </div>
                        </div> */}
                                </DialogContent>
                                <DialogActions>
                                    <Button className="MuiBadge-colorSecondary saveContactBtn"
                                            onClick={guardarUpdatePass} color="inherit">
                                        <DoneIcon className="iconBtnG" fontSize="small"/>
                                        Aceptar
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </Container>
                    :
                <div>
                    <p className="titleNtfcAddEvent" style={{fontSize: "17px"}}>AJUSTES</p>
                    <br/>
                    <div style={{
                        width: "125px", height: "125px", borderRadius: "100px",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: "#00AC46"
                    }}>
                        {
                            foto && typeof(foto) !== 'undefined' && foto.length>0 ?
                                <div className={classes.root}>
                                    <Avatar alt="fotoperfil" src={Collections.imageProfilePath + foto} />
                                </div>
                            /*<img width="85px" height="85px" src={Collections.imageProfilePath + foto}
                                style={{display: "block", margin: "auto", marginTop: "15px"}} alt="fotoperfil"/>*/
                                :
                            <PersonOutlineOutlinedIcon style={{
                                    color: "#00AC46", width: "85px", height: "85px",
                                    display: "block", margin: "auto", marginTop: "15px"
                                }}/>
                        }
                        <Button className="greenThermomix" onClick={handleAddPhoto}
                                style={{
                                    width: "150px", padding: "10px", color: "white", borderRadius: "10px",
                                    marginTop: "-90px", fontWeight: "600",
                                    marginLeft: "100px"
                                }}>
                            Agregar foto
                        </Button>
                    </div>
                    <br/>
                    <div className="row">
                        {
                            /*
                        <div className="col l12">
                            <FormGroup style={{float: "left"}}>
                                <FormControlLabel
                                    label="Activar modo nocturno"
                                    control={
                                        <BlueCheckbox checked={checkModoNctr}
                                                      onChange={activaModoNocturno} name="all"/>
                                    }
                                    style={{color: "#999999"}}
                                />
                            </FormGroup>
                        </div>
                            */
                        }
                        <div className="col l6" style={{marginTop: "25px"}}>
                            <Button className="col l6" onClick={editarPerfil} style={{
                                width: "175px", padding: "10px",
                                margin: "10px", color: "white", borderRadius: "10px", backgroundColor: "#F8A527"
                            }}>
                                {/*<PeopleOutlineIcon />&nbsp;*/}
                                Editar Perfil
                            </Button>
                            {/*
                            <Button className="col l6 greenThermomix" onClick={descargarDirectorio}
                                    style={{
                                        width: "220px",
                                        padding: "10px",
                                        margin: "10px",
                                        color: "white",
                                        borderRadius: "10px"
                                    }}>
                                {/ *<PeopleOutlineIcon />&nbsp;* /}
                                Descargar directorio
                            </Button>
                            */}
                        </div>
                    </div>
                    {
                        /*
                        <div className="row">
                            <div className="col l12">
                                <Button className="col l4" style={{
                                    width: "175px", padding: "10px",
                                    margin: "10px", color: "white", borderRadius: "10px", backgroundColor: "red"
                                }}>
                                    Borrar Eventos
                                </Button>
                                <Button className="col l4 greenThermomix" style={{
                                    width: "220px",
                                    padding: "10px", margin: "10px", color: "white", borderRadius: "10px"
                                }}>
                                    Agregar Eventos
                                </Button>
                                <Button className="col l4 " style={{
                                    width: "220px",
                                    padding: "10px",
                                    margin: "10px",
                                    color: "white",
                                    borderRadius: "10px",
                                    backgroundColor: "blue"
                                }}>
                                    Usuarios netsuite
                                </Button>
                            </div>
                        </div>
                        */
                    }
                    <Dialog open={openModalFoto} onClose={handleCloseModalFoto} aria-labelledby="form-dialog-title">
                        <DialogTitle className="titleNtfcAddEvent" id="form-dialog-title"
                                     onClose={handleCloseModalFoto}>
                            <MuiDialogTitle disableTypography style={img.length!==0 && croppedImage===null ? {color: "white"} : {}}>
                                <span
                                    className="TitleModalContact">Agregar foto</span>
                                <IconButton className="closeButtonX" aria-label="close"
                                            onClick={handleCloseModalFoto}>
                                    <CloseIcon fontSize="large" style={img.length!==0 && croppedImage===null ? {color: "white"} : {}}/>
                                </IconButton>
                            </MuiDialogTitle>
                        </DialogTitle>
                        <DialogContent>
                            {
                                img.length===0 ?
                                    <Dropzone
                                        onChangeStatus={handleChangeStatusFoto}
                                        accept="image/*"
                                        maxFiles={1}
                                        inputContent="Arrastra una imagen o haz click aquí para cargarla"
                                        inputWithFilesContent={files => `${1 - files.length} more`}
                                        submitButtonDisabled={files => files.length < 1}
                                    />
                                    :
                                    <div>
                                        {
                                            croppedImage!==null ?
                                                <div>
                                                    <div className={classes.root}>
                                                        <Avatar alt="fotoperfil" src={croppedImage} />
                                                    </div>
                                                </div>
                                                :
                                                <Cropper
                                                    image={img}
                                                    crop={crop}
                                                    cropShape={'round'}
                                                    rotation={rotation}
                                                    zoom={zoom}
                                                    aspect={2 / 2}
                                                    onCropChange={setCrop}
                                                    onRotationChange={setRotation}
                                                    onCropComplete={onCropComplete}
                                                    onZoomChange={setZoom}
                                                />
                                        }
                                    </div>
                            }

                            <DialogContentText style={img.length!==0 && croppedImage===null ? {color: "white"} : {}}>
                                Esta imagen es para uso único de la agenda.
                                Actualizar esta imagen NO cambia la fotografía en NetSuite ni en tu registro como Presentador(a).
                            </DialogContentText>

                            {/* <div className="row">
                            <div className="col l12 s12 m12 s12 fieldModal">
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<GreenCheckbox checked={updatePassword} onChange={handleChangeUpdtPass}
                                                                name="updatePassword" />}
                                        label="No volver a mostrar este mensaje." style={{color: "#00AC46"}}
                                    />
                                </FormGroup>
                            </div>
                        </div> */}
                        </DialogContent>
                        <DialogActions>
                            {
                                croppedImage===null ?
                                    <Button className="MuiBadge-colorSecondary saveContactBtn"
                                            onClick={uploadCropFoto} color="inherit">
                                        <DoneIcon className="iconBtnG" fontSize="small"/>
                                        Aceptar
                                    </Button>
                                    :
                                    <Button className="MuiBadge-colorSecondary saveContactBtn"
                                            onClick={guardarFoto} color="inherit">
                                        <DoneIcon className="iconBtnG" fontSize="small"/>
                                        Guardar
                                    </Button>
                            }
                        </DialogActions>
                    </Dialog>
                </div>
            }
            </>
            }
            </>
            }
        </div>
    );
}