import AuthService from "../Services/AuthService";
import { useHistory } from 'react-router-dom'
import React, {useState, useEffect} from 'react';
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {css} from "glamor";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                Este campo es requerido!
            </div>
        );
    }
};

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            <Link color="inherit" href="https://thermomix.mx/">
                Vorwerk  ©
            </Link>{' '}
            {new Date().getFullYear()}
            {' | Todos los Derechos Reservados '}
        </Typography>
    );
}

const Reclutamiento = (props) => {

    let history = useHistory();

    const [nameEmail] = useState(props.datos.replace('?', ''));
    const [nameWhoRecommends, setNameWhoRecommends] = useState("");
    const [mailWhoRecommends, setMailWhoRecommends] = useState("");
    const [nameRecommend, setNameRecommend] = useState("");
    const [fhoneRecommend, setFhoneRecommend] = useState("");
    const [mailRecommend, setMailRecommend] = useState("");
    const [notesRecommend, setNotesRecommend] = useState("");
    const [message, setMessage] = useState("");
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [mailUserCreatedEvent, setMailUserCreatedEvent] = useState(false);
    const [iduUserCreatedEvent, setIduUserCreatedEvent] = useState("");
    // Para los contactos externos agregar campo adicional llamado RECLUTAMIENTO (boolean) servira para modificar el tipo de usuario

    useEffect(() => {
        console.log(props);
        let arrDatos = atob(nameEmail).split("-");
        console.log(arrDatos[0]); // correo de la persona que crea el evento
        //console.log(window.location.pathname)
        setIduUserCreatedEvent(arrDatos[0]);
        setMailUserCreatedEvent(arrDatos[1]);
        setNameWhoRecommends(arrDatos[2]);
        setMailWhoRecommends(arrDatos[3]);
    },[]);

    const alerts = (msg, error) =>{
        toast(msg, {
            className: css({
                background: "#E7332A",
                color: "white"
            }),
            progressClassName: css({
                background: "#00AC46"
            })
        });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

        let continua = true;
        setMessage("");
        // Validar formulario
        if(nameRecommend.length===0){
            setMessage("Se requiere el nombre del recomendado.");
            continua = false;
        }
        if(fhoneRecommend.length===0){
            setMessage("Se requiere un telefono del recomendado.");
            continua = false;
        }
        if(mailRecommend.length===0){
            setMessage("Se requiere un correo del recomendado.");
            continua = false;
        }
        if(continua){
            console.log(

                "\n nameRecommend: " + nameRecommend +
                "\n mailRecommend: " + mailRecommend +
                "\n fhoneRecommend: " + fhoneRecommend +
                "\n nameWhoRecommends: " + nameWhoRecommends +
                "\n mailWhoRecommends: " + mailWhoRecommends +
                "\n iduUserCreatedEvent: " + iduUserCreatedEvent +
                "\n mailUserCreatedEvent: " + mailUserCreatedEvent +
                "\n notesRecommend: " + notesRecommend
            );
            // nombre, correo, telefono, nombreQuienRecomienda, correoQuienRecomienda, correoQuienCreo, notas
            AuthService.registerUserExtr(
                nameRecommend, mailRecommend, fhoneRecommend, nameWhoRecommends, mailWhoRecommends,
                iduUserCreatedEvent, mailUserCreatedEvent, notesRecommend
            )
            .then(
                (resp) => {
                    //console.log(resp.data.success);
                    if(resp.data.success){
                        setSaveSuccess(true);
                    } else if(resp.data.exist){
                        setSaveSuccess(false);
                        alerts("Usted ya tiene un registro como recomendado.", true)
                    } else if(!resp.data.existWhoRecommends){
                        setSaveSuccess(false);
                        alerts("Hay un problema con el correo de la persona que lo esta recomendando.", true)
                    } else {
                        setSaveSuccess(false);
                    }
                    window.localStorage.removeItem('titleToolbar');
                    history.push('/');
                },
                (error) => {
                    window.localStorage.removeItem('titleToolbar');
                    history.push('/');
                    setSaveSuccess(false);
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    alerts(resMessage, true);
                    setMessage(resMessage);
                }
            )
        }
    };

    return (

        <Container component="main" className="answeringMainOverride">
            <CssBaseline/>
            <div className="answeringContainer">
            {
                iduUserCreatedEvent.length>0 &&
                <div className="loginPaper">
                    <img style={{width: "165px"}} src="/assets/img/thermomix_header_2023.png" alt=""/>
                    <br/>
                    <br/>
                    {!saveSuccess ?
                    <Typography component="h1" variant="h5">
                        Recomienda a alguien
                    </Typography>
                    :<div></div>}
                    {
                        saveSuccess ?
                        <Container component="main" className="answeringMainOverride">
                        <CssBaseline/>
                            <div className="answeringPaper">
                                <div className="answeringTextBox">
                                    <Typography className="answeringAsistencia">
                                        ¡Gracias por recomendar a alguien para vivir una Demo Thermomix®!
                                    </Typography>
                                </div>
                            </div>
                            <div>
                                <Typography className="answeringAvisoPrivacidad">
                                <Link href="https://www.thermomix.mx/politica-privacidad-2/">Aviso de Privacidad</Link>
                                </Typography>
                            </div>
                            <div className="asweringSocialBox">
                                <a href="https://www.facebook.com/thermomixmexico"><img className="answeringIcons" width="50px" height="47px" src="/assets/img/icons/ico_facebook_green.png" alt="Footer"/></a>
                                <a href="https://www.youtube.com/thermomixmexicooficial"><img className="answeringIcons" width="50px" height="47px" src="/assets/img/icons/ico_youtube_green.png" alt="Footer"/></a>
                                <a href="https://cookidoo.mx/foundation/es-MX"><img className="answeringIcons" width="50px" height="47px" src="/assets/img/icons/ico_cookidoo_green.png" alt="Footer"/></a>
                                <a href="https://www.pinterest.com.mx/thermomixmexico/"><img className="answeringIcons" width="50px" height="47px" src="/assets/img/icons/ico_pinterest_green.png" alt="Footer"/></a>
                                <a href="https://www.instagram.com/thermomixmexico/"><img className="answeringIcons" width="50px" height="47px" src="/assets/img/icons/ico_instagram_green.png" alt="Footer"/></a>
                            </div>
                            <div className="answeringFooterContainer">
                                <img width="100%" src="/assets/img/answering_footer.png" alt="Footer"/>
                            </div>
                    </Container>
                            :
                            <form className="recomiendaForm">
                            <br/>
                            <Divider/>
                            <br/>
                            <TextField variant="outlined" margin="normal" required fullWidth autoFocus
                                       id="nameWhoRecommends" label="Nombre de quien recomienda:" disabled
                                       name="nameWhoRecommends" value={nameWhoRecommends} validations={[required]}
                            />
                            <TextField type="email" variant="outlined" margin="normal" required fullWidth
                                       name="mailwhorecommends" label="Correo de quien recomienda:" disabled
                                       id="mailwhorecommends" value={mailWhoRecommends} validations={[required]}
                            />
                            <br/><br/>
                            <Divider/>
                            <br/>
                            <TextField variant="outlined" margin="normal" required fullWidth validations={[required]}
                                       name="nameRecommend" label="Nombre del recomendado:" id="nameRecommend"
                                       defaultValue={nameRecommend} onChange={(e) => setNameRecommend(e.target.value)}
                            />
                            <TextField type="number" variant="outlined" margin="normal" required fullWidth
                                       validations={[required]}
                                       name="fhoneRecommend" label="Teléfono del recomendado:" id="fhoneRecommend"
                                       defaultValue={fhoneRecommend} onChange={(e) => setFhoneRecommend(e.target.value)}
                            />
                            <TextField type="email" variant="outlined" margin="normal" required fullWidth
                                       validations={[required]}
                                       name="mailRecommend" label="Correo del recomendado:" id="mailRecommend"
                                       defaultValue={mailRecommend} onChange={(e) => setMailRecommend(e.target.value)}
                            />
                            <TextField variant="outlined" margin="normal" fullWidth multiline rows={5}
                                       name="notesRecommend" label="Notas del recomendado:" id="notesRecommend"
                                       defaultValue={notesRecommend} onChange={(e) => setNotesRecommend(e.target.value)}
                            />

                            <br/><br/>
                            <Divider/>
                            <br/>

                            <Button type="submit" fullWidth variant="contained" color="primary" className="loginSubmit"
                                    onClick={handleFormSubmit}>
                                Guardar
                            </Button>

                            {message && (
                                <div className="form-group">
                                    <div className="alert alert-danger" role="alert"
                                         style={{color: "red", textAlign: "right", marginTop: "15px"}}>
                                        * {message}
                                    </div>
                                </div>
                            )}
                        </form>
                    }
                </div>
            }
            {!saveSuccess ?
            <Box mt={8}>
            <Copyright/>
            </Box>
            : <div></div>}
        </div>
        </Container>
    );
};

export default Reclutamiento;