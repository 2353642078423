//import {withRouter} from 'react-router-dom';
import { useHistory } from 'react-router-dom'
import AuthService from "../Services/AuthService";
import React, {useState} from 'react';
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../toastify-custom.css';
import {css} from "glamor";
//import {withLocalize, Translate} from "react-localize-redux";
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
//import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
//import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Collections from "../Utils/collections";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                Este campo es requerido!
            </div>
        );
    }
};

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center" className="HomeFooter">
            <Link color="inherit" href="https://thermomix.mx/">
                Vorwerk  © 
            </Link>{' '}
            {new Date().getFullYear()}
            {' | Todos los Derechos Reservados.'}
        </Typography>
    );


}

function FooterVersion() {
    if (process.env.REACT_APP_DEVENV){
        return (
            <Typography variant="body2" color="textSecondary" align="center" className="HomeFooter">
                {'Development Server - App Ver. '+Collections.CurrentAppVersion+' Build '+Collections.CurrentBuildVersion}
            </Typography>
        );
    }
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {' '}
        </Typography>
    );
}


const Login = (props) => {

    let history = useHistory();


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [seePassword, setSeePassword] = useState(false);
    const [message, setMessage] = useState("");
    const [LogBtnState, setLogBtnState] = useState(false);

    const alerts = (msg) =>{
        toast(msg, {
            className: css({
                background: "#E7332A",
                color: "white"
            }),
            progressClassName: css({
                background: "#00AC46 "
            })
        });
    };

    const onChangeEmail = (e) => {
        const email = e.target.value.toLowerCase().replace(/ /g, '');
        console.log(email);
        setEmail(email);
    };

    const onChangePassword = (e) => {
        const password = e.target.value.replace(/ /g, '');
        setPassword(password);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setLogBtnState(true);

        setMessage("");

        //this.Auth.login(this.state.email, this.state.password)
        AuthService.login(email, password).then( ()=>{
                history.replace("/");
                window.location.reload();
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                alerts(resMessage);
                setMessage(resMessage);
                setLogBtnState(false);
            }
        );
    };

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleFormSubmit(e);
        }
    }


    const handleResetPass = (path, titleToolbar) => {
        window.localStorage.setItem('titleToolbar', titleToolbar)
        history.push(path);
    }

    /*const goTo = (path) => {
        props.history.replace(path);
    }*/


    return (
        <Container component="main" className="loginMain" maxWidth="xs">
            <CssBaseline/>
            
            <div className="loginPaper">

                    <img style={{width: "100%"}} src="/assets/img/appgenda-logo_2023.png" alt=""/>
                <br/>
                <br/>
                <Typography component="h1" variant="h5">
                    Iniciar Sesión
                </Typography>
                <form className="loginForm">
                    <div className="row">
                        <div className="col l11 s11">
                            <Input
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Correo electrónico"
                                name="email"
                                autoComplete="email"
                                placeholder="Email"
                                autoFocus
                                defaultValue={email}
                                onChange={onChangeEmail}
                                validations={[required]}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col l11 s11">
                            <Input
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                placeholder="Contraseña"
                                name="password"
                                label="Contraseña"
                                type={seePassword?"text":"password"}
                                id="password"
                                autoComplete="current-password"
                                defaultValue={password}
                                onChange={onChangePassword}
                                onKeyDown={_handleKeyDown}
                                validations={[required]}
                                endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="ver contraseña"
                                        onClick={()=>setSeePassword(!seePassword)}
                                      >
                                        {seePassword ? <VisibilityOff /> : <VisibilityIcon/>}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                            />
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col l11 s12">
                            {
                                /*
                                <FormControlLabel
                                control={<Checkbox value="remember" color="default"/>}
                                label="Recuérdame"
                                />
                                */
                            }
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className="loginSubmit"
                                onClick={handleFormSubmit}
                                disabled = {LogBtnState}
                            >
                                Iniciar sesión
                            </Button>

                            {message && (
                                <div className="form-group">
                                    <div className="alert alert-danger" role="alert"
                                         style={{color: "red", textAlign: "right", marginTop: "15px"}}>
                                        * {message}
                                    </div>
                                </div>
                            )}

                            <Grid container className="options-loginForm">
                        <Grid item xs>
                            <Link href="/resetPass" variant="body2" className="HomeFooter" onClick={()=>handleResetPass('/resetPass','resetPass')}>
                                {/*<Link href="/" variant="body2">*/}
                                ¿Olvidaste tu contraseña?
                            </Link>
                        </Grid>
                        {/* <Grid item>
                            <Link href="/register" variant="body2">
                                {"¿No tienes una cuenta? Regístrate"}
                            </Link>
                        </Grid> */}
                    </Grid>
                        </div>
                    </div>
                </form>
            </div>
            <Box mt={8}>
                <Copyright/>
            </Box>
            <Box mt={8}>
                <FooterVersion/>
            </Box>
        </Container>

    );
};

export default Login;