import React, {useState, useEffect} from 'react';
import AuthService from "../Services/AuthService";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Estadisticas from "./VerObjetivos"

export default function EstructuraObjetivos(props) {

    const [currentUser] = useState(props.user);
    const [usersIntrns] = useState(props.userIntr);
    const [calendarioVorwerk] = useState(props.calendarioVorwerk);
    const [CurrentFecha] = useState(props.CurrentFecha);
    const [CurrentMesNum] = useState(props.CurrentMesNum);
    const [CurrentAnioNum] = useState(props.CurrentAnioNum);
    const [userEstr, setUserEstr] = useState([]);
    const [userSlcc, setUserSlcc] = useState({});
    const [verLista, setVerLista] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    let arrUsrs = [];

    /*
    useEffect(() => {
        //console.log(userEstr)
    },[userEstr])
    */
    useEffect(()=>{
        //Vamos a revisar si es admin u otro rol
        if(currentUser.rol[0].value===0){
            let arrEstr = usersIntrns.filter(uI=> uI.rol[0].value!==0);
            if(arrEstr.length>0){
                arrEstr.forEach((uE,i)=>{
                    arrUsrs.push({index: i, idu: uE.idu, estructura: uE.estructura,
                      nombre: uE.nombre +(uE.apellido && uE.apellido.length>0? ' ' + uE.apellido:''),
                      idRol: uE.rol[0].value, rol: uE.rol[0].text, tieneObjetivos: false, check: false, objetivos: {}})
                    //consultaGoals(uE, (i===arrEstr.length-1));
                });
                //arrUsrs.sort((a,b)=> b.idRol-a.idRol);
                consultaGoals(arrUsrs)
            }
        } else {
            let arrEstr = usersIntrns.filter(uI=> uI.idu===currentUser.idu ||
                (uI.estructura.filter(e=> e.idu === currentUser.idu).length>0)
            );
            if(arrEstr.length>0){
                arrEstr.forEach((uE,i)=>{
                    arrUsrs.push({index: i, idu: uE.idu, estructura: uE.estructura,
                      nombre: uE.nombre +(uE.apellido && uE.apellido.length>0? ' ' + uE.apellido:''),
                      idRol: uE.rol[0].value, rol: uE.rol[0].text, tieneObjetivos: false, check: false, objetivos: {}})
                    //consultaGoals(uE, (i===arrEstr.length-1));
                });
                //arrUsrs.sort((a,b)=> b.idRol-a.idRol);
                consultaGoals(arrUsrs)
            }
        }

    }, []);

    const consultaGoals = (users) => {
        AuthService.consult(`{goalsMonthYear(month: ${CurrentMesNum} year: ${CurrentAnioNum} ){ 
            id iduUser prospectos experiencias ventas budgets month year }}`)
        .then(result=>{
            let goals = result.data.data.goalsMonthYear;
            if( goals !== null && goals.length > 0 ){
                //console.log(goals)
                goals.forEach(uG=>{
                  let userF = users.filter(uF => uF.idu===uG.iduUser)
                  if(userF.length>0){
                    let indice = userF[0].index
                    //console.log(indice)
                    users[indice].check = true
                    users[indice].tieneObjetivos = true
                    users[indice].objetivos = uG
                  }
                })
                //console.log(users)
                setUserEstr(users);   
            }
        })
        .catch(error=>{console.log(error);});
    };

    const handleVerObjetivos = (user) => {
      let u = usersIntrns.filter(uf => uf.idu ===user.idu)
      if(u.length>0){
        setUserSlcc(u[0]);
        setOpenModal(true);
      }
    }


    const handleCloseModal = (ev) =>{
        setUserSlcc({});
        setOpenModal(false);
    }

    function table(rol, users){

        return users.map((usr, index) => {
            // Para cuando es presentadora o el admin y user sales director
            if( rol===1 || (currentUser.rol[0].value === 0 && usr.idRol===9) ){
                return <div key={index}><div className={"row"} style={{width: "97%"}} key={index}>
                    <div className={"col l1 s1 labelInterno"+usr.idRol} style={{width: "10px", height: "20px"}}></div>
                    <div className="col l1 s1" >{usr.idu}</div>
                    <div className="col l6 s9" align="left">{usr.nombre}</div>
                    <div className="col l2 s12" align="left">{usr.rol}</div>
                    <div className="col l2 s12" align="center">
                        <div style={usr.tieneObjetivos ?
                            { backgroundColor: "#00AC46", borderRadius: "10px", textAlign: "center", color: "#FFF"}:
                            { backgroundColor: "#99A6AB", borderRadius: "10px", textAlign: "center", color: "#FFF"}
                        }>
                            <b>{usr.tieneObjetivos? 'DATOS INGRESADOS':'DATOS SIN INGRESAR'}</b>
                        </div>
                        {
                          usr.idRol!==9 &&
                          <Button className="buttonVerObjetivos" style={{color: "white", fontWeight: "600", marginTop: "10px"}}
                              onClick={()=>handleVerObjetivos(usr)}>
                              Ver datos
                          </Button>
                        }
                    </div>
                  </div>
                  <hr />
                </div>
            } else
            if( (currentUser.rol[0].value === 0 || currentUser.idu===usr.idu || currentUser.rol[0].value !== rol) &&
                    (usr.idRol===9 || usr.idRol===8 || rol===5 || rol===3) ){
                let userN = [];
                if (usr.idRol===9){
                    // Si esta en el rol de sales director
                    userN = userEstr.filter(uI=> uI.idRol===8
                        && (uI.estructura.filter(e=> e.idu === usr.idu).length>0)
                    );
                } else if (usr.idRol===8){
                    // Si esta en el rol de Reg Mngr traer los gerentes
                    userN = userEstr.filter(uI=> uI.idRol===5
                        && (uI.estructura.filter(e=> e.idu === usr.idu).length>0)
                    );
                } else if (rol===5){  // rol de gerente
                    userN = userEstr.filter(uI=> uI.idRol===3
                        && (uI.estructura.filter(e=> e.idu === usr.idu).length>0)
                    );
                } else if (rol===3){ // rol sea 3 de lider
                    userN = userEstr.filter(uI=> uI.idRol===1
                        && (uI.estructura.filter(e=> e.idu === usr.idu).length>0)
                    );
                }
                return <Accordion className="row" key={index}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className={"row"} style={{width: "100%"}}>
                            <div className={"col l1 s1 labelInterno"+usr.idRol} style={{width: "10px", height: "20px"}}></div>
                            <div className="col l1 s1" >{usr.idu}</div>
                            <div className="col l6 s12" align="left">{usr.nombre}</div>
                            <div className="col l2 s12" align="left">{usr.rol}</div>
                            <div className="col l2 s12" align="center">
                              <div style={usr.tieneObjetivos ?
                                { backgroundColor: "#00AC46", borderRadius: "10px", textAlign: "center", color: "#FFF"}:
                                { backgroundColor: "#99A6AB", borderRadius: "10px", textAlign: "center", color: "#FFF"}
                              }>
                                  <b>{usr.tieneObjetivos? 'DATOS INGRESADOS':'DATOS SIN INGRESAR'}</b>
                              </div>
                              {
                                  !(usr.idRol===9 || usr.idRol===8) &&
                                  <Button className="buttonVerObjetivos" style={{color: "white", fontWeight: "600", marginTop: "10px"}}
                                      onClick={()=>handleVerObjetivos(usr)}>
                                      Ver datos
                                  </Button>
                              }
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="row" style={usr.idRol=== 8 ? {width: "97%", marginLeft: "5px"}: rol===5 ?
                            {width: "97%", marginLeft: "7px"}: { width: "97%", marginLeft: "9px"}}>
                            {usr.idRol===9 && table(8, userN)}
                            {usr.idRol===8 && table(5, userN)}
                            {rol===5 && table(3, userN)}
                            {rol===3 && table(1, userN)}
                        </div>
                    </AccordionDetails>
                </Accordion>
            } else {
                return null
            }
        })
    }

    const handleVerLista = () => {
        setVerLista(true)
    }

    return (
        <div>
            <div className="row">
                <div className="col l6 m6 s12">
                    <h4 style={{fontSize: "25px"}}>Estatus del equipo</h4>
                </div>
                {
                    !verLista &&
                    <div className="col l6 s12"  style={{marginTop: "25px", textAlign: "center"}}>
                        <Button className="MuiBadge-colorSecondary" onClick={handleVerLista}>Consultar</Button>
                    </div>
                }
            </div>
            { verLista &&
                <div>
                    <div className="row desktopshow" style={{width: "97%"}}>
                        <div className="col l1" style={{width:"10px"}}></div>
                        <div className="col l1"><b>IDU</b></div>
                        <div className="col l6" align="left"><b>NOMBRE</b></div>
                        <div className="col l2" align="left"><b>ROL</b></div>
                        <div className="col l2" align="left"><b>ESTATUS</b></div>
                    </div>

                    <div>
                        {
                            table(currentUser.rol[0].value, userEstr)
                        }
                    </div>
                    {/* Mostrar dialogo de perfil para este usuario */}
                    <Dialog open = {openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
                        <DialogTitle className="titleNtfcAddEvent" id="form-dialog-title" onClose={handleCloseModal}>
                        {handleCloseModal ? (
                                <IconButton style={{padding: "0"}} className="closeButtonX" aria-label="close" onClick={handleCloseModal}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            ) : null}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                            </DialogContentText>
                            {/* Mostrar el perfil */}
                            <Estadisticas user={userSlcc} userIntr={usersIntrns} calendarioVorwerk={calendarioVorwerk} CurrentFecha={CurrentFecha}/>
                        </DialogContent>
                    </Dialog>
                </div>
            }

        </div>
    );
}