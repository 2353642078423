import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
const GreenCheckbox = withStyles({
    root: {
        color: "#00AC46",
        '&$checked': {
            color: "#00AC46",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    }
}));
/**
 * Componente de asistencia para pasar lista de un evento
 * @param {*} props datos del evento
 * @returns Contenedor de lista de invitados con checkbox para marcar los que asistieron
 */
export default function Asistencia(props) {

    const [event] = useState(props.event);
    const [yaEnvioCorreo] = useState(props.yaEnvioCorreo)
    const [validateMonday] = useState(props.validateMonday)
    const classes = useStyles();
    const [left, setLeft] = React.useState([]);
    const [checkSelectAll, setCheckSelectAll] = React.useState(false);

    useEffect(() => {
        let usrsExtr = [];
        console.log(event.listaInvitados);

        event.listaInvitados.forEach((invt,key)=>{
            if(invt.idu.length===24 && invt.idu.indexOf("@")===-1){
                Object.assign(invt, {"checked": invt.sendMail});
                usrsExtr.push(invt);
            }
        });
        setLeft(usrsExtr)
    }, []);
    /**
     * Función para checar invitado por invitado y marcar su asistencia
     * @param {*} ev datos del evento que esta cambiando
     * @param {*} index indice en el listado de invitados para ver cual cambiar su valor
     */
    const handleToggle = (ev, index) => {
        //console.log(index + " Checar uno... " + ev.target.checked)
        if(yaEnvioCorreo){ return }
        if(!validateMonday){ return }
        setCheckSelectAll(false);
        let newUsrs = [];
        left.forEach((val,i)=>{
            if(index===i){
                val.checked = ev.target.checked
            }
            newUsrs.push(val)
        });
        setLeft(newUsrs);
        window.localStorage.setItem("enviarAsistencia", JSON.stringify(newUsrs));
    };
    /**
     * Función para seleccionar o no todos los invitados
     * @param {*} ev 
     */
    const handleChangeSelectAll = (ev) => {
        //console.log("Seleciconar todos... " + ev.target.checked)
        if(!validateMonday){return}
        setCheckSelectAll(ev.target.checked);
        left.map(val=>{
            return val.checked = ev.target.checked
        });
        window.localStorage.setItem("enviarAsistencia", JSON.stringify(left));
    };
    /**
     * Función para pintar listado de invitados
     * @param {*} items datos del invitado
     * @returns Una lista de invitados personalizada
     */
    const customList = (items) => (
        <div>
            {
                items.length>0 ?
                    <>
                    <div style={{marginTop: "10px", marginLeft: "26px"}}>
                        {!yaEnvioCorreo && validateMonday &&
                            <FormGroup row>
                                <FormControlLabel
                                    control={<GreenCheckbox checked={checkSelectAll}
                                                            onChange={handleChangeSelectAll} name="seRepite"/>}
                                    label={"Todos"} className="inviteAllProfileCheck"
                                />
                            </FormGroup>
                        }
                    </div>
                    {items.map((value, key) => {
                        return (
                            value.checked ?
                             (
                                <div key={key} style={{marginTop: "10px", marginLeft: "26px"}}>
                                    Correo enviado a: <b>{value.nombre}</b>
                                </div>
                             )
                            :
                            (
                                <div key={key} style={{marginTop: "10px", marginLeft: "26px"}}>
                                    <FormGroup row >
                                        <FormControlLabel
                                            control={
                                                <GreenCheckbox checked={value.checked}
                                                               onChange={(ev)=>handleToggle(ev, key)} name={"user"+key} />}
                                            label={`${value.nombre}`} className="inviteAllProfileCheck"
                                        />
                                    </FormGroup>
                                </div>
                            )
                    );
                    })}
                    </>
                    :
                    <>
                    <div style={{marginTop: "10px", textAlign: "center"}}>
                        <b>No hay usuarios externos en este evento.</b>
                    </div>
                    </>
            }
        </div>
    );

    return (
        <Grid container alignItems="center" className={classes.root}>
            <Grid item alignContent="center">{customList(left)}</Grid>
        </Grid>
    );
}