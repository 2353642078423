import React, {useState} from 'react';
import { useHistory } from 'react-router-dom'
// eslint-disable-next-line no-unused-vars
import AuthService from "../Services/AuthService";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {css} from "glamor";
import axios from 'axios'
import Collections from "../Utils/collections";
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Lottie from 'react-lottie-player'
import loading from "../Lottie/33637-ondas-sonoras.json"
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                Este campo es requerido!
            </div>
        );
    }
};
const ResetPass = (props) => {

    let history = useHistory();
    const [idUser] = useState(props.datos.replace('?', ''));
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [completed, setCompleted] = useState(true);
    const [seePassword, setSeePassword] = useState(false)
    const [seePasswordConfirm, setSeePasswordConfirm] = useState(false)

    const alerts = (msg, error) => {
        toast(msg, {
            className: css({
                background: error?"#E7332A": "#00AC46",
                color: "white"
            }),
            progressClassName: css({
                background: error? "#00AC46": "white"
            })
        })
    };

    const handleChange = (e) => {
        switch(e.target.name) {
            case "password":
                setPassword(e.target.value);
                break
            case "passwordConfirm":
                setPasswordConfirm(e.target.value);
                break
            default:
                setPassword("")
                break
        }
        
    }

    const handleFormSubmit = (e) =>
    {
        e.preventDefault();
        setCompleted(false);
        let continua = true;
        let mensaje= "";

        if( /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*([^a-zA-Z\d\s]))(?=.*?[^\w\s]).{8,}$/.test(password) ){
            if(password !== passwordConfirm ){
                continua = false;
                mensaje="Tu contraseña no fue confirmada correctamente.";
            }
        } else {
            continua = false;
            mensaje = "Debes ingresar una contraseña con las condiciones mencionadas.";
        }

        if(!password || password===0){
            continua = false;
            mensaje="Debes ingresar una contraseña.";
        }

        //console.log(currentUser)
        //console.log(mensaje)
        if(continua){
            let _id = idUser.replace("id=", "")
            console.log(_id)
            axios
                .post(Collections.ipServer + "/mails/changePasswordId", {
                    id: _id,
                    password: password
                })
                .then(function (response) {
                    alerts("Se han modificado tus datos correctamente.", false);
                    setTimeout(()=>{
                        //AuthService.logout();
                        window.localStorage.setItem('titleToolbar', "");
                        history.push("/");
                        window.location.reload();
                        setCompleted(true);
                    }, 2000)
                })
                .catch(function (error) {
                    console.log(error);
                    alerts("Usuario no existente", true);
                    setCompleted(true);
                });
        } else {
            alerts(mensaje, true);
            setCompleted(true);
        }
    };

    const handleFormCancel = () => {
        window.localStorage.setItem('titleToolbar', "");
        history.push("/");
        window.location.reload();
    };

    return (
        <Container component="main" className="loginMain" maxWidth="xs">
            <CssBaseline/>
            <div className="loginPaper">

                <img src="/assets/img/thermomix_header.png" alt=""/>
                <br/>
                <br/>
                <div
                    style={{
                        background: "lightgrey",
                        padding: "20px",
                        borderRadius: "15px"
                    }}
                >
                    {
                        // eslint-disable-next-line
                        <Typography >
                            💡 Tips para una contraseña segura:
                        </Typography>
                    }
                    <ul>
                        <li>- La contraseña debe tener al menos 8 caracteres y hasta 15 caracteres.</li>
                        <li>- La contraseña debe contener al menos una letra mayúscula</li>
                        <li>- Debe contener 2 números</li>
                        <li>- Debe contener alguno de los siguientes caracteres especiales: @ (arroba) .
                            (punto) - (guión) * (asterisco)
                        </li>
                    </ul>
                </div>
                {
                    !completed ?
                        <Lottie
                            loop
                            animationData={loading}
                            play
                            style={{width: 450, height: 450, display: "block", margin: "auto"}}
                        />
                        :
                        <form className="col s12 inputs-travel">
                            <div className="row">
                                <div className=" col s12">
                                    
                                    <Input
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        placeholder="Contraseña"
                                        name="password"
                                        label="Contraseña"
                                        type={seePassword?"text":"password"}
                                        id="password"
                                        autoComplete="current-password"
                                        defaultValue={password}
                                        onChange={handleChange}
                                        validations={[required]}
                                        endAdornment={
                                            <InputAdornment position="end">
                                            <IconButton
                                                aria-label="ver contraseña"
                                                onClick={()=>setSeePassword(!seePassword)}
                                            >
                                                {seePassword ? <VisibilityOff /> : <VisibilityIcon/>}
                                            </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s12">
                                    
                                    <Input
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        placeholder="Confirmar contraseña"
                                        name="passwordConfirm"
                                        label="Confirmar contraseña"
                                        type={seePasswordConfirm?"text":"password"}
                                        id="passwordConfirm"
                                        autoComplete="current-password"
                                        defaultValue={passwordConfirm}
                                        onChange={handleChange}
                                        onKeyDown={handleChange}
                                        validations={[required]}
                                        endAdornment={
                                            <InputAdornment position="end">
                                            <IconButton
                                                aria-label="ver contraseña"
                                                onClick={()=>setSeePasswordConfirm(!seePasswordConfirm)}
                                            >
                                                {seePasswordConfirm ? <VisibilityOff /> : <VisibilityIcon/>}
                                            </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row content-btn-login">
                                <button onClick={handleFormCancel} style={{float: "left", backgroundColor: "#B42C2E", color:"#FFF"}}
                                        className="btn-large btn-travel">
                                    Cancelar
                                </button>
                                <button onClick={handleFormSubmit} style={{marginLeft: "10px", float: "right"}}
                                        className="MuiBadge-colorSecondary btn-large btn-travel">
                                    Actualizar
                                </button>
                            </div>
                        </form>
                }
            </div>
        </Container>
    );

};
export default ResetPass;