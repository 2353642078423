import React, {useState, useEffect} from 'react';
import AuthService from "../Services/AuthService";
//import { useHistory } from 'react-router-dom'
//import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
//import CircularProgress from '@material-ui/core/CircularProgress';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Lottie from 'react-lottie-player'
import loading from "../Lottie/33637-ondas-sonoras.json"
//import utils from "../Utils/collections";

export default function VerObjetivos(props) {

    //let history = useHistory();

    const [currentUser] = useState(props.user);
    const [usersIntrns] = useState(props.userIntr);
    const [calendarioVorwerk] = useState(props.calendarioVorwerk);
    const [myGoals, setMyGoals] = useState([]);
    const [sumaGoals, setSumaGoals] = useState();
    const [prospectos, setProspectos] = useState([]);
    const [sumProspectos, setSumProspectos] = useState();
    const [experiencias, setExperiencias] = useState([]);
    const [sumExperiencias, setSumExperiencias] = useState();
    const [ventas, setVentas] = useState([]);
    const [sumVentas, setSumVentas] = useState();
    //const [ventasNewApi, setVentasNewApi] = useState([]);
    //const [cancelacionesNewApi, setCancelacionesNewApi] = useState();
    //const [ventasGrafica, setVentasGrafica] = useState([]);
    const [load, setLoad] = useState(false);
    const arrVentas = [];
    const arrCancelaciones = [];
    // eslint-disable-next-line
    const [month, setMonth] = useState(0);
    // eslint-disable-next-line
    const [year, setYear] = useState(0);
    const [CurrentFecha] = useState(props.CurrentFecha);
    let smtrGoal = {
        sumProspectos: 0,
        sumExperiencias: 0,
        sumVentas: 0
    };
    let smtrExperiencias = {
        experienciasProgramadas: 0,
        experienciasRealizadas: 0
    };
    let smtrPrsp = 0;
    //let meses = [1,2,3,4,5,6,7,8,9,10,11,12];
    console.log('Inicia consulta desde VerObjetivos.js');
    useEffect(()=>{
        AuthService.getIdVentas().then(json=> {
            if(json){
                let datosVentas = json.data.results;
                //console.log(datosVentas);
                if(datosVentas){
                    datosVentas.forEach((dato, index)=>{
                        //console.log(dato.basic.customFieldList.customField);
                        
                        //Validar que solo sea de la fecha iicio de mes vorwerk en adelante
                        // Esta consulta debe ser en base al mes Vorwerk
                        //To Do Ver2: Fecha Changed for useState value.
                        //let fecha = new Date()
                        //let fechaVorwerkFin = utils.calendarioVorwerk[CurrentFecha.getMonth()][CurrentFecha.getMonth()][1].fin;
                        //To Do Ver6: Check if fechaVorwerkFin must be validated in the if statement
                        //let fechaVorwerkFin = calendarioVorwerk[CurrentFecha.getMonth()].fechaFin;
                        //console.log('Ver6 fechaVorwerkFin: '+fechaVorwerkFin);
                        //let fechaVorwerkInicio = utils.calendarioVorwerk[CurrentFecha.getMonth()][CurrentFecha.getMonth()][0].inicio;
                        let fechaVorwerkInicio = new Date(calendarioVorwerk[CurrentFecha.getMonth()].fechaInicio);
                        //console.log('Ver6 fechaVorwerkInicio: '+fechaVorwerkInicio);

                        //To Do Ver6: Check if this date modifier is needed or if the date is compared correctly with the sales date.
                        /* Temporal disble?
                        if(calendarioVorwerk && calendarioVorwerk.length>0){
                            //console.log("Hay calendario vorwerk")
                            calendarioVorwerk.forEach(mes=>{
                                //console.log("mes", mes)
                                if( CurrentFecha.getTime() > new Date(mes.fechaInicio).getTime() && CurrentFecha.getTime() < new Date(mes.fechaFin).getTime() ) {
                                    fechaVorwerkInicio = new Date(mes.fechaInicio)
                                }
                            })
                        } else {
                            console.log("ERROR: Sin calendario vorwerk")
                        }
                        */
                        
                        
                        //console.log("fecha vorwerk para estadistica: ", fechaVorwerkInicio)
                        // Fecha de transaccion de venta en el servicio
                        let fechaVenta = new Date(dato.basic.tranDate[0].searchValue)
                        //To Do Ver6: IMPORTANT Disable this console log.
                        //console.log('Fecha de venta para verificar: '+fechaVenta);
                        if(fechaVenta.getTime()>= fechaVorwerkInicio.getTime() ){
                            console.log("Tenemos ventas en este mes...")
                            if(dato.basic.customFieldList.customField.length>0){
                                dato.basic.customFieldList.customField.forEach((ventas, j)=>{
                                    if(ventas.$attributes.internalId==="883"){
                                        //console.log(dato.salesRepJoin.entityId[0].searchValue + " - venta");
                                        arrVentas.push({"idu": dato.salesRepJoin.entityId[0].searchValue, "venta": 1});
                                    }
                                    if(ventas.$attributes.internalId==="3137"){
                                        //console.log(dato.salesRepJoin.entityId[0].searchValue + " - cancelación")
                                        arrCancelaciones.push({"idu": dato.salesRepJoin.entityId[0].searchValue, "cancelacion": 1});
                                    }
                                    //console.log(ventas.$attributes.internalId)
                                });
                            }
                        } else {
                            console.log('NO HAY VENTAS REGISTRADAS PARA ESTE USUARIO.');
                        }
                        
                    });
                }
                /*
                console.log("VENTAS:");
                console.log(arrVentas.sort((a,b)=>a.idu-b.idu));

                console.log("CANCELACIONES:");
                console.log(arrCancelaciones);
                */
                //setVentasNewApi(arrVentas.sort((a,b)=>a.idu-b.idu));
                //setCancelacionesNewApi(arrCancelaciones);

                consultaGoals(currentUser, true);
                consultaEstadisticaPresentador();
                if(currentUser.rol[0].value===3 || currentUser.rol[0].value===5){
                    consultaEstadisticaLyG();
                    if(currentUser.rol[0].value===3){  // Solo lideres para gráfica
                        let gql = `{salesIdu(iduEmpleado: "${currentUser.idu}"){
                            id
                            iduEmpleado
                            email
                            mes
                            year
                            mesAnio
                            ventas_sin_tm_ganada
                            cancelaciones
                            created_date
                          }}`;
                        //console.log(gql);
                        AuthService.consult(gql)
                            .then(resp=>{
                                //console.log(resp.data.data.salesIdu)
                                //setVentasGrafica(resp.data.data.salesIdu)
                            })
                    }
                }
                //console.log(arrVentas.filter(a=>a.idu==="9453"));
            }
        });
    }, []);

    useEffect(() => {
        //console.log("entroReagendar: "+entroReagendar)
    },[sumProspectos]);

    useEffect(() => {
        //console.log("entroReagendar: "+entroReagendar)
    },[sumaGoals]);

    useEffect(() => {
        //console.log("entroReagendar: "+entroReagendar)
    },[sumExperiencias]);

    const consultaGoals = (user, presentador) => {
        //console.log(user)
        setLoad(false);
        let fecha = new Date(); 
        //Si es lider
        setMonth(fecha.getMonth()+1);
        setYear(fecha.getFullYear());

        // Esta consulta debe ser en base al mes Vorwerk
        //let fechaVorwerk = calendarioVorwerk[fecha.getMonth()].fechaInicio;
        //To Do Ver6: Check if this disabled let fechaVorwerkInicio is ok.
        //let fechaVorwerkInicio = calendarioVorwerk[fecha.getMonth()].fechaFin;
        let mesConsultar = (fecha.getMonth()+1)
        let yearConsult = fecha.getFullYear()
        //To Do Ver6: Remove this json to console log.
        
        function prettyJSONv(obj) {
            let stringed = JSON.stringify(obj, null, 2);
            return stringed
        }
        let CalendarToJson = prettyJSONv(calendarioVorwerk);
        console.log('Calendario Transmitido al JS: '+CalendarToJson);


        if(calendarioVorwerk && calendarioVorwerk.length>0){
            calendarioVorwerk.forEach(mes=>{
                //console.log("mes", mes)
                let fechaVorwerkInicio = new Date(mes.fechaInicio)
                console.log('Fecha Inicio para consulta de equipo: '+fechaVorwerkInicio);
                let fechaVorwerk = new Date(mes.fechaFin) //
                if( fecha.getTime() > fechaVorwerkInicio.getTime() && fecha.getTime() < fechaVorwerk.getTime() ) {
                    let newFechaSave = new Date(fechaVorwerkInicio.getTime() + (10*24*60*60*1000))
                    mesConsultar = newFechaSave.getMonth()+1
                }
            })
        } else {
            //To Do Ver6: Add a query to the DBLog for this error cases.
            console.log('ERROR: No se recibió calendario para consulta de equipo');
            //To Do Ver6: Check if this disabled section is ok.
            /*
            if( fecha.getTime() > fechaVorwerkInicio.getTime() && fecha.getTime() < fechaVorwerk.getTime() ) {
                console.log("1... esta dentro de la fecha vorwerk ")
                let newFechaSave = new Date(fechaVorwerkInicio.getTime() + (10*24*60*60*1000))
                mesConsultar = newFechaSave.getMonth()+1
                yearConsult = newFechaSave.getFullYear()
            } else if( fecha.getTime() < fechaVorwerkInicio.getTime()) {
                //console.log("2... la fecha de hoy es menor a la fecha inicio de vorwerk")
                // si la fecha de hoy es menor a la fecha inicio de vorwerk
                mesConsultar = fecha.getMonth()+1
                yearConsult = fecha.getFullYear()
            } else {
                //To Do Ver6: Send an error log to the backend.
                console.log('ERROR: La fecha Vorwerk No Coincide.');
            }
            */
        }


        AuthService.consult(`{
              goalsIduMonthYear(iduUser:"${user.idu}" month: ${mesConsultar}
                year: ${yearConsult} ){ id iduUser prospectos experiencias ventas budgets month year }
            }`
        )
            .then(result=>{
                let goals = result.data.data.goalsIduMonthYear;
                setLoad(true);
                //console.log(goals)
                if( goals !== null && goals.length > 0 ){
                    //console.log(goals);
                    if(currentUser.idu === user.idu && presentador) {
                        //console.log(goals);
                        setMyGoals(goals);
                    } else {
                        smtrGoal.sumProspectos = smtrGoal.sumProspectos + goals[0].prospectos;
                        smtrGoal.sumExperiencias = smtrGoal.sumExperiencias + goals[0].experiencias;
                        smtrGoal.sumVentas = smtrGoal.sumVentas + goals[0].ventas;
                        let objSumGoals = {
                            sumProspectos: smtrGoal.sumProspectos,
                            sumExperiencias: smtrGoal.sumExperiencias,
                            sumVentas: smtrGoal.sumVentas
                        };
                        //console.log(user.idu)
                        //console.log(objSumGoals)
                        setSumaGoals(objSumGoals);
                    }
                }
            })
            .catch(error=>{console.log(error);});
    };

    const consultaEstadisticaPresentador = () => {
        //Consultar  getProspectos
        AuthService.getProspectos(currentUser.idu)
            .then(resp => {
                setProspectos(resp.data)
            })
            .catch(error=>{console.log(error)});

        //Consultar  getExperiencias
        AuthService.getExperiencias(currentUser.idu)
            .then(resp => {
                let exp = [];
                exp.push(resp.data);
                setExperiencias(exp)
            })
            .catch(error=>{console.log(error)});

        //Consultar ventas del nuevo array de ventas de nueva API
        /*
        AuthService.getVentas(currentUser.idu)
            .then(resp => {
                setVentas(resp.data);
            })
            .catch(error=>{console.log(error)});
        */
        let noVentas = arrVentas.filter(v => v.idu === currentUser.idu)
        let noCncl = arrCancelaciones.filter(c => c.idu === currentUser.idu)
        let arrVentas2 = [{Ventas_sin_tm_ganada: noVentas.length, Cancelaciones: noCncl.length, idu: currentUser.idu}]
        setVentas(arrVentas2);
    };

    const consultaEstadisticaLyG = () => {
        let chavos = usersIntrns.filter(usr => (usr.estructura && usr.estructura.length>0 && usr.estructura.filter(usrE => (usrE && usrE.idu && usrE.idu===currentUser.idu) ).length>0) )
        //Vamos a verificar si el mismo usuario no esta en los chavos lo agregamos
        let inChavosCurrent = chavos.filter(u=>u.idu===currentUser.idu)
        if(inChavosCurrent.length===0){
            chavos.push(currentUser);
        }

        /*
        console.log("VENTAS:");
        console.log(arrVentas.sort((a,b)=>a.idu-b.idu));
        console.log("CANCELACIONES:");
        console.log(arrCancelaciones);
        */
        let arrVentas2 = []
        chavos.forEach( (u, i)=>{
            consultaGoals(u, false);
            consultaSumExperiencias(u, i===chavos.length-1);
            consultaSumProspectos(u, i===chavos.length-1);

            let noVentas = arrVentas.filter(v => v.idu === u.idu)
            let noCncl = arrCancelaciones.filter(c => c.idu === u.idu)

            let objVenta = {Ventas_sin_tm_ganada: noVentas.length, Cancelaciones: noCncl.length, idu: u.idu}
            arrVentas2.push(objVenta)
        });
        //console.log(arrVentas2)
        let sumVentas = 0;
        let sumCancelaciones = 0;
        arrVentas2.forEach(v=>{
            sumVentas += parseInt(v.Ventas_sin_tm_ganada);
            sumCancelaciones += parseInt(v.Cancelaciones);
        });
        //console.log({sumVentas: sumVentas, sumCancelaciones: + sumCancelaciones})
        setSumVentas({sumVentas: sumVentas, sumCancelaciones: + sumCancelaciones});

        //Consultar  getVentas
        /*
        let arrIdu = '';
        chavos.forEach((u, i)=>{
            arrIdu += i===0 ? u.idu: ', ' + u.idu
        });
        //console.log(arrIdu);
        AuthService.getVentas(arrIdu)
            .then(resp => {
                //console.log(resp.data);
                // Vamos a acumular las ventas de estos chavos
                if(resp.data.length>0){
                    let sumVentas = 0;
                    let sumCancelaciones = 0;
                    resp.data.forEach(v=>{
                        sumVentas += parseInt(v.Ventas_sin_tm_ganada);
                        sumCancelaciones += parseInt(v.Cancelaciones);
                    });
                    setSumVentas({sumVentas: sumVentas, sumCancelaciones: + sumCancelaciones});
                }
            })
            .catch(error=>{console.log(error)});
        */
        //console.log(chavos);
    };

    const consultaSumExperiencias = (user, ultimo) => {
        //console.log("--> " + user.idu)
        //Consultar  getExperiencias
        AuthService.getExperiencias(user.idu)
            .then(resp => {
                //console.log(resp.data);
                smtrExperiencias.experienciasProgramadas += resp.data.experienciasProgramadas.length;
                smtrExperiencias.experienciasRealizadas += resp.data.experienciasRealizadas.length;

                setSumExperiencias({
                    experienciasProgramadas: smtrExperiencias.experienciasProgramadas,
                    experienciasRealizadas: smtrExperiencias.experienciasRealizadas
                })

            })
            .catch(error=>{console.log(error)});
    };

    const consultaSumProspectos = (user, ultimo) => {
        AuthService.getProspectos(user.idu)
            .then(resp => {
                //console.log(resp.data);
                smtrPrsp += resp.data.length;

                //if (ultimo){
                    //console.log(user.idu + ' prospecto: ' + smtrPrsp + ' ultimo: ' + ultimo)
                    setSumProspectos(smtrPrsp);
                //}
            })
            .catch(error=>{console.log(error)});
    };

    /*
    const handleClick= (path, titleToolbar) => {
        window.localStorage.setItem('titleToolbar', titleToolbar)
        history.push(path);
    }
    */

    return (
        <div>
        {
            !load ?
                <Lottie
                    loop
                    animationData={loading}
                    play
                    style={{width: 450, height: 450, display: "block", margin: "auto"}}
                />
            :
            <div>
                
                <div className="row" align="center">
                    <h5>¿Qué he logrado?  /  ¿Qué quiero lograr?</h5>
                    <br />
                    {/* <h3 className="staTile RosaTitle">Estadística ROSA</h3> */}
                    {
                        //PRESENTADORES
                    }
                    {
                        (currentUser.rol[0].value === 1 || (currentUser.rol[0].value === 3)) &&
                        <div className="col l12 panelRosa">
                            <div className="ProspectoCont">
                                <h4 className="staTile StatTitle_Prospecto">
                                  Prospectos: 
                                  <Tooltip arrow disableFocusListener title="Total de contactos ingresados con eventos agendados este mes.">
                                    <InfoOutlinedIcon fontSize="large"/> 
                                  </Tooltip>
                                </h4>
                                <div>
                                  <h5> {prospectos.length}/{myGoals && myGoals.length>0 ? myGoals[0].prospectos: 0}</h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col l12 s12 rosaSection row">
                                    <div className="col l6 m6 s12">
                                        <h4 className="staTile StatTitle_ExpProg">
                                            Demos Programadas:
                                            <Tooltip arrow disableFocusListener title="Total de Experiencias (demos) agendadas respecto al objetivo declarado.">
                                                <InfoOutlinedIcon fontSize="large"/> 
                                            </Tooltip>
                                        </h4>
                                        <div>
                                          <h5> {experiencias && experiencias.length>0 && experiencias[0].experienciasProgramadas.length} / {myGoals && myGoals.length>0 ? myGoals[0].experiencias: 0}</h5>
                                        </div>
                                    </div>

                                    <div className="col l6 m6 s12">
                                        <h4 className="staTile StatTitle_ExpDone">
                                            Demos Realizadas:
                                            <Tooltip arrow disableFocusListener title="Total de Experiencias (demos) con pase de lista realizado, respecto al objetivo declarado por mi equipo.">
                                                <InfoOutlinedIcon fontSize="large"/> 
                                            </Tooltip> 
                                        </h4>
                                        <div>
                                            <h5> {experiencias && experiencias.length>0 && experiencias[0].experienciasRealizadas.length} / {myGoals && myGoals.length>0 ? myGoals[0].experiencias: 0}</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className="col l12 s12 rosaSection">
                                    <div className="ProspectoCont">
                                        <h4 className="staTile StatTitle_Sales">
                                          Ventas: 
                                          <Tooltip arrow disableFocusListener title="Total de pedidos efectivos realizados en el mes.">
                                            <InfoOutlinedIcon fontSize="large"/> 
                                          </Tooltip>
                                        </h4>
                                        <div>
                                          <h5>{ventas && ventas.length>0 && ventas[0].Ventas_sin_tm_ganada}/{myGoals && myGoals.length>0 ? myGoals[0].ventas: 0}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        //LIDERES
                    }
                    {
                        (currentUser.rol[0].value === 3) &&
                        <div className="col l12 s12 panelRosa">
                            <div>
                                <h4 className="StatTitle_MyTeam">Mi Equipo</h4>

                                <div className="row">
                                    <div className="col l12 s12 ProspectoCont">
                                        <h4 className="staTile StatTitle_Prospecto">
                                          Prospectos: 
                                          <Tooltip arrow disableFocusListener title="Total de contactos ingresados con eventos agendados este mes.">
                                              <InfoOutlinedIcon fontSize="large"/> 
                                          </Tooltip>
                                        </h4>
                                        <div>
                                            <h5>{sumProspectos}/
                                                {
                                                    (parseInt(sumaGoals &&  sumaGoals.sumProspectos)?
                                                        parseInt(sumaGoals && sumaGoals.sumProspectos):0)
                                                }
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col l12 s12 rosaSection row">
                                        <div className="col l6 s12">
                                            <h4 className="staTile StatTitle_ExpProg">
                                                Demos Programadas:
                                                <Tooltip arrow disableFocusListener title="Total de Experiencias (demos) agendadas respecto al objetivo declarado por mi equipo.">
                                                    <InfoOutlinedIcon fontSize="large"/> 
                                                </Tooltip>
                                            </h4>
                                            <div>
                                              <h5>
                                                  {sumExperiencias && sumExperiencias.experienciasProgramadas} /
                                                    {
                                                        (parseInt(sumaGoals && sumaGoals.sumExperiencias)?
                                                            parseInt(sumaGoals && sumaGoals.sumExperiencias):0)
                                                    }
                                              </h5>
                                            </div>
                                        </div>
                                        <div className="col l6 s12">
                                            <h4 className="staTile StatTitle_ExpDone">
                                                Demos Realizadas:
                                                <Tooltip arrow disableFocusListener title="Total de Experiencias (demos) con pase de lista realizado, respecto al objetivo declarado por mi equipo.">
                                                    <InfoOutlinedIcon fontSize="large"/> 
                                                </Tooltip> 
                                            </h4>
                                            <div>
                                              <h5>
                                                  {sumExperiencias && sumExperiencias.experienciasRealizadas} /
                                                    {
                                                        (parseInt(sumaGoals && sumaGoals.sumExperiencias)?
                                                            parseInt(sumaGoals && sumaGoals.sumExperiencias):0)
                                                    }
                                              </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col l12 s12 rosaSection">
                                        <div className="ProspectoCont">
                                            <h4 className="staTile StatTitle_Sales">
                                              Ventas: 
                                              <Tooltip arrow disableFocusListener title="Total de pedidos efectivos realizados en el mes respecto al objetivo declarado por mi equipo.">
                                                  <InfoOutlinedIcon fontSize="large"/> 
                                              </Tooltip> 
                                            </h4>
                                            <div>
                                              <h5>
                                                  {parseInt(sumVentas && sumVentas.sumVentas)}/
                                                    {
                                                        (parseInt(sumaGoals && sumaGoals.sumVentas)?
                                                            parseInt(sumaGoals && sumaGoals.sumVentas):0)
                                                    }
                                              </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        //GERENTES
                    }
                    {
                        (currentUser.rol[0].value === 5) &&
                        <div className="col l12 s12 panelRosa">
                            <div>
                                <h4 className="StatTitle_MyTeam">Mi Gerencia</h4>
                                <div className="row">
                                    <div className="col l12 s12 rosaSection row">
                                        <div className="col l6 s12">
                                            <h4 className="staTile StatTitle_ExpProg">
                                              Demos Programadas: 
                                              <Tooltip arrow disableFocusListener title="Total de Experiencias (demos) agendadas respecto al objetivo declarado por mi fuerza de ventas.">
                                                  <InfoOutlinedIcon fontSize="large"/> 
                                              </Tooltip>
                                            </h4>
                                            <div>
                                                <h5>
                                                    {sumExperiencias && sumExperiencias.experienciasProgramadas} / {sumaGoals ? sumaGoals.sumExperiencias:0}
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="col l6 s12">
                                            <h4 className="staTile StatTitle_ExpDone">
                                              Demos Realizadas: 
                                              <Tooltip arrow disableFocusListener title="Total de Experiencias (demos) con pase de lista realizado, respecto al objetivo declarado por mi fuerza de ventas.">
                                                  <InfoOutlinedIcon fontSize="large"/> 
                                              </Tooltip>
                                            </h4>
                                            <div>
                                                <h5>
                                                    {sumExperiencias && sumExperiencias.experienciasRealizadas}/{sumaGoals ? sumaGoals.sumExperiencias:0}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col l12 s12 rosaSection">
                                        <div className="ProspectoCont">
                                            <h4 className="staTile StatTitle_Sales">
                                              Ventas: 
                                              <Tooltip arrow disableFocusListener title="Total de pedidos efectivos realizados en el mes, respecto al budget mensual.">
                                                  <InfoOutlinedIcon fontSize="large"/> 
                                              </Tooltip>
                                            </h4>
                                            <div>
                                              <p>¿Qué ha logrado mi objetivo? / Budgets</p>
                                              <h5>
                                                  {parseInt(sumVentas && sumVentas.sumVentas)} / {myGoals && myGoals.length>0 ? parseInt(myGoals && myGoals[0].budgets): 0}
                                              </h5>
                                            </div>
                                            <div>
                                              <p>¿Qué ha logrado mi objetivo? / Acumulado de mi equipo</p>
                                              <h5>
                                                  {parseInt(sumVentas && sumVentas.sumVentas)} / {sumaGoals ? sumaGoals.sumVentas: 0}
                                              </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            
            </div>
        }
        </div>
    );
}