import AuthService from "../Services/AuthService";
//import { useHistory } from 'react-router-dom'
import React, {useState, useEffect} from 'react';
import "react-toastify/dist/ReactToastify.css";
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

/**
 * Componente para saber si el invitado asistira a un evento o no
 * @param {*} props datos del invitado
 * @returns Contenedor con la información de si el invitado asistira
 */
const Asistire = (props) => {

    //let history = useHistory();

    const [datosEmail] = useState(props.datos.replace('?', ''));
    const [asistire, setAsistire] = useState(0);

    useEffect(() => {
        console.log(props);
        let arrDatos = atob(datosEmail).split("-");
        setAsistire(parseInt(arrDatos[5]));
        let idEvento = arrDatos[0];
        let correoInvitado = arrDatos[4];
        let asistire = arrDatos[5];

        console.log(arrDatos[0]); // correo de la persona que crea el evento
        console.log(arrDatos[3] + " correo de invitado externo: " + arrDatos[4]); // Trae el response de si asistira o no asistira
        console.log(arrDatos[5]); // Trae el response de si asistira o no asistira

        // console.log("**********");
        // console.log(today);
        // console.log("**********");
        AuthService.updateListaInvitados(idEvento, correoInvitado, asistire)
            .then(resp=>{
                // Vamos a buscar en la lista de invitados al correo de este invitado y colocarle el dato de asistire
                console.log(resp);
            })
            .catch(err=>console.log(err));

        // Vamos a actualizar este evento este invitado si asistira o no asistira
    },[]);

    return (
        <Container component="main" className="answeringMainOverride">
            <CssBaseline/>
            <div className="answeringContainer">
                <div className="answeringPaper">
                    <img style={{width: "165px"}} src="/assets/img/thermomix_header_2023.png" alt=""/>
                    <br/>
                    <br/>
                    <div className="answeringTextBox">
                        <Typography className="answeringAsistencia">
                            {asistire===1 ? 'Gracias por confirmar tu asistencia':'Lamentamos que no nos puedas acompañar, '}
                        </Typography>
                        <Typography className="answeringAsistencia">
                            {asistire===1 ? '¡Estaremos felices de que nos acompañes!':'esperamos recibirte muy pronto en un evento Thermomix®.'}
                        </Typography>
                    </div>
                </div>
                <div>
                    <Typography className="answeringAvisoPrivacidad">
                    <Link href="https://www.thermomix.mx/politica-privacidad-2/">Aviso de Privacidad</Link>
                    </Typography>
                </div>
                <div className="asweringSocialBox">
                    <a href="https://www.facebook.com/thermomixmexico"><img className="answeringIcons" width="50px" height="47px" src="/assets/img/icons/ico_facebook_green.png" alt="Footer"/></a>
                    <a href="https://www.youtube.com/thermomixmexicooficial"><img className="answeringIcons" width="50px" height="47px" src="/assets/img/icons/ico_youtube_green.png" alt="Footer"/></a>
                    <a href="https://cookidoo.mx/foundation/es-MX"><img className="answeringIcons" width="50px" height="47px" src="/assets/img/icons/ico_cookidoo_green.png" alt="Footer"/></a>
                    <a href="https://www.pinterest.com.mx/thermomixmexico/"><img className="answeringIcons" width="50px" height="47px" src="/assets/img/icons/ico_pinterest_green.png" alt="Footer"/></a>
                    <a href="https://www.instagram.com/thermomixmexico/"><img className="answeringIcons" width="50px" height="47px" src="/assets/img/icons/ico_instagram_green.png" alt="Footer"/></a>
                </div>
                <div className="answeringFooterContainer">
                    <img width="100%" src="/assets/img/answering_footer.png" alt="Footer"/>
                </div>
            </div>
        </Container>
    );
};

export default Asistire;