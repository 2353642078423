import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {css} from "glamor";
import Lottie from 'react-lottie-player'
import loading from "../Lottie/33637-ondas-sonoras.json"
//import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
// import InfoIcon from '@material-ui/icons/Info';
//import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
//import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import Button from '@material-ui/core/Button';

//import DeleteIcon from '@material-ui/icons/Delete';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LinkUi from '@material-ui/core/Link';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
//import CardHeader from '@material-ui/core/CardHeader';
//import FormControl from '@material-ui/core/FormControl';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
//import Tabs from '@material-ui/core/Tabs';
//import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import ReorderIcon from '@material-ui/icons/Reorder';
import AppsIcon from '@material-ui/icons/Apps';
import Brightness1Icon from '@material-ui/icons/Brightness1';
//import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import AuthService from "../Services/AuthService";
import utils from "../Utils/collections";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        width: 250
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    MailU: {
        fontSize: 12,
        marginBottom:12
    },
    PhoneU: {
        fontSize: 12,
        marginBottom:12
    },
    NameU: {
        marginBottom: 12,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        // height: "40px",
        textTransform: "capitalize"
    },
});
/*
const useStylesTab = makeStyles({
    root: {
        flexGrow: 1,
    },
});
*/
const GreenCheckbox = withStyles({
    root: {
        color: "#808080",
        '&$checked': {
            color: "#00AC46",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);
const BlueCheckbox = withStyles({
    root: {
        color: "#00AC46",
        '&$checked': {
            color: "#00AC46",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);
/**
 * Componente de usuarios externos dentro del directorio
 * @param {*} props Datos del usuario logeado
 * @returns Regresa la vista de los usuarios externos
 */
export default function Externos(props) {

    let history = useHistory();
    const [currentUser] = useState(props.currentUser);
    const classesCard = useStyles();
    const [usrs, setUsrs] = useState([]); // Este array sera para mostrar los usuarios que sean elegidos
    const [usrsAll, setUsrsAll] = useState([]); // Respaldo de usuarios
    //const [usrsExtr, setUsrsExtr] = useState([]);
    const [usrsExtrCntc, setUsrsExtrCntc] = useState([]);
    const [usrsExtrRcmn, setUsrsExtrRcmn] = useState([]);
    const [usrsExtrPrsp, setUsrsExtrPrsp] = useState([]);
    const [usrsExtrClnt, setUsrsExtrClnt] = useState([]);
    const [usrsExtrRclt, setUsrsExtrRclt] = useState([]);
    const [verLista, setVerLista] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [editar, setEditar] = useState(false);
    const [perfiles] = useState(utils.perfiles);
    const defaultUsuario = {
        id:"", nombre: "",
        correo: "",
        telefono: "",
        categoria: "1",
        activo: true,
        perfil: [],
        nombreQuienRecomienda: "",
        correoQuienRecomienda: "",
        telefonoQuienRecomienda: "",
        iduQuienCreo: "",
        correoQuienCreo: "",
        noPedido: "",
        reclutamiento: false,
        notas: ""
    };
    const [newUsroExtr, setNewUsroExtr] = useState(defaultUsuario);
    const [traking, setTraking] = useState([]);
    const [openQuestion, setOpenQuestion] = useState(false);
    const [idEliminar, setIdEliminar] = useState("");
    const [ textoFiltrar, setTextoFiltrar] = useState("");
    const [ sortDown, setSortDown] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [SaveUsrExtBtn, setSaveUsrExtBtn] = useState(false);
    const [RyGRegistered, setRyGRegistered] = useState(0);

    useEffect(() => {
        consultaUsuarios(currentUser.rol[0].value===0? currentUser.correo: currentUser.idu);
        console.log('----------------->second function in useEffect.<----------------------');
        if (localStorage.getItem('showReferenciado') !== null){

            let preSelectedExterno = localStorage.getItem('showReferenciado');
            localStorage.removeItem("showReferenciado");
            console.log('------------------------>se encontró referenciado preSeleccionado, NetSuiteID:'+preSelectedExterno);
            AuthService.getReferenciado(preSelectedExterno, currentUser.idu)
                .then((resp) =>
                {
                    editarUsuarioOpenModal(resp.data.UsrExt);
                })
                .catch((err) =>
                {
                    console.log('Error in getReferenciado process:'+err);
                });
            
        } else {
            //To Do OCT23: Remove this ELSE and console.log.
            console.log('------------------------>NO se encontró referenciado preSeleccionado');
        }
    }, []);
    /**
     * Función para consultar los usuarios externos ligados al usuario logeado
     * @param {*} correo Usado para filtrar los usuarios externos por correo
     */
    const consultaUsuarios = (correo) => {
        AuthService.consult(`{userExternos(iduQuienCreo:"${correo}"){
            id idu:id nombre correo telefono categoria activo perfil nombreQuienRecomienda correoQuienRecomienda telefonoQuienRecomienda notas
                iduQuienCreo correoQuienCreo noPedido PedidoStatus reclutamiento fechaCambioCliente fechaCambioReclutamiento created referenciadoLMS}}`)
            .then(result=>{
                //console.log(result.data.data.userExternos);
                result.data.data.userExternos.map((el, i)=>{
                    return Object.assign(el, {"checked": false, "esExtr": true})
                })
                setCompleted(true);
                setUsrs(result.data.data.userExternos);
                setUsrsAll( JSON.parse(JSON.stringify(result.data.data.userExternos)) );
                //setUsrsExtr(result.data.data.userExternos);
                setUsrsExtrCntc(
                    result.data.data.userExternos.filter((el,i)=>el.categoria==='1')
                );
                setUsrsExtrRcmn(
                    result.data.data.userExternos.filter((el,i)=>el.categoria==='2')
                );
                setUsrsExtrPrsp(
                    result.data.data.userExternos.filter((el,i)=>el.categoria==='3')
                );
                setUsrsExtrClnt(
                    result.data.data.userExternos.filter((el,i)=>el.categoria==='4')
                );
                setUsrsExtrRclt(
                    result.data.data.userExternos.filter((el,i)=>el.categoria==='5')
                );
                props.onChange(result.data.data.userExternos.length);
            })
            .catch(error=>{
                console.log(error);
                setCompleted(true);
            })
    };
    /**
     * Función para mostrar notificación al usuario
     * @param {*} msg Mensaje que se le mostrara al usuario
     */
    const alerts = (msg, error) =>{
        toast(msg, {
            className: css({
                background: error ? "#E6332A":"#00AC46",
                color: "white"
            }),
            progressClassName: css({
                background: error ? "#00AC46":"#FFF"
            })
        });
    };
    /**
     * Función para abrir modal
     */
    const handleClickOpenModal = () => {
        setEditar(true)
        setNewUsroExtr(defaultUsuario);
        setOpenModal(true);
        setSaveUsrExtBtn(false);
    };
    /**
     * Función para cerrar modal
     */
    const handleCloseModal = (ev) =>{
        setEditar(false)
        setOpenModal(false);
    };
    /**
     * Función para agregar un nuevo usuario externo
     * @param {*} ev 
     */
    const newUserExterno = (ev) => {
        //Vamos a guardar los datos
        //console.log("Revisemos que datos guardar " + currentUser.nombre + " idu: " + currentUser.idu);;
        //console.log(newUsroExtr);
        //setUsrsExtr(usrsExtr.concat(newUsroExtr))
        // Validar campos
        let continua = true, mensaje='';
        setSaveUsrExtBtn(true);
        if(!validarEmail(newUsroExtr.correo)){
            mensaje += ("La dirección de email es incorrecta!.\n");
            continua = false;
        }
        if(newUsroExtr.nombre.length===0){
            mensaje += ("El nombre no puede estar vacío!.\n");
            continua = false;
        }
        if(newUsroExtr.telefono.length===0){
            mensaje += ("El telefono no puede estar vacío!.\n");
            continua = false;
        }
        if(newUsroExtr.perfil.length===0){
            mensaje += ("Debe colocar por lo menos un perfil!.\n");
            continua = false;
        }

        if(continua){
            let perfilStrn = newUsroExtr.perfil.toString();
            let arrPerfil = (perfilStrn && perfilStrn.length>0 ? perfilStrn.split(","): [] );
            let prfl = '[';
            arrPerfil.forEach((item, i)=>{
                prfl += '"'+ item.trim() + '"';
            });
            prfl += ']';
            //console.log(prfl.replace(/""/g, '","'));
            //Guardar en MongoDB
            AuthService.consult(`mutation{
                addUserExterno(
                    nombre: "${newUsroExtr.nombre}"
                    correo: "${newUsroExtr.correo}"
                    telefono: "${newUsroExtr.telefono}"
                    categoria: "${newUsroExtr.categoria}"
                    activo: ${true} 
                    perfil: ${prfl.replace(/""/g, '","')}
                    iduQuienCreo: "${currentUser.rol[0].value===0? currentUser.correo: currentUser.idu}"
                    correoQuienCreo: "${currentUser.correo}"
                    noPedido: ""
                    notas: "${newUsroExtr.notas}"
                ){
                    id nombre correo telefono categoria activo perfil iduQuienCreo correoQuienCreo noPedido notas
                }}`)    
                .then(result => {
                    //console.log(result.data.data.addUserExterno);
                    //setUsrsExtr(usrsExtr.concat(result.data.data.addUserExterno));
                    console.log('Guardado de Usuario Externo correcto');
                    consultaUsuarios(currentUser.rol[0].value===0? currentUser.correo: currentUser.idu);
                    setEditar(false)
                    setOpenModal(false);
                })
                .catch(error => {
                    console.log('Error en guardado de Usuario Externo');
                    console.log("error addUserExterno info: " + error);
                    setEditar(false)
                    setOpenModal(false);
                });
        } else {
            alerts(mensaje);
            setSaveUsrExtBtn(false);
        }
    }
    /**
     * Función para eliminar un usuario externo
     * @param {*} id ID de usuario a ser eliminado
     */
    const eliminarUsuarioExt = (id) => {
        console.log("Preguntar si desea eliminar..");
        setIdEliminar(id);
        setOpenQuestion(true);
    }
    /**
     * Función para ejecutar el gql para eliminar el usuario
     */
    const aceptoEliminarUser = () => {
        AuthService.consult(`mutation{
              removeUserExterno(id: "${idEliminar}"){ id }
            }`)
            .then(result => {
                consultaUsuarios(currentUser.rol[0].value===0? currentUser.correo: currentUser.idu);
                setOpenQuestion(false);
            })
            .catch(error => {
                console.log("error removeUserExterno info: " + error);
            });
    }
    /**
     * Función para abrir modal de edición de usuario
     * @param {*} usro Datos del usuario
     */
    const editarUsuarioOpenModal = (usro) => {
        AuthService.checkRyGinvite(usro.correo, currentUser.idu)
            .then(result=>{
                if(result.data.found && result.data.SameAdv){
                    setRyGRegistered(1);
                } else if (result.data.found){
                    setRyGRegistered(2);
                } else {
                    setRyGRegistered(0);
                }
            })
            .catch(error=>{
                console.log('Error in checkRyGinvite process.');
            });
        console.log('------------------>valor enviado para abrir editarUsuarioOpenModal<---------------------');
        console.log(usro)
        setCompleted(false);
        // Ir por el traking de este usuario todos sus eventos
        let datos = "id titulo fecha start end color idTipo tipo status created_event iduPresentador correoPresentador listaInvitados " +
            "{ idu nombre correo segmento esExtr sendMail asistire } fechaFinReagenda fechaFinReagendaFormato";
        AuthService.consult(`{eventsTracking( correo: "${usro.correo}" iduPresentador: "${currentUser.idu}" ){ ${datos} }}`)
        //AuthService.consult(`{events( correo: "${usro.correo}" ){ ${datos} }}`)
            .then(result=>{
                let ev = result.data.data.eventsTracking;
                /*
                ev.forEach(evn=>{
                    console.log('Correo Presentador: '+ evn.correoPresentador + ' - ' + evn.iduPresentador + ' vs ' + currentUser.idu)
                })
                let evnts = ev.length>0? ev.filter(e=>e.iduPresentador===currentUser.idu):[];
                */
                ev.forEach(evnt=>{
                    let arrInvitado = evnt.listaInvitados ? evnt.listaInvitados.filter((el,i)=>el.correo===usro.correo):[];
                    let asistio = arrInvitado.length>0? (arrInvitado[0].sendMail!==null?arrInvitado[0].sendMail:false) : false;
                    let realizado = evnt.listaInvitados ?
                        (evnt.listaInvitados.filter(invt=>invt.sendMail===true).length>0?'Realizado': 'Agendado') :
                        "Agendado";
                    Object.assign(evnt, {"asistio":asistio, "realizado": realizado});
                });
                console.log(ev);
                setCompleted(true);
                setTraking(ev);
                setNewUsroExtr(usro);
                setOpenModal(true);
            })
            .catch(error=>{
                console.log("Error eventByMonth, eventByDate: " + error);
                setCompleted(true);
                setTraking([]);
                setNewUsroExtr(usro)
                setOpenModal(true);
            })
    }
    /**
     * Función para ejecutar el gql y actualizar los deato del usuario externo
     */
    const editarUserExterno = () => {
        //console.log("Vamos a agregar estos nuevos datos para el id: " + newUsroExtr.id);
        //console.log(typeof(newUsroExtr.perfil));
        //let arrPerfil = (newUsroExtr.perfil && newUsroExtr.perfil.length>0 ? newUsroExtr.perfil.toString().split(","): [] );
        // Validar campos
        let continua = true, mensaje='';
        if(!validarEmail(newUsroExtr.correo)){
            mensaje += ("La dirección de email es incorrecta!.\n");
            continua = false;
        }
        if(newUsroExtr.nombre.length===0){
            mensaje += ("El nombre no puede estar vacío!.\n");
            continua = false;
        }
        if(newUsroExtr.telefono.length===0){
            mensaje += ("El telefono no puede estar vacío!.\n");
            continua = false;
        }
        if(newUsroExtr.perfil.length===0){
            mensaje += ("Debe colocar por lo menos un perfil!.\n");
            continua = false;
        }
        if(continua){
            let arrPerfil = newUsroExtr.perfil;
            if(typeof(newUsroExtr.perfil) !== 'object'){
                arrPerfil = (newUsroExtr.perfil && newUsroExtr.perfil.length>0 ? newUsroExtr.perfil.split(","): [] );
            }
            let prfl = '[';
            arrPerfil.forEach((item, i)=>{
                prfl += '"'+ item.trim() + '"';
            });
            prfl += ']';
            //Colocar categoría
            //console.log(newUsroExtr.categoria)
            let newCtgr = newUsroExtr.reclutamiento ? 5: newUsroExtr.noPedido.length>0 && newUsroExtr.referenciadoLMS !== true ? 4:
                (newUsroExtr.categoria === '2' || newUsroExtr.categoria === '3') ? newUsroExtr.categoria: 1;
            let gqlMuttn = `mutation{
                updateUserExterno(
                    id: "${newUsroExtr.id}"
                    nombre: "${newUsroExtr.nombre}"
                    correo: "${newUsroExtr.correo}"
                    telefono: "${newUsroExtr.telefono}"
                    categoria: "${newCtgr}"
                    activo: ${true} 
                    perfil: ${prfl.replace(/""/g, '","')}
                    noPedido: "${newUsroExtr.noPedido}"
                    reclutamiento: ${newUsroExtr.reclutamiento}
                    fechaCambioCliente: "${newCtgr===4 ? new Date().getTime(): ''}"
                    fechaCambioReclutamiento: "${newCtgr===5 ? new Date().getTime(): ''}"
                    notas: "${newUsroExtr.notas}"
                ){
                id nombre correo telefono categoria activo perfil iduQuienCreo correoQuienCreo nombreQuienRecomienda 
                correoQuienRecomienda telefonoQuienRecomienda noPedido reclutamiento fechaCambioCliente fechaCambioReclutamiento created notas
            }}`;
            //console.log(gqlMuttn)

            AuthService.consult(gqlMuttn)
                .then(result => {
                    consultaUsuarios(currentUser.rol[0].value===0? currentUser.correo: currentUser.idu);
                    setEditar(false)
                    setOpenModal(false);
                })
                .catch(error => {
                    console.log("error updateUserExterno info: " + error);
                    setEditar(false)
                    setOpenModal(false);
                });

        } else {
            alerts(mensaje);
        }
    };
    /**
     * Función para cuando se modifica los valores de un usuario que esta siendo creado
     * @param {*} ev Nuevo valor del elemento
     */
    const handleChangeNewUsro = (ev) => {
        console.log(ev.target.name +": "+ ev.target.value + " or -> " + ev.target.checked);
        if(ev.target.name==='reclutamiento'){
            setNewUsroExtr({ ...newUsroExtr, [ev.target.name]: ev.target.checked });
        } else {
            setNewUsroExtr({ ...newUsroExtr, [ev.target.name]: ev.target.value });
        }
    }
    /**
     * Función para validar correo
     * @param {*} valor Correo de uaurio
     * @returns True or False
     */
    const validarEmail=(valor) =>{
        //eslint-disable-next-line
        return (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(valor))
    };

    const handleVerLista = (verLsta) =>{
        setVerLista(verLsta)
    }
    /**
     * Función para seleccionar todos los usuarios
     * @param {*} event 
     */
    const handleCheckAll = (event) => {
        setCheckAll(event.target.checked)
        usrs.map((el,i)=>{
            return el.checked=event.target.checked
        })
    }
    /**
     * Función para seleccionar un usuario
     * @param {*} event 
     * @param {*} id 
     */
    const handleCheckOne = (event, id) => {
        //console.log(id+ " vamos a checar uno por uno ..." + event.target.checked)
        setCheckAll(false)
        let nU = []
        usrs.forEach((el,i)=>{
            if(el.id===id){
                el.checked = event.target.checked
            }
            nU.push(el)
        })
        setUsrs(nU)
    }
    /**
     * Función para seleccionar a los usuarios que se les invitara a un evento
     * @param {*} path 
     * @param {*} titleToolbar 
     */
    const handleInvitarEvento = (path, titleToolbar) => {
        let usrsInvt = usrs.filter((el)=> el.checked === true);
        let arrUsers = [];
        //To Do VerZ2: IMPORTANT--> CHECK IF THIS FUNCTION IS USED AND WHERE.

        usrsInvt.forEach(usr=>{

            console.log('Valores de usuarios para este segmento de logicas: ');

        let StringedUser = JSON.stringify(usr, null, 2);
        console.log(StringedUser);

        console.log('----> SEGMENTO EN PRUEBA -----> Valor para referenciadoLMS: '+usr.referenciadoLMS);


            let objUsr = {
                idu: usr.id,
                correo: usr.correo,
                nombre: usr.nombre,
                segmento: usr.perfil,
                esExtr: true,
                sendMail: false,
                asistire: 0,
                referenciadoLMS: usr.referenciadoLMS
            };
            arrUsers.push(objUsr);
        });
        let usuarios = btoa(JSON.stringify(arrUsers));
        localStorage.setItem("usersEvent", usuarios);
        window.localStorage.setItem('titleToolbar', titleToolbar)
        history.push(path);
    }
    /**
     * Función para invitar a usuarios seleccionados para invitar a evento
     * @param {*} path Parametro de ruta
     * @param {*} titleToolbar Nombre de la sección a la que pasaremos
     */
    const invitarEvento = (path, titleToolbar) => {
        let objUsr = [{
            idu: newUsroExtr.id,
            correo: newUsroExtr.correo,
            nombre: newUsroExtr.nombre,
            segmento: newUsroExtr.perfil,
            esExtr: true,
            sendMail: false,
            asistire: 0,
            referenciadoLMS: newUsroExtr.referenciadoLMS
        }];
        let usuarios = btoa(JSON.stringify(objUsr));
        localStorage.setItem("usersEvent", usuarios);
        window.localStorage.setItem('titleToolbar', titleToolbar)
        history.push(path);
    }

    const goEdit=()=>{
        setEditar(true)
    }

    const InvitaRyG=()=>{

        console.log('----------->Usuario externo para invitación RyG. --->correo :'+newUsroExtr.correo)
        console.log('----------->Usuario externo para invitación RyG. --->nombre :'+newUsroExtr.nombre)
        console.log('----------->Usuario externo para invitación RyG. --->idu presentador :'+currentUser.idu)
        console.log('----------->Usuario externo para invitación RyG. --->ID presentador :'+currentUser.id_user)
        console.log('----------->Usuario externo para invitación RyG. --->nombre presentador :'+currentUser.nombre)
        

        AuthService.sendMailRyGInvite(newUsroExtr.correo,newUsroExtr.nombre,currentUser.idu,currentUser.id_user,currentUser.nombre)
            .then(resp => {
                if(resp.data.exito){
                    alerts(resp.data.msg);
                }   
                //console.log('Invitación para Recomienda y Gana Enviada.');
            })
            .catch(error=>{console.log(error)});
        /*
        AuthService.sendMailInvtExtrEvnt(
            
            (user.rol[0].value === 0 ? user.correo: user.idu), user.correo, invt.correo,
            datosEvento.tipo, invt.nombre, fechaEvento, horaEvento)
            .then(resp => {
                console.log('Correo de Ficha de Entrevista Enviado Correctamente.');
            })
            .catch(error=>{console.log(error)});
                    */
    };

    const handleClickSortDownUp = (fltr) => {
        setSortDown(!sortDown)
        let usrsFtlr = JSON.parse(JSON.stringify(usrs))
        let newsUsrs =
            fltr === 'nombre' ?
                ( usrsFtlr.sort((usrA, usrB) =>
                    (usrB.nombre.toLowerCase() < usrA.nombre.toLowerCase()) ? -1 : (usrB.nombre.toLowerCase() > usrA.nombre.toLowerCase()) ? 1 : 0
                ) ):
                fltr === 'creado'?
                    ( usrsFtlr.sort((usrA, usrB) =>
                        (new Date(usrB.created).getTime() < new Date(usrA.created).getTime()) ?
                            -1 : (new Date(usrB.created).getTime() > new Date(usrA.created).getTime()) ? 1 : 0
                    )):
                    (
                        usrsFtlr.sort((usrA, usrB) =>
                            (usrB.perfil.toString().toLowerCase() < usrA.perfil.toString().toLowerCase()) ?
                                -1 : (usrB.perfil.toString().toLowerCase() > usrA.perfil.toString().toLowerCase()) ? 1 : 0
                        ));

        if(sortDown){
            newsUsrs = newsUsrs.reverse()
        }
        //console.log(newsUsrs)
        setUsrs(newsUsrs)
    }

    const renderTodos = usrs.map( ( el, index ) => {
        if(verLista){
            return <li key={ index } style={{listStyle: "none"}}>
                <div className="row" key={index}>
                    {
                        index === 0 &&
                        <div className="col l12 s12" style={{marginTop: "20px"}}>
                            <div className="col l1 s1">&nbsp;</div>
                            <div className="col l3 s5"> <b>Nombre</b>
                                <IconButton  className="infoContactBulk desktopshow" onClick={()=>handleClickSortDownUp("nombre")}>
                                    { sortDown ?
                                        <ArrowDropDownIcon fontSize="large"/> :
                                        <ArrowDropUpIcon fontSize="large"/>
                                    }
                                </IconButton>
                            </div>
                            <div className="col l3 s4"> <b>Creado </b>
                                <IconButton  className="infoContactBulk desktopshow" onClick={()=>handleClickSortDownUp("creado")}>
                                    { sortDown ?
                                        <ArrowDropDownIcon fontSize="large"/> :
                                        <ArrowDropUpIcon fontSize="large"/>
                                    }
                                </IconButton>
                            </div>
                            <div className="col l3 desktopshow"> <b>Perfil</b>
                                <IconButton  className="infoContactBulk desktopshow" onClick={()=>handleClickSortDownUp("perfil")}>
                                    { sortDown ?
                                        <ArrowDropDownIcon fontSize="large"/> :
                                        <ArrowDropUpIcon fontSize="large"/>
                                    }
                                </IconButton>
                            </div>
                            <div className="col l3 s2" style={{textAlign: "center"}}>
                                <span className="col l4"></span>
                                <FormGroup row className="col l4">
                                    <FormControlLabel
                                        control={
                                            <GreenCheckbox checked={checkAll}
                                                           onChange={handleCheckAll} name="all" />}
                                        label=""
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    }
                    <div className="col l12 s12" style={ index%2===0 ? { paddingTop:"9px", backgroundColor: "white" }:{ } }>
                        <div className="col l1 s1 ContactListViewCont">
                            <Brightness1Icon className={"categoria"+el.categoria}/>
                        </div>
                        <div className="col l3 s5 ContactListViewCont" style={{textTransform: 'capitalize'}}> {el.nombre}</div>
                        <div className="col l3 s4"> {
                            new Date(el.created).toLocaleDateString('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' })
                        } </div>
                        <div className="col l3 desktopshow">
                            {
                                el.perfil.toString().length>40?el.perfil.toString().substr(0,30)+'...':el.perfil.toString()
                            }
                        </div>
                        <div className="col l2 s2 ContactListViewCont nopadding" style={{textAlign: "center"}}>
                            <IconButton className="col l4 s6" onClick={()=>editarUsuarioOpenModal(el)}>
                                <VisibilityIcon color="disabled"/>
                            </IconButton>
                            <FormGroup row className="col l4 s6">
                                <FormControlLabel
                                    control={
                                        <GreenCheckbox checked={el.checked} style={{paddingTop: "0px"}}
                                            onChange={(ev)=>handleCheckOne(ev, el.id)} name={"user"+el.id} />}
                                    label=""
                                />
                            </FormGroup>
                        </div>
                    </div>
                </div>
            </li>;
        } else {
            return <li key={ index } style={{listStyle: "none"}}>
                <div className="col s12 l3" key={index}>
                    <Card className={classesCard.root + ' cardContact'}>
                        <CardContent  style={{textAlign: "center", padding:"5px"}}>
                            <FormGroup className="checkContactBulk"
                                       style={{width: "1em", display: "inline-block"}}>
                                <FormControlLabel
                                    control={
                                        <GreenCheckbox checked={el.checked}
                                                       onChange={(ev)=>handleCheckOne(ev, el.id)} name={"user"+el.id} />}
                                    label=""
                                />
                            </FormGroup>
                            {/* TODO: El color esta fijo en theme_themomix.css */}
                            <FiberManualRecordIcon className={"typeContactBulk categoria"+el.categoria} fontSize="large" />
                            <Typography className={el.referenciadoLMS?"RyGfloating":""}>
                            {el.referenciadoLMS?"Recomienda y Gana":""}
                            </Typography>
                            <Typography className={classesCard.NameU} style={{textTransform: 'capitalize'}}>
                                {el.nombre}
                            </Typography>
                            <Typography className={classesCard.PhoneU} color="textSecondary" gutterBottom>
                                {el.telefono}
                            </Typography>
                            <Typography className={classesCard.MailU} color="textSecondary" gutterBottom>
                                {el.correo}
                            </Typography>
                            <Typography className={classesCard.MailU} color="textSecondary" gutterBottom>
                                {el.perfil.toString().length>0?
                                    (el.perfil.toString().length>40?
                                        el.perfil.toString().substr(0,30)+'...':el.perfil.toString())
                                    : '-'}
                            </Typography>
                            <CardActions className="infoContactBulk">
                                <IconButton  className="infoContactBulk" onClick={() => editarUsuarioOpenModal(el)}>
                                    <img src="/assets/img/icons/informacion_gray_23.png" className="iconInfo" alt="Ver Información"/>
                                </IconButton>
                                {
                                    <Tooltip title="Eliminar">
                                        <IconButton aria-label="delete"
                                                    onClick={() => eliminarUsuarioExt(el.id)}>
                                            <img src="/assets/img/icons/trash_gray_23.png" className="iconDelContact" alt="Eliminar"/>
                                        </IconButton>
                                    </Tooltip>
                                }
                            </CardActions>
                        </CardContent>
                    </Card>
                </div>
            </li>;
        }
    } );

    const onchangeFiltrar = (ev) => {
        setTextoFiltrar(ev.target.value)
    }

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            filtrar(e);
        }
    }
    /**
     * Función para filtrar entre usuarios externos
     * @param {*} e 
     */
    const filtrar = (e) => {
        //console.log("Vamos a filtrar por el texto: " + textoFiltrar.toLowerCase())
        let usrsFtlr = usrsAll;
        let newsUsrs = usrsFtlr.filter(usr =>
            (
                usr.nombre.toLowerCase().indexOf(textoFiltrar.toLowerCase())>=0 ||
                usr.correo.toLowerCase().indexOf(textoFiltrar.toLowerCase())>=0 ||
                (usr.perfil.length>0 && usr.perfil.filter(prfl => prfl.toLowerCase().indexOf(textoFiltrar.toLowerCase()) >= 0 )>0)
            )
        )
        if(newsUsrs.length===0){
            usrsFtlr.forEach(usr=>{
                if(usr.perfil.length>0){
                    let usrPrfl = usr.perfil.filter(prfl => prfl.toLowerCase().indexOf(textoFiltrar.toLowerCase()) >= 0 )
                    if(usrPrfl.length>0){
                        newsUsrs.push(usr)
                    }
                }
            })
        }
        setUsrs(newsUsrs)
    }

    const handleChangeUser = (perfil) =>{
        let newsUsrs = [];
        switch(perfil){
            case 0:
                newsUsrs = usrsAll
                break;
            case 1:
                newsUsrs = usrsExtrCntc
                break;
            case 2:
                newsUsrs = usrsExtrRcmn
                break;
            case 3:
                newsUsrs = usrsExtrPrsp
                break;
            case 4:
                newsUsrs = usrsExtrClnt
                break;
            case 5:
                newsUsrs = usrsExtrRclt
                break;
            default:
                newsUsrs = usrsAll
                break;
        }
        setUsrs(newsUsrs)
    }

    return (
        <div>
            {
            !completed ?
                <Lottie
                    loop
                    animationData={loading}
                    play
                    style={{ width: 450, height: 450, display: "block", margin: "auto" }}
                />
                :
                <div>
                <div className="row ">
                    <div className="col l3 desktopshow">
                    </div>
                    <div className="col l3 s9" >
                        <input type="text" value={textoFiltrar} onKeyDown={_handleKeyDown}
                               onChange={(ev)=>onchangeFiltrar(ev)} placeholder="Buscar por usuario o por perfil..." className="searchExtInput"/>
                    </div>
                    <div className="col l1 s2">
                        <Button onClick={(ev)=>filtrar(ev)} style={{padding:"5px", backgroundColor: "#00AC46", borderRadius:"10px", color: "white"}}>
                            <SearchIcon style={{color: "white"}}/>
                        </Button>
                    </div>
                    <div className="col l5 s12">
                        <span className="searchExtInputInfo">{usrs.length + " usuarios encontrados"}</span>
                    </div>
                </div>
                <Divider />
                <div className="row UserFilterTypeDiv ">
                    <div className="col l8 s12" >
                        <div className="col s6 UserFilterType" style={usrsAll.length>0?{textAlign: "center", cursor: "pointer"}:{}}
                             onClick={()=>handleChangeUser(0)}>
                            Todos <span className="labelCategoria">
                            {usrsAll.length}
                            </span>
                        </div>
                        <div className="col s6 UserFilterType"  style={{textAlign: "center", cursor: "pointer"}}
                             onClick={()=>handleChangeUser(1)}>
                            Contactos <span className="labelCategoria1">{usrsExtrCntc.length}</span>
                        </div>
                        <div className="col s6 UserFilterType"  style={{textAlign: "center", cursor: "pointer"}}
                             onClick={()=>handleChangeUser(2)}>
                            Recomendados <span className="labelCategoria2">{usrsExtrRcmn.length}</span>
                        </div>
                        <div className="col s6 UserFilterType"  style={{textAlign: "center", cursor: "pointer"}}
                             onClick={()=>handleChangeUser(3)}>
                            Prospectos <span className="labelCategoria3">{usrsExtrPrsp.length}</span>
                        </div>
                        <div className="col s6 UserFilterType" style={{textAlign: "center", cursor: "pointer"}}
                             onClick={()=>handleChangeUser(4)}>
                            Clientes <span className="labelCategoria4">{usrsExtrClnt.length}</span>
                        </div>
                        <div className="col s6 UserFilterType" style={{textAlign: "center", cursor: "pointer"}}
                             onClick={()=>handleChangeUser(5)}>
                            Reclutas <span className="labelCategoria5">{usrsExtrRclt.length}</span>
                        </div>
                    </div>
                    <div className="col l4 s12" style={{textAlign: "center",  marginTop: "10px"}}>
                        <div className="row">
                        <div className="col l7">
                                <Button className="greenThermomix" onClick={handleClickOpenModal}
                                        style={{width: "200px", padding:"10px", color: "white", borderRadius:"10px"}}>
                                    <img src="/assets/img/icons/newcontact_white_23.png" alt="Nuevo Contacto" style={{mixBlendMode: "screen", width: "20px", margin: "5px", marginRight: '10px'}}/>
                                    Nuevo contacto
                                </Button>
                            </div>
                            <div className="col l2">
                                <ReorderIcon color="disabled" fontSize="large" style={verLista ?
                                    {color: "#00AC46", cursor: 'pointer'}:
                                    {color: "#0000008A", cursor: 'pointer'}}
                                            onClick={()=>handleVerLista(true)}/>
                            </div>
                            <div className="col l2">
                                <AppsIcon fontSize="large" style={verLista ?
                                    {color: "#0000008A", cursor: 'pointer'}:
                                    {color: "#00AC46", cursor: 'pointer'}}
                                        onClick={()=>handleVerLista(false)}/>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <Divider />
                <div className="row contactContainers" style={{overflow:"scroll"}}>
                    { renderTodos }
                </div>
    
                <div className="controlsAllInvite" style={{marginBottom:"30px"}}>
                {
                    usrs.length > 0 &&
                    <div className="row ">
                        {!verLista &&
                        <FormGroup className="selectboxAllCnt" style={{float: "right"}}>
                            <FormControlLabel
                                label="Seleccionar Todos"
                                control={
                                    <BlueCheckbox checked={checkAll} onChange={handleCheckAll} name="all" />
                                }
                                style={{color: "#00AC46"}}
                            />
                        </FormGroup>
                        }
                    </div>
                }
                {
                    usrs.length>0 &&
                    <div className="row">
                        <LinkUi color="inherit" href="/calendario?add=true"
                                onClick={()=>handleInvitarEvento('/calendario?add=true', 'Calendario?add=true') }>
                            <Button className="selectboxAllCnt"
                                style={{width: "200px", padding:"10px", backgroundColor: "#00AC46",
                                    borderRadius:"10px", color: "white"}}>
                                <img src="/assets/img/icons/calendar_white_23.png" alt="Calendario" style={{mixBlendMode: "screen", width: "20px", marginRight: "10px"}}/>
                                Invitar a evento
                            </Button>
                        </LinkUi>
                    </div>
                }
                </div>
    
                <Dialog open = {openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
                    <DialogTitle className="titleNtfcAddEvent" id="form-dialog-title" onClose={handleCloseModal}>
    
                        <MuiDialogTitle disableTypography >
                            {newUsroExtr.id && newUsroExtr.id.length > 0 ?
                                <span className="TitleModalContact">
                                    {editar ? 'Editar Contacto' : 'Detalles' }
                                </span>:
                                <span className="TitleModalContact">Agregar Contacto</span>
                            }
                            {handleCloseModal ? (
                                <IconButton style={{padding: "0"}} className="closeButtonX" aria-label="close" onClick={handleCloseModal}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            ) : null}
                        </MuiDialogTitle>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        </DialogContentText>
                        {
                            editar ?
                                <div className="row">
                                    {
                                        newUsroExtr.nombreQuienRecomienda && newUsroExtr.nombreQuienRecomienda.length>0 &&
                                        <div className="fieldModal">
                                            <InputLabel className="labelForm" id="nombreCompleto">Nombre de quien recomendó:</InputLabel>
                                            <input className="col l12 s12" type="text" name="nombre" value={newUsroExtr.nombreQuienRecomienda}
                                                   disabled readOnly style={{border: `1px solid #00AC46`}}/>
                                        </div>
                                    }
                                    {
                                        newUsroExtr.correoQuienRecomienda && newUsroExtr.correoQuienRecomienda.length > 0 &&
                                        <div className="fieldModal">
                                            <InputLabel className="labelForm" id="nombreCompleto">Correo de quien
                                                recomendo:</InputLabel>
                                            <input className="" type="text" name="nombre"
                                                   value={newUsroExtr.correoQuienRecomienda}
                                                   disabled readOnly style={{border: `1px solid #00AC46`}}/>
                                        </div>
                                    }
                                    {
                                        newUsroExtr.telefonoQuienRecomienda && newUsroExtr.telefonoQuienRecomienda.length > 0 &&
                                        <div className="fieldModal">
                                            <InputLabel className="labelForm" id="nombreCompleto">Teléfono de quien
                                                recomendó:</InputLabel>
                                            <input className="" type="text" name="nombre"
                                                   value={newUsroExtr.telefonoQuienRecomienda}
                                                   disabled readOnly style={{border: `1px solid #00AC46`}}/>
                                        </div>
                                    }
                                    <div className="fieldModal">
                                        <InputLabel className="labelForm" id="nombreCompleto">Nombre Completo:</InputLabel>
                                        <input className="" type="text" name="nombre" value={newUsroExtr.nombre}
                                               onChange={handleChangeNewUsro} style={{border: `1px solid #00AC46`}}/>
                                    </div>
                                    <div className="fieldModal">
                                        <InputLabel className="labelForm" id="correo">Correo:</InputLabel>
                                        <input className="" type="email" name="correo" value={newUsroExtr.correo}
                                               onChange={handleChangeNewUsro} style={{border: `1px solid #00AC46`}}/>
                                    </div>
                                    <div className="fieldModal">
                                        <InputLabel className="labelForm" id="telefono">Teléfono:</InputLabel>
                                        <input className="" type="number" name="telefono" value={newUsroExtr.telefono}
                                               onChange={handleChangeNewUsro} style={{border: `1px solid #00AC46`}}/>
                                    </div>
                                    <div className="fieldModal">
                                        <InputLabel className="labelForm" id="notas">Notas:</InputLabel>
                                        <input className="" type="text" name="notas" value={newUsroExtr.notas}
                                               onChange={handleChangeNewUsro} style={{border: `1px solid #00AC46`}}/>
                                    </div>
                                    <div className="fieldModal">
                                        <InputLabel className="labelForm" id="perfil">Perfil:</InputLabel>
                                        {/*
                                            <input className="" type="text" name="perfil" value={newUsroExtr.perfil}
                                                   onChange={handleChangeNewUsro} style={{border: `1px solid #00AC46`}}
                                                   placeholder="familiar, vegetariano"
                                            />
                                            */}
                                        <Select style={{width: "100%"}} name="perfil" value={newUsroExtr.perfil}
                                                onChange={handleChangeNewUsro} multiple input={<Input />} >
                                            {
                                                perfiles.map(el=>
                                                    <MenuItem key={el.id} value={el.nombre} >
                                                        {el.nombre}
                                                    </MenuItem>
                                                )
                                            }
                                        </Select>
    
    
                                    </div>
                                    {
                                        newUsroExtr.id && newUsroExtr.id.length > 0 && newUsroExtr.referenciadoLMS &&
                                        <div className="col l6 m12 s12 fieldModal">
                                            <InputLabel className="labelForm" id="noPedido">Número de Pedido: </InputLabel>
                                            <input className="" type="text" name="noPedido"
                                                   value={newUsroExtr.PedidoStatus === 'Cancelado' ? newUsroExtr.noPedido+' (CANCELADO)' : newUsroExtr.noPedido}
                                                   disabled readOnly style={{border: `1px solid #00AC46`}}/>
                                        </div>
                                    }
                                    {
                                        newUsroExtr.id && newUsroExtr.id.length > 0 && newUsroExtr.referenciadoLMS === false &&
                                            <div className="col l6 m12 s12 fieldModal">
                                                <InputLabel className="labelForm" id="correo">Número de Pedido</InputLabel>
                                                <input className="col l12 s12" type="email" name="noPedido" value={newUsroExtr.noPedido}
                                                       onChange={handleChangeNewUsro} style={{border: `1px solid #00AC46`}}/>
                                                {/*
                                        <InputLabel className="labelForm" id="perfil">Categoría:</InputLabel>
                                        <select id="selectCategoria" name="categoria" onChange={handleChangeNewUsro}
                                                value={newUsroExtr.categoria} style={{width: "225px", display: "block"}}>
                                            <option value="1">Contactos</option>
                                            <option value="2">Recomendaciones</option>
                                            <option value="3">Prospectos</option>
                                            <option value="4">Clientes</option>
                                        </select>*/}
    
                                            </div>
                                    }
                                    {
                                        newUsroExtr.id && newUsroExtr.id.length > 0 &&
                                        <div className="col l6 m12 fieldModal">
                                            <FormControlLabel label="Reclutamiento"
                                                  control={
                                                      <Checkbox value={newUsroExtr.reclutamiento}
                                                                checked={newUsroExtr.reclutamiento}
                                                                color="default"
                                                                onChange={handleChangeNewUsro}
                                                                name="reclutamiento"/>
                                                  }
                                            />
                                        </div>
                                    }
                                </div>
                                :
                                <div className="row popDetailContact">
                                    {/* Aqui va el detalle de un usuario */}
                                    {/* <div className="col l12 s12" style={{textAlign: "center"}} >
                                        <div className="col l1 desktopshow">
                                            <span style={{fontSize: "11px"}}>Categoría</span>
                                        </div>
                                        <div className="col l4 desktopshow" style={{textAlign: "center"}}>
                                            <span style={{fontSize: "11px"}}>Nombre</span>
                                        </div>
                                        <div className="col l3 desktopshow" style={{textAlign: "center"}}>
                                            <span style={{fontSize: "11px"}}>Teléfono</span>
                                        </div>
                                        <div className="col l4 desktopshow" style={{textAlign: "center"}}>
                                            <span style={{fontSize: "11px"}}>Correo electrónico</span>
                                        </div>
                                    </div> */}
                                    <div className="col l12 s12" style={{marginTop: "10px"}}>
                                        <div className="col l1 s2" style={{textAlign: "center"}}>
                                            <FiberManualRecordIcon className={"categoria"+newUsroExtr.categoria} fontSize="large"
                                                                   style={{display: "block", marginLeft: "11px"}}/>
                                        </div>
                                        <div className="col l4 s10" style={{textAlign: "center"}}>
                                            <span style={{fontSize: "20px", fontWeight: "600"}}>{newUsroExtr.nombre}</span>
                                        </div>
                                        <div className="col l3 s12" style={{textAlign: "center"}}>
                                            <span style={{fontSize: "20px"}}>{newUsroExtr.telefono}</span>
                                        </div>
                                        <div className="col l4 s12" style={{textAlign: "center"}}>
                                    <span style={{fontSize: "16px"}}>
                                        {newUsroExtr.correo}
                                    </span>
                                        </div>
                                    </div>
                                    <div className="col l12 desktopshow" style={{textAlign: "center", marginTop: "30px"}}>
                                        <div className="col l2 ">
                                            <span style={{fontSize: "11px"}}>Perfil</span>
                                        </div>
    
                                        <div className="col l5 " style={{textAlign: "center"}}>
                                            <span style={{fontSize: "11px"}}>Recomendación de: {newUsroExtr.referenciadoLMS}</span>
                                        </div>
                                        <div className="col l3 " style={{textAlign: "center"}}>
                                            <span style={{fontSize: "11px"}}>Número de Pedido</span>
                                        </div>
                                        <div className="col l2 ">
                                            <span style={{fontSize: "11px"}}>Reclutamiento</span>
                                        </div>
    
                                    </div>
                                    <div className="col l12 s12" style={{marginTop: "10px"}}>
                                        <div className="col l2 s12" style={{textAlign: "center"}}>
                                            {
                                                newUsroExtr.perfil.map( (el, i) =>
                                                    <div key={i}>
                                                        <span>- {el}</span><br/>
                                                    </div>
                                                )
                                            }
                                        </div>
    
                                        <div className="col l5 s12" style={{textAlign: "center"}}>
                                            {
                                                newUsroExtr.referenciadoLMS === true && newUsroExtr.correoQuienRecomienda<1 &&
                                                <>
                                                <p><strong classname="desktopshow">Registro propio en la plataforma</strong> <br classname="desktopshow"/> Recomienda y Gana</p>
                                                </>
                                            }
                                            {
                                                newUsroExtr.nombreQuienRecomienda && newUsroExtr.nombreQuienRecomienda.length > 0 && newUsroExtr.referenciadoLMS === false &&
                                                <>
                                                <p><strong classname="desktopshow">Origen de Recomendado:</strong> <br classname="desktopshow"/> Pase de Lista</p>
                                                    <p><strong classname="desktopshow">Recomienda:</strong> <br classname="desktopshow"/> {newUsroExtr.nombreQuienRecomienda}</p>
                                                    <p><strong classname="desktopshow">Correo de quien recomienda:</strong> {newUsroExtr.correoQuienRecomienda}</p>
                                                </>
                                            }
                                            {
                                                newUsroExtr.nombreQuienRecomienda && newUsroExtr.nombreQuienRecomienda.length > 0 && newUsroExtr.referenciadoLMS === true &&
                                                <>
                                                <p><strong classname="desktopshow">Origen de Recomendado:</strong> <br classname="desktopshow"/> Recomienda y Gana</p>
                                                    <p><strong classname="desktopshow">Recomienda:</strong> <br classname="desktopshow"/> {newUsroExtr.nombreQuienRecomienda}</p>
                                                    <p><strong classname="desktopshow">Correo de quien recomienda:</strong> {newUsroExtr.correoQuienRecomienda}</p>
                                                    <p><strong classname="desktopshow">Teléfono de quien recomienda:</strong> {newUsroExtr.telefonoQuienRecomienda}</p>
                                                </>
                                            }
                                        </div>
    
                                        <div className="col l3 s12" style={{textAlign: "center"}}>
                                            {
                                                newUsroExtr.noPedido && newUsroExtr.noPedido.length > 0 &&
                                                <span><strong classname="desktopshow"># Pedido:</strong> {newUsroExtr.referenciadoLMS === true && newUsroExtr.PedidoStatus === 'Cancelado' ? newUsroExtr.noPedido + ' (CANCELADO)':newUsroExtr.noPedido}</span>
                                            }
                                        </div>
                                        <div className="col l2 s12">
                                            <strong classname="desktopshow">Reclutamiento: </strong>
                                            <FormControlLabel style={{marginLeft: "15px"}}
                                                control={
                                                <Checkbox value={newUsroExtr.reclutamiento} checked={newUsroExtr.reclutamiento}
                                                                color="default"
                                                                onChange={handleChangeNewUsro}/>
                                                }
                                            />
                                        </div>
    
    
                                    </div>
                                    {
                                        //newUsroExtr.categoria==="2" &&
                                        <div className="col l12 s12" style={{marginTop: "10px"}}>
                                            <p><strong>Notas de este usuario:</strong></p>
                                            {newUsroExtr.notas}
                                        </div>
                                    }
    
                                    <div className="col l12 s12 titleNtfcAddEvent trackingTitleM" >
                                        <br />
                                        <span className="TitleModalContact">Tracking</span>
                                    </div>
                                    <div className="col l12 s12" style={{textAlign: "center", marginTop: "10px"}}>
                                        <div className="col l6 s12">
                                            <div className="row" style={{height: "200px", maxHeight: "350px", border: `1px solid #00AC46`,
                                                background: "#f1f1f1", overflowY: "scroll", borderRadius: "10px"}}>
                                                <div className="col l12 s12" style={{padding: "0", marginTop: "5px", marginBottom: "5px"}}>
                                                    <div className="col l4 s6" style={{fontSize: "11px"}}>Agregado el</div>
                                                    <div className="col l4 s6">
                                                        {new Date(newUsroExtr.created).toLocaleDateString()}
                                                    </div>
                                                </div>
                                                {
                                                    newUsroExtr.reclutamiento ?
                                                    <div className="col l12 s12"
                                                         style={{padding: "0", marginTop: "5px", marginBottom: "5px"}}>
                                                        <div className="col l4 s6" style={{fontSize: "11px"}}>Cambio a
                                                            Reclutamiento
                                                        </div>
                                                        <div className="col l4 s6">
                                                            {new Date(newUsroExtr.fechaCambioReclutamiento).toLocaleDateString()}
                                                        </div>
                                                    </div>
                                                    :
                                                    newUsroExtr.noPedido && newUsroExtr.noPedido.length>0 ?
                                                        <div className="col l12 s12"
                                                             style={{padding: "0", marginTop: "5px", marginBottom: "5px"}}>
                                                            <div className="col l4 s6" style={{fontSize: "11px"}}>Cambio a
                                                                cliente
                                                            </div>
                                                            <div className="col l4 s6">
                                                                {new Date(newUsroExtr.fechaCambioCliente).toLocaleDateString()}
                                                            </div>
                                                        </div>
                                                        :
                                                        ( newUsroExtr.categoria === '3' && traking.length>0 ) ?
                                                        <div className="col l12 s12"
                                                             style={{padding: "0", marginTop: "5px", marginBottom: "5px"}}>
                                                            <div className="col l4 s6" style={{fontSize: "11px"}}>Cambio a
                                                                Prospecto
                                                            </div>
                                                            <div className="col l4 s4 s6">
                                                                
                                                                { new Date(traking[0].fecha).toLocaleDateString() }
                                                            </div>
                                                        </div>
                                                            :
                                                        <></>
                                                }
                                                {
                                                    /* Pintar todo el traking */
                                                    traking.map((el,i)=>
                                                        <div className="col l12 s12" key={i}
                                                             style={{padding: "0", marginTop: "5px", marginBottom: "5px"}}>
                                                            <div className="col l4 s4" style={{fontSize: "11px"}}>
                                                                { el.titulo.length>0?el.titulo:el.tipo}
                                                            </div>
                                                            <div className="col l4 s4">
                                                                {new Date(el.fecha).toLocaleDateString()}
                                                                {
                                                                    el.status === 2 && el.fechaFinReagenda && el.fechaFinReagenda.length>0 ?
                                                                    <>
                                                                        <br />
                                                                        {new Date(el.fechaFinReagenda).toLocaleDateString()}
                                                                    </>:
                                                                    <></>
                                                                }
                                                            </div>
                                                            {
                                                                el.status === 2 ?
                                                                    <div className="col l4 s4" style={{fontSize: "11px"}}>
                                                                        Reagendado
                                                                    </div>
                                                                    :
                                                                    <div className="col l4 s4" style={{fontSize: "11px"}}>
                                                                        { el.realizado }
                                                                        <br/>
                                                                        {
                                                                            new Date(el.fecha).getTime()<new Date().getTime() ?
                                                                                (el.asistio?'Asistió': 'No asistió') : ''
                                                                        }
                                                                    </div>
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="col l6 s12 actionModalContacInfo">
                                            <div className="col l6 s6 ContactsButton">
                                                <LinkUi color="inherit"
                                                        onClick={goEdit}>
                                                    <Button className="MuiBadge-colorSecondary contactCardBtns" color="inherit">
                                                        <img src="/assets/img/icons/edit_white_23.png" alt="Editar"
                                                             style={{mixBlendMode: "screen", height: "30px", margin: "5px", marginRight: "20px"}}/>
                                                        Editar Datos de Contacto
                                                    </Button>
                                                </LinkUi>
                                            </div>
                                            <div className="col l6 s6 ContactsButton">
                                                <LinkUi color="inherit" href="/calendario?add=true"
                                                        onClick={()=>invitarEvento('/calendario?add=true', 'Calendario?add=true') }>
                                                    <Button className="MuiBadge-colorSecondary contactCardBtns" color="inherit">
                                                        <img src="/assets/img/icons/calendar_white_23.png" alt="Calendario"
                                                             style={{mixBlendMode: "screen", width: "32px", margin: "5px", marginRight: "20px"}}/>
                                                        Invitar Contacto a Evento
                                                    </Button>
                                                </LinkUi>
                                            </div>
                                            <div className="col l6 s6 ContactsButton">
                                            {RyGRegistered === 0 && (
                                                <LinkUi color="inherit"
                                                        onClick={InvitaRyG}>
                                                    <Button className="MuiBadge-colorSecondary contactCardBtns" color="inherit">
                                                        <img src="/assets/img/icons/cupon_ryg_23_white.png" alt="Invitar"
                                                             style={{mixBlendMode: "screen", height: "32px", margin: "5px"}}/>
                                                        Invitar a Recomienda y Gana
                                                    </Button>
                                                </LinkUi>
                                            )}
                                            {RyGRegistered > 0 && (
                                                <LinkUi color="inherit"
                                                        >
                                                    <Button disabled = {RyGRegistered} className="MuiBadge-colorBlocked contactCardBtns" color="inherit">
                                                        <img src="/assets/img/icons/cupon_ryg_23_white.png" alt="Invitar"
                                                             style={{mixBlendMode: "screen", height: "32px", margin: "5px"}}/>
                                                        Invitar a Recomienda y Gana
                                                    </Button>
                                                </LinkUi>
                                            )}
                                                {RyGRegistered === 1 && (
                                                        <React.Fragment>
                                                            <p style={{color: 'red'}}>Este contacto ya está registrado en Recomienda y Gana.</p>
                                                        </React.Fragment>
                                                )}
                                                {RyGRegistered === 2 && (
                                                        <React.Fragment>
                                                            <p style={{color: 'red'}}>Este contacto ya está registrado y asignado a otro presentador.</p>
                                                        </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
    
                    </DialogContent>
                    {
                        editar ?
                            <DialogActions>
                                { /*
                                    <Button onClick={handleCloseModal} color="primary">
                                        Cancelar
                                    </Button>
                                */ }
                                {
                                    newUsroExtr.id && newUsroExtr.id.length>0 ?
                                        <Button className="MuiBadge-colorSecondary editContactBtn" onClick={editarUserExterno} color="inherit">
                                            <EditIcon className="iconBtnG" fontSize="small" />
                                            Guardar
                                        </Button>
                                        :
                                        <Button className="MuiBadge-colorSecondary saveContactBtn" onClick={newUserExterno} disabled = {SaveUsrExtBtn} color="inherit">
                                            <DoneIcon className="iconBtnG" fontSize="small" />
                                            Guardar
                                        </Button>
                                }
                            </DialogActions>
                            :
                            <DialogActions>
                            </DialogActions>
                    }
    
                </Dialog>
                <Dialog
                    open={openQuestion}
                    onClose={()=>setOpenQuestion(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Eliminar usuario externo</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            ¿Estás seguro de eliminar al usuario?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setOpenQuestion(false)} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={aceptoEliminarUser} color="primary" autoFocus>
                            Aceptar
                        </Button>
                    </DialogActions>
                </Dialog>
                </div>
            }
        </div>
    );
}