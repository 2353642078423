import React, {useState, useEffect} from 'react';
import AuthService from "../Services/AuthService";
import Externos from './Externos'
import Internos from './Internos'
//Provisional Pendientes Disable
//import Pendientes from './Pendientes'
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
//import clsx from 'clsx';
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {css} from "glamor";
import Lottie from 'react-lottie-player'
import loading from "../Lottie/33637-ondas-sonoras.json"

/**
 * Componente del Directorio
 * @param {*} props Datos del usuario logeado
 * @returns La vista del directorio con usuarios externos e internos
 */
export default function Directorio(props) {

    //const [classes] = React.useState(props.classes);
    //const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const [valueTab, setValueTab] = React.useState(0);
    const [currentUser] = useState(props.datauser);
    const [noUsrsExtr, setNoUsrsExtr] = useState(0);
    const [usrsIntr, setUsrsIntr] = useState([]);
    const [noUsrsIntr, setNoUsrsIntr] = useState(0);
    //Provisional Pendientes Disable
    //const [noPendientes, setNoPendientes] = useState(0);
    const [completed, setCompleted] = useState(false);

    useEffect(() => {
        //Caso donde una lider es gerente
        let casoPocoCmun = false;
        if(currentUser.id_user_type_catalog===0 || currentUser.rol.length===0){
            AuthService.consult(`{users{
                    id
                    idu
                    nombre
                    apellido
                    correo
                    profile_route
                    rol {
                        value
                        text
                    }
                    telefono
                    oficina: sucursal {
                        value
                        name
                    }
                    estructura {
                        id_user
                        idu
                        nombre
                        apellido
                        correo
                        telefono
                        rol {
                            value
                            text
                        }
                        oficina {
                            value 
                            text
                        }
                    }
                }
            }`)
            .then(result=>{
                //console.log(result.data.data.users);
                result.data.data.users.map((el, i)=>{
                    return Object.assign(el, {"checked": false, "esExtr": false})
                });
                setCompleted(true);
                let estrAll = result.data.data.users;
                if (estrAll.length>0){
                    //console.log(estrAll);
                    setNoUsrsIntr(estrAll.length)
                    setUsrsIntr( estrAll.filter((el,i)=>el.idu!==null)
                        .sort((a,b)=>a.rol[0].value - b.rol[0].value) );
                }
            })
            .catch(error=>{
                console.log(error);
                setCompleted(true);
                setNoUsrsIntr(0);
                setUsrsIntr([]);
                if(error.response && error.response.status===401 && error.response.data.message==="Invalid Token"){
                    AuthService.logout();
                }
            });
        } else {
            //console.log(currentUser);
            let iduEstructura="";
            switch (currentUser.rol[0].value){
                case 3: // lider
                    iduEstructura=currentUser.estructura.filter((el,i)=>el.rol[0].value===5);
                    break;
                case 5: // gerente - delegada
                    iduEstructura=currentUser.estructura.filter((el,i)=>el.rol[0].value===8);
                    break;
                case 8: // area manager
                    iduEstructura=currentUser.estructura.filter((el,i)=>el.rol[0].value===9);
                    break;
                case 9: // regional manager
                    iduEstructura=[currentUser];
                    break;
                case 1: // presentadora
                    iduEstructura=currentUser.estructura.filter((el,i)=>el.rol[0].value===3);
                    if(iduEstructura.length===0) {
                        iduEstructura=currentUser.estructura.filter((el,i)=>el.rol[0].value===5);
                        if(iduEstructura.length>0){
                            let newIduEst = []
                            iduEstructura.map(est=> {
                                if(est.rol[0].text.toString().toLowerCase().indexOf('lider')>=0){
                                    est.rol[0].value = 3
                                    newIduEst.push(est)
                                }
                                return est
                            });
                            iduEstructura=newIduEst;
                        }
                        casoPocoCmun = true;
                    }
                    break;
                default:
                    iduEstructura=[currentUser];
                    break;
            }
            //console.log(iduEstructura)
            if(iduEstructura.length===0) {
                iduEstructura = [currentUser];
                alerts("Hay una inconsistencia con tu estructura, favor de revisar con tu Gerente.", true);
            }
            if(iduEstructura.length>0){
                AuthService.consult(`{estructura(iduSuperior:"${iduEstructura[0].idu}" idu: "${currentUser.idu}" 
                rol: ${currentUser.rol[0].value}){
                    id
                    idu
                    nombre
                    apellido
                    correo
                    rol {
                        value
                        text
                    }
                    telefono
                    profile_route
                    oficina: sucursal {
                        value
                        name
                    }
                    estructura {
                        id_user
                        idu
                        nombre
                        apellido
                        correo
                        telefono
                        rol {
                            value
                            text
                        }
                        oficina {
                            value 
                            text
                        }
                    }
                }
                }`)
                .then(result=>{
                    setCompleted(true);
                    if(result.data.data.estructura && result.data.data.estructura.length>0){
                        result.data.data.estructura.map((el, i)=>{
                            return Object.assign(el, {"checked": false, "esExtr": false})
                        });
                        //console.log(result.data.data.estructura);
                        //console.log("Arreglar currentUser.estructura");
                        //console.log(currentUser.estructura);
                        /*
                        let estrJrrq = [];
                        switch (currentUser.rol[0].value){
                            case 3: // lider - Jefa de Grupo
                                //      console.log("Aqui entra...");
                                estrJrrq = currentUser.estructura.filter((el,i)=> (el.rol[0].value!==8 && el.rol[0].value!==9) );
                                break;
                            case 5: // gerente - delegada
                                estrJrrq = currentUser.estructura.filter((el,i)=> el.rol[0].value!==9);
                                break;
                            case 8: // area manager
                                estrJrrq = currentUser.estructura.filter((el,i)=> el.rol[0].value===9);
                                break;
                            //case 9: // regional manager
                            //    iduEstructura=user;
                            //    break;
                            case 1: // presentadora
                                estrJrrq = currentUser.estructura.filter((el,i)=> (el.rol[0].value!==5 && el.rol[0].value!==8 && el.rol[0].value!==9));
                                break;
                            default:
                                estrJrrq = currentUser.estructura.filter((el,i)=> (el.rol[0].value!==5 && el.rol[0].value!==8 && el.rol[0].value!==9));
                                break;
                        }
                        //console.log(estrJrrq);
                        estrJrrq.map((el, i)=>{
                            return Object.assign(el, {"checked": false})
                        });
                        */
                        //console.log("casoPocoCmun: " + casoPocoCmun)
                        let estrAll = casoPocoCmun ?
                            result.data.data.estructura.filter(usr=>
                                (usr.idu===iduEstructura[0].idu) ||
                                (usr.estructura && usr.estructura.filter(otE =>
                                    otE.rol[0].text.toString().toLowerCase().indexOf("lider")>=0 &&
                                    iduEstructura[0].correo && otE.idu===iduEstructura[0].idu
                                ).length>0)
                            ):
                            result.data.data.estructura // [].concat(estrJrrq.filter((el,i)=>el.idu!==currentUser.idu), result.data.data.estructura);
                        if (estrAll.length>0){
                            //console.log(estrAll);
                            setNoUsrsIntr(estrAll.length)
                            setUsrsIntr(estrAll) //
                        }
                    }
                })
                .catch(error=>{
                    console.log(error);
                    if(error.response && error.response.status===401 && error.response.data.message==="Invalid Token"){
                        AuthService.logout();
                    }
                });
            }
        }
        /*
        AuthService.consult(`{listaInvitadosPendientes(iduPresentador:"${currentUser.rol[0].value===0? currentUser.correo: currentUser.idu}"){
            id titulo fecha start end color idTipo tipo modalidad1 modalidad2 notas direccion idAppointment 
            listaInvitados { idu nombre correo segmento esExtr sendMail } status created_event iduPresentador 
            correoPresentador seRepite frecuencia seRepiteHasta idEventoLigado}}`)
        .then(result=>{
            //console.log(result.data.data.listaInvitadosPendientes);
            let numeroPendientes = 0;
            let arrPend = [];
            result.data.data.listaInvitadosPendientes.forEach((el, i)=>{
                if(el.listaInvitados.length>0){
                    el.listaInvitados.forEach((res,i)=>{
                        console.log(res.sendMail);
                        if(res.sendMail){
                            // Revisar si ya existe en arreglo ya no meterlo
                            let arrExiste = arrPend.filter(usr=>usr.correo===res.correo)
                            if(arrExiste.length===0){
                                arrPend.push(res)
                                numeroPendientes++;
                            }
                        }
                    })
                }
            });
            console.log(numeroPendientes);
            setNoPendientes(0);
        })
        .catch(error=>{
            console.log(error);
        })
        */

        /* Provisional Pendientes Disable---------------------
        let numeroPendientes = 0;
        let arrPend = [];
        AuthService.consult(`{userExternos(iduQuienCreo:"${currentUser.rol[0].value===0? currentUser.correo: currentUser.idu}"){
            id idu:id nombre correo telefono categoria activo perfil nombreQuienRecomienda correoQuienRecomienda notas 
            iduQuienCreo correoQuienCreo noPedido reclutamiento fechaCambioCliente fechaCambioReclutamiento created}}`)
            .then(result=>{
                //console.log(result.data.data.userExternos);
                result.data.data.userExternos.forEach((usrExt, i)=>{
                    //console.log("ultimo evento de: " + usrExt.correo)
                    AuthService.consult(`{listaInvitadosPendientes(idu:"${usrExt.idu}"){
                    id titulo fecha start end color idTipo tipo modalidad1 modalidad2 notas direccion idAppointment 
                    listaInvitados { idu nombre correo segmento esExtr sendMail asistire} status created_event iduPresentador 
                    correoPresentador seRepite frecuencia seRepiteHasta idEventoLigado}}`)
                        .then(result=>{
                            //console.log("-->")
                            //console.log(result.data.data.listaInvitadosPendientes);
                            //console.log("<--")

                            result.data.data.listaInvitadosPendientes.forEach((el, i)=>{
                                if(el.listaInvitados.length>0){
                                    el.listaInvitados.forEach((res,i)=>{
                                        if( res.correo === usrExt.correo &&
                                            (!res.sendMail || res.sendMail===null) ){
                                            //Vamos a colocar el nombre del evento al que no asistio
                                            Object.assign(res, {"nombreEvento": el.tipo, "start": el.start,
                                                "checked": false, "esExtr": true, "sendMail": el.sendMail, "asistire": el.asistire})

                                            // Revisar si ya existe en arreglo ya no meterlo
                                            //let arrExiste = arrPend.filter(usr=>usr.correo===res.correo)
                                            //if(arrExiste.length===0){
                                                numeroPendientes++;
                                                arrPend.push(res);
                                                //console.log(numeroPendientes);
                                                setNoPendientes(numeroPendientes);
                                            //}
                                        }
                                    })
                                }
                            });
                        })
                        .catch(error=>{
                            console.log(error);
                        })

                })


            })
            .catch(error=>{
                console.log(error);
            })
    }, []);
    */
   //End of Provisional Pendientes Disable
}, []);


    /**
     * Función para mostrar notificación al usuario
     * @param {*} msg Mensaje que se le mostrara al usuario
     * @param {*} error Para saber si corresponde a un error
     */
    const alerts = (msg, error) =>{
        toast(msg, {
            className: css({
                background: error ? "#E6332A":"#00AC46",
                color: "white"
            }),
            progressClassName: css({
                background: error ? "#00AC46":"#FFF"
            })
        });
    };
    /**
     * Función para controlar el cambio entre las tabs de internos y externos
     * @param {*} event 
     * @param {*} newValue Valor de la tab a mostrar internos, externos
     */
    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };

    const handleChangeNoUsrsExtr = (e) => {
        setNoUsrsExtr(e)
    };

    /* Provisional Pendientes disable
    const handleChangeNoPendientes = (e) => {
        setNoPendientes(e)
    };
    */

    return (
        <div>
            {
            !completed ?
                <Lottie
                    loop
                    animationData={loading}
                    play
                    style={{ width: 450, height: 450, display: "block", margin: "auto" }}
                />
                :
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <div>
                        <div className="row" style={{marginBottom: "0px"}}>
                        <div className={valueTab === 1 ? "DirTab col l2 s4" : 'col l2 s4'} style={valueTab === 1 ?
                                {
                                    backgroundColor: "#C9FFDB",
                                    fontWeight: "bolder",
                                    padding: "10px",
                                    textAlign: "center",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2px",
                                    cursor: "pointer",
                                    borderTop: "1px solid #00AC46",
                                    borderRight: "1px solid #00AC46",
                                    borderLeft: "1px solid #00AC46",
                                    borderBottom: "2px solid #00AC46"
                                } :
                                {
                                    padding: "10px",
                                    textAlign: "center",
                                    borderStyle: "solid",
                                    borderWidth: "1px",
                                    borderRadius: "12px 12px 0 0",
                                    cursor: "pointer",
                                    borderTop: "1px solid lightgrey",
                                    borderRight: "1px solid lightgrey",
                                    borderLeft: "1px solid lightgrey"
                                }}
                                 onClick={(ev) => handleChangeTab(ev, 1)}>
                                <span style={{marginRight: "5px"}}>Internos</span>
                                <span className="labelCategoria">{noUsrsIntr}</span>
                            </div>
                            <div className={valueTab === 0 ? "DirTab col l2 s4" : 'col l2 s4 '} style={valueTab === 0 ?
                                {
                                    backgroundColor: "#C9FFDB",
                                    fontWeight: "bolder",
                                    padding: "10px",
                                    textAlign: "center",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2px",
                                    cursor: "pointer",
                                    borderTop: "1px solid #00AC46",
                                    borderRight: "1px solid #00AC46",
                                    borderLeft: "1px solid #00AC46",
                                    borderBottom: "2px solid #00AC46"
                                } :
                                {
                                    padding: "10px",
                                    textAlign: "center",
                                    borderStyle: "solid",
                                    borderWidth: "1px",
                                    borderRadius: "12px 12px 0 0",
                                    cursor: "pointer",
                                    borderTop: "1px solid lightgrey",
                                    borderRight: "1px solid lightgrey",
                                    borderLeft: "1px solid lightgrey"
                                }}
                                 onClick={(ev) => handleChangeTab(ev, 0)}>
                                <span style={{marginRight: "5px"}}>Externos</span>
                                <span className="labelCategoria">{noUsrsExtr}</span>
                            </div>
                            
                        </div>
                        <Divider/>
                        <br/>
                        {valueTab === 1 ?
                            <Internos currentUser={currentUser} usrsIntr={usrsIntr}/>
                            :
                            //valueTab === 0 ?
                            <Externos currentUser={currentUser} noUsrsExtr={noUsrsExtr}
                                      onChange={handleChangeNoUsrsExtr}/>
                        }

                    </div>
                </Grid>
            </Grid>
            }
        </div>
    );
}