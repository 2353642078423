import React, {useState, useEffect} from 'react';
import axios from "axios";
import AuthService from "../Services/AuthService";
import Collections from "../Utils/collections";
import Button from '@material-ui/core/Button';
import CallMadeIcon from '@material-ui/icons/CallMade';
import LinkUi from '@material-ui/core/Link';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DoneIcon from '@material-ui/icons/Done';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ShareIcon from '@material-ui/icons/Share';
import Tooltip from '@material-ui/core/Tooltip';
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {css} from "glamor";
import TextField from '@material-ui/core/TextField';

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                Este campo es requerido!
            </div>
        );
    }
};

export default function Recursos(props) {

    const [currentUser] = useState(props.user);
    const [files, setFiles] = useState(undefined);
    const [openModalFoto, setOpenModalFoto] = useState(false);
    const [titulo, setTitulo] = React.useState("");
    const [idRcrsDlte, setIdRcrsDlte] = React.useState("");
    const [recursos, setRecursos] = React.useState([]);
    const [openQuestion, setOpenQuestion] = useState(false);

    useEffect(() => {
        //Consultar todos los recursos
        consultarRecursos();
    }, []);

    const consultarRecursos = () => {
        AuthService.consult(`{recursosAll{
                id
                titulo
                resource_route
                user_create
            }
        }`)
        .then((recursos) => {
            setRecursos(recursos.data.data.recursosAll);
            setIdRcrsDlte("");
        })
        .catch(error => console.log(error))
    }

    const addRecursos = () => {
        setOpenModalFoto(true);
    }

    const alerts = (msg,error) =>{
        error ?
            toast(msg, {
                className: css({
                    background: "red",
                    color: "white"
                }),
                progressClassName: css({
                    background: "#00AC46"
                })
            })
            :
            toast(msg, {
                className: css({
                    background: "black"
                }),
                progressClassName: css({
                    background: "purple"
                })
            })
    };

    const guardarFoto=()=>{
        let continua = true;
        let msj = "";
        if(titulo.length===0){
            continua = false;
            msj = "Debes agregar un titulo a tu recurso."
        }
        if(!files){
            continua = false;
            msj = "Debes agregar un archivo válido para tu recurso."
        }
        if(continua){
            const reader = new FileReader();
            reader.onload = e => {
                //setImg(e.target.result);
                //console.log(e.target.result);
                AuthService.uploadResource(e.target.result, titulo)
                    .then(function(response) {
                        // Colocar la imagen
                        setOpenModalFoto(false);
                        consultarRecursos();
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            };
            reader.readAsDataURL(files);
        } else {
            alerts(msj, true)
        }
    }

    // called every time a file's `status` changes
    const handleChangeStatusFoto = ({ meta, file }, status) => {
        console.log(meta)
        if(status==="done"){
            if(meta.type==="application/pdf"){
                setFiles(file)
                console.log("pdf")
            } else {
                /*
                if(meta.height===500){
                    if(meta.width===500){
                    */
                setFiles(file)
                /*
                    } else{
                        alerts("El ancho de la imágen debe ser de 500px.", true)
                    }
                } else {
                    alerts("La altura de la imágen debe ser de 500px.", true)
                }
                */
            }
        }
    }
    const handleCloseModalFoto = (ev) =>{
        setOpenModalFoto(false);
    };

    const onChangeTitulo = (ev) =>{
        console.log("Cambiar titulo: " + ev.target.value)
        setTitulo( ev.target.value );
    };

    const download = (e, path, rcrs) => {
        if(path.indexOf(".pdf")>=0){
            axios.get(path).then(resp=>{
                const link = document.createElement("a");
                link.href = resp.data;
                link.type = 'application/pdf';
                link.setAttribute("download", "resourceThermomix.pdf"); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch(err=>console.log(err));
        } else {


            AuthService.downloadResource(rcrs, "img1").then(resp=>{
                //console.log('data:image/jpeg;base64,' + resp.data.base64);
                var canvas = document.getElementById("recurso_"+rcrs);
                var ctx = canvas.getContext("2d");
                var image = new Image();
                image.onload = function() {
                    //console.log(image.width + " " + image.height)
                    canvas.setAttribute("width", image.width);
                    canvas.setAttribute("height", image.height);
                    ctx.drawImage(image, 0, 0);
                    const link = document.createElement("a");
                    //console.log(window.URL.createObjectURL(blob))
                    link.href = canvas.toDataURL("image/png"); //'data:image/jpeg;base64,' + resp.data.base64
                    //link.type = 'image/jpeg';
                    link.setAttribute("download", "resource.jpg"); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                };
                image.src = 'data:image/jpeg;base64,' + resp.data.base64
            }).catch(err=>console.log(err));
        }
    };

    const eliminarRecurso = (id) => {
        setIdRcrsDlte(id);
        setOpenQuestion(true)
    };
    const aceptoEliminarRecurso = () => {
        AuthService.consult(`mutation{ removeRecurso(id:"${idRcrsDlte}"){ id resource_route } }`)
            .then((recursos) => {
                AuthService.deleteResource(recursos.data.data.removeRecurso.resource_route)
                    .then((delRecursos) => {
                        consultarRecursos();
                        setOpenQuestion(false)
                    })
                    .catch(error => {console.log(error)
                        consultarRecursos();
                        setOpenQuestion(false)
                    })
            })
            .catch(error => {console.log(error)
                consultarRecursos();
                setOpenQuestion(false)
            })
    }

    return (
        <div className="row">
            {
                currentUser.rol[0].value===0 &&
                <Button className="greenThermomix" onClick={addRecursos}
                        style={{ width: "230px", color: "white", borderRadius: "10px",
                            fontWeight: "600", padding: "10px", float: "right", marginRight: "50px"
                        }}>
                    <img src="/assets/img/icons/agregar_white_23.png" className="iconAgregarVar" alt="Agregar"/>
                    Agregar recursos
                </Button>
            }

            <div className="col l12" style={{marginTop: "30px"}}>
                {
                    recursos.map((recurso,i)=>
                        <div className="col l4 gridResourceElement" key={i}>
                            {
                                recurso.resource_route.indexOf(".pdf")>=0 ?
                                    <img width="300px" height="300px" src="/assets/img/pdf_thumbnail.png" alt="pdf"/> :
                                    <>
                                        <canvas id={"recurso_"+recurso.resource_route} width="500" height="500"
                                                style={{width: "300px", height: "300px", display: "none"}}/>
                                        <img width="300px" height="300px" id={"resource_" + recurso.resource_route} src={Collections.imageResourcePath + recurso.resource_route} alt="fotoperfil"/>
                                    </>
                            }
                            <div className="row" style={{marginTop: "-60px"}}>
                                <div className="col l5">
                                    {
                                        // eslint-disable-next-line
                                        <Button onClick={(e)=>download(e, Collections.imageResourcePath + recurso.resource_route, recurso.resource_route)}
                                            className="greenThermomix"
                                                style={{
                                                    padding: "10px", color: "white", borderRadius: "10px", fontWeight: "600"
                                                }}>
                                            <img src="/assets/img/icons/descargar_white_23.png" className="iconDownload" alt="Eliminar"/>
                                            Descargar
                                        </Button>
                                    }

                                </div>
                                <div className="col l2">
                                    {
                                        currentUser.rol[0].value===0 &&
                                        <Tooltip title="Delete" >
                                            <IconButton aria-label="delete" style={{backgroundColor: "#00AC46"}}
                                                        onClick={() => eliminarRecurso(recurso.id)}>
                                                <img src="/assets/img/icons/trash_white_23.png" className="iconTrash" alt="Eliminar"/>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </div>
                                <div className="col l5" style={{display:"none"}}>
                                    <Button className="greenThermomix"
                                        style={{float: "right",
                                            padding: "10px", color: "white", borderRadius: "10px", fontWeight: "600"
                                        }}>
                                        <ShareIcon style={{margin:"0 10"}}/>
                                        Compartir
                                    </Button>
                                </div>
                            </div>
                            <h5 >{recurso.titulo}</h5>
                        </div>
                    )
                }
            </div>
            <div className="col l12" style={{textAlign: "center", marginTop: "45px"}}>
                <LinkUi color="inherit" href="http://mithermomix.mx">
                    <Button className=""
                            style={{ color: "gray", fontSize: "19px", padding: "5px 30px",
                                borderRadius: "10px", borderStyle: "solid", borderWidth: "1px", textTransform:"none"
                            }}>
                        <CallMadeIcon style={{fontWeight: "600", color: "gray", marginRight: "20px", }} fontSize="large"/>
                        Encuentra más recursos en mithermomix.mx
                    </Button>
                </LinkUi>
            </div>
            <Dialog open={openModalFoto} onClose={handleCloseModalFoto} aria-labelledby="form-dialog-title">
                <DialogTitle className="titleNtfcAddEvent" id="form-dialog-title"
                             onClose={handleCloseModalFoto}  style={{width: "500px"}}>
                    <MuiDialogTitle disableTypography>
                                        <span
                                            className="TitleModalContact">Agregar recurso</span>
                        <IconButton className="closeButtonX" aria-label="close" onClick={handleCloseModalFoto}>
                            <CloseIcon fontSize="large" />
                        </IconButton>
                    </MuiDialogTitle>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="titulo"
                        label="Titulo"
                        name="titulo"
                        autoComplete="titulo"
                        autoFocus
                        value={titulo}
                        onChange={onChangeTitulo}
                        validations={[required]}
                    />

                    <Dropzone
                        onChangeStatus={handleChangeStatusFoto}
                        accept="image/*,.pdf"
                        maxFiles={1}
                        inputContent="Arrastra una imagen o un pdf haz click aquí para cargarla"
                        inputWithFilesContent={files => `${1 - files.length} more`}
                        submitButtonDisabled={files => files.length < 1}
                    />

                    {/* <div className="row">
                            <div className="col l12 s12 m12 s12 fieldModal">
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<GreenCheckbox checked={updatePassword} onChange={handleChangeUpdtPass}
                                                                name="updatePassword" />}
                                        label="No volver a mostrar este mensaje." style={{color: "#00AC46"}}
                                    />
                                </FormGroup>
                            </div>
                        </div> */}
                </DialogContent>
                <DialogActions>
                    <Button className="MuiBadge-colorSecondary saveContactBtn"
                            onClick={guardarFoto} color="inherit">
                        <DoneIcon className="iconBtnG" fontSize="small"/>
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openQuestion}
                onClose={()=>setOpenQuestion(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Eliminar Recurso</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Estás seguro de eliminar el recurso?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setOpenQuestion(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={aceptoEliminarRecurso} color="primary" autoFocus>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}