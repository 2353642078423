import React, { useState, useEffect }  from 'react';
import AuthService from "../Services/AuthService";
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import "react-datepicker/dist/react-datepicker.css";
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import NativeSelect from '@material-ui/core/NativeSelect';
import Checkbox from '@material-ui/core/Checkbox';

const GreenCheckbox = withStyles({
    root: {
        color: "#00AC46",
        '&$checked': {
            color: "#00AC46",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

export default function NavBar (props) {

    //let history = useHistory();
    const [user] = useState(AuthService.getCurrentUser());
    const [eventos, setEventos] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [datosEvento, setDatosEvento] = useState({});

    useEffect(() => {
        let findEv = JSON.parse(localStorage.getItem("findEvent"));
        //console.log(findEv);
        let find = findEv !== null && findEv.value ? findEv.value: '';
        let findId = findEv !== null && findEv.id ? findEv.id: '';
        localStorage.removeItem("findEvent");
        consultaEventos(user.rol[0].value === 0 ? user.correo: user.idu, find, findId);
    }, []);

    const consultaEventos = (userEvnt, value, id) => {
        let gql = `events(correo: "${userEvnt}")`;
        if(id.length>0){
            gql = `event(id: "${id}")`;
        }
        //console.log(gql);
        AuthService.consult(`query{
              ${gql}{ id tipo titulo fecha modalidad1 modalidad2 notas direccion start end status 
              idTipo listaInvitados { idu nombre correo segmento esExtr } 
              seRepite seRepiteHasta iduPresentador correoPresentador frecuencia }
            }`
        )
        .then(result=>{
            let evnts = id.length>0 ? [result.data.data.event]: result.data.data.events;
            //console.log(evnts)
            evnts = evnts.filter(ev => (
                ev.tipo.toLowerCase().indexOf(value.toLowerCase())>=0 ||
                ev.titulo.toLowerCase().indexOf(value.toLowerCase())>=0
            )).sort((a,b)=>{
                return new Date(b.fecha) - new Date(a.fecha)
            });
            //console.log(evnts)
            setEventos(evnts);
        })
        .catch(error=>{
            console.log(error);
            if(error.response && error.response.status===401 && error.response.data.message==="Invalid Token"){
                AuthService.logout();
            }
        });
    }

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const showEvent = (evento) => {
        setDatosEvento(evento)
        setOpenModal(true)
    }

    return (
        <div>
            <h2>Listado de eventos</h2>
            <table>
                <thead>
                    <tr>
                        <th>EVENTO</th>
                        <th>MODALIDAD 1</th>
                        <th>MODALIDAD 2</th>
                        <th>FECHA</th>
                        <th>HORA</th>
                        <th>OPCIONES</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        eventos.map((ev,index)=>
                            <tr key={index}>
                                <td>{ev.tipo}</td>
                                <td>{ev.modalidad1}</td>
                                <td>{ev.modalidad2}</td>
                                <td>{new Date(ev.start).toLocaleDateString()}</td>
                                <td>{new Date(ev.start).toLocaleTimeString()}</td>
                                <td>
                                    <Button onClick={()=>showEvent(ev)}
                                        style={{backgroundColor: "#00AC46", color: "#FFF", paddingLeft:"10px", paddingRight:"10px"}}>
                                        <SearchIcon/>
                                        Consultar
                                    </Button>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
            {/* * * * M O D A L  D E L  E V E N T O * * * */}
            <Dialog open = {openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title" >
                <DialogTitle className="titleNtfcAddEvent" id="form-dialog-title" onClose={handleCloseModal}>
                    {
                        datosEvento.status===0 ?
                            <span style={{color: "red"}}>Evento Cancelado</span> :
                            <span>Datos del Evento</span>
                    }
                </DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col l5" style={{display: "flex", padding: "0px"}}>
                            {/* <DateRangeOutlinedIcon color="disabled"/> */}
                            <img src="/assets/img/icons/calendar_23.png" className="iconSideMenu icon_popup" alt="calNewEvent"/>
                            <span style={{display:"table-cell", verticalAlign: "middle"}}>
                                {new Date(datosEvento.fecha).toLocaleDateString('es-MX',
                                    { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
                                }
                            </span>
                        </div>
                        <div className="col l5">
                            <div className="col l6 " style={{display: "block", padding: "0px"}}>
                                <span className="col l12">
                                    De: {new Date(datosEvento.start).toLocaleTimeString('es-MX', { hour: '2-digit', minute: '2-digit'})}
                                </span>
                            </div>
                            <div className="col l6 " style={{display: "block", padding: "0px"}}>
                                <span className="col l12">
                                    A: {new Date(datosEvento.end).toLocaleTimeString('es-MX', { hour: '2-digit', minute: '2-digit'})}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col l6">
                            <b>Evento:</b> <br/>
                            {datosEvento.tipo}
                            <br/>
                            <br/>
                            {
                                datosEvento.titulo && datosEvento.titulo.length>0 &&
                                    <div>
                                        <b>Título de tu evento:</b><br/>
                                        {datosEvento.titulo}
                                    </div>
                            }
                        </div>
                        <div className="col l6">
                            <b>Modalidad: </b> <br/>
                            <span>{datosEvento.modalidad1==='grupal'?'Grupal': 'Individual'}</span>
                            <span style={{margin: "10px"}}>/</span>
                            <span>{datosEvento.modalidad2==='virtual'?'Virtual': 'Presencial'}</span>

                        </div>
                    </div>
                    {
                        datosEvento.iduPresentador === user.idu &&
                        <>
                            <div className="row">
                                <img src="/assets/img/icons/contacts_23.png" className="iconSideMenu icon_popup" alt="calNewEvent"/>
                                <span style={{fontSize: "22px", color: "gray"}}>Lista de Invitados</span>
                            </div>
                            <div className="row">

                            <div className="col l12">

                                {
                                    (datosEvento.listaInvitados && datosEvento.listaInvitados.length>0) &&
                                    <div className="col l6">
                                        <div className="row"
                                             style={{padding: '0px', margin: '0px', height: "300px",
                                                 maxHeight: "450px", border: `1px solid #00AC46`,
                                                 background: "#f1f1f1", overflowY: "scroll"}}>
                                            <NativeSelect disabled style={{width: "100%"}}>
                                                <option>Invitados</option>
                                            </NativeSelect>
                                            {
                                                datosEvento.listaInvitados.map((el, index)=>
                                                    <div key={index}>
                                                            <span className="col l9"
                                                                  style={{padding: '0px', margin: '0px', fontSize: "12px", marginTop: "5px"}}>
                                                                { el.nombre + (el.apellido && el.apellido !==null && el.apellido.length>0 ? ' ' + el.apellido: '') }
                                                            </span>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                }
                            </div>

                        </div>
                        </>
                    }
                    <div className="row">

                        <div className="col l12 optionsAddCont">
                            Dirección: {datosEvento.direccion}
                        </div>
                        <div className="col l12 optionsAddCont">
                            Notas: {datosEvento.notas}
                        </div>
                        {
                            datosEvento.seRepite &&
                            <div className="col l12">
                                <div className="col l5" style={{marginTop: "10px", textAlign: "end"}}>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<GreenCheckbox checked={datosEvento.seRepite}
                                                                    name="seRepite"/>}
                                            label="Este evento se repite" style={{color: "#00AC46"}}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col l7">
                                    <NativeSelect id="frecuencia" name="frecuencia"
                                                  style={{marginTop: "10px"}} disabled
                                                  value={datosEvento.frecuencia}>
                                        <option value="0">Elige la frecuencia</option>
                                        <option value="1">Semanalmente</option>
                                        <option value="2">Mensualmente</option>
                                        <option value="3">Anualmente</option>
                                    </NativeSelect>
                                </div>
                                <div className="col l12">
                                    <p className="col l5">Se repite hasta: {new Date(datosEvento.seRepiteHasta).toLocaleDateString()}</p>
                                </div>

                            </div>
                            }

                    </div>
                </DialogContent>

                <DialogActions>

                    <Button onClick={handleCloseModal} style={{backgroundColor: "rgb(0, 154, 61)", color: "rgb(255, 255, 255)"}}
                            className="MuiBadge-colorPrimary">
                        Cerrar
                    </Button>
                </DialogActions>

            </Dialog>
        </div>
    )

}